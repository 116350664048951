import React from 'react';
import {
    TestimonialCard as Card,
    TestimonialImg as Img,
    TestimonialCardName as Name,
    TestimonialCardP as P
} from '../../../Style/Testimonials';

export const TestimonialCard = ({
    src,
    name,
    quote,
    isWide
}) => {
    return (
        <Card isWide={isWide}>
            <Img src={src} alt={name} />
            <Name>{name}</Name>
            <P>{quote}</P>
        </Card>
    ) 
}