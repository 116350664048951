import React from 'react';
import { getUnits } from '../../../../actions';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { ProductDropdownContainer as Container } from '../../../../Style/Manifest';

export const ProductDropdown = ({
    possibleArticleCounts,
    articles,
    showRemove,
    groupName,
    articleType,
    pricePerArticle,
    onQuantityChange,
    needsTrashQuestion,
    productType
}) => {
    const label = `$${(pricePerArticle/100).toFixed(2)} per ${articleType}`;
    const options = () => {
        if (!needsTrashQuestion) {
            if (possibleArticleCounts.length) {
                const rows = possibleArticleCounts.map(articleCount => {
                    const units = getUnits(articleType, articleCount, true)
                    return (<option key={articleCount} className="bundle-product-option" value={articleCount}>{articleCount} {units} ${(pricePerArticle / 100 * articleCount).toFixed(2)}</option>)
                });
                return rows;
            } else {
                return (<option key={`product-option-${Math.random(1000)}`} className="bundle-product-option" value={0}>0 -Out of Stock</option>)
            }
        }
    }

    const remove = () => {
        if (showRemove && !needsTrashQuestion) {
            return <option value="remove">Remove from plan</option>
        }
    }

    const skip = () => {
        if (articles === 0 || !needsTrashQuestion) {
            return <option value={0}>0 - Remind me later</option>
        }
    }

    const showNone = () => {
        if (needsTrashQuestion) {
            return (
                <option value="-">-</option>
            )
        }
    }

    return (
        <Container>
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
                <Select
                    id={`product-select-${productType}`}
                    native
                    value={articles}
                    onChange={e => onQuantityChange(groupName, e.target.value)}
                    label={label}
                >
                    {options()}
                    {showNone()}
                    {skip()}
                    {remove()}
                </Select>
            </FormControl>
        </Container>
    )
}
