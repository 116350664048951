import React from 'react';
import {
    BundleLandingProductGroupContainer as Container,
    BundleLandingProductGroupImg as Img,
    BundleLandingProductGroupLink as Link,
    BundleLandingProductGroupP as P,
} from '../../../Style';

export const BundleProductGroup = ({ productGroup, onclick }) => {
    const {
        status,
        available,
        imageUrl,
        articleType,
        pricePerArticle,
        brandName,
        displayName
    } = productGroup

    
    if(status === "Active" && available) {
        return (
            <Container onClick={onclick}>
                <Img src={imageUrl} alt={brandName + " " + displayName} />
                <P>
                    <b>{brandName}</b> {displayName}<br />
                    ${(pricePerArticle/100).toFixed(2)} per {articleType}<br />
                </P>
                <Link>Learn more</Link>
            </Container>
        )
    } else return <div />
}