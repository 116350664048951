export const
    accountHeader = require('./account-header-img.png'),
    add_bundle = require('./add_bundle.svg'),
    availability = require('./location-pin.svg'),
    availability_denied = require('./location-pin-close.svg'),
    confetti = require('./confetti.svg'),
    coupon_checkmark = require('./coupon_checkmark.svg'),
    login = require('./login.svg'),
    not_found = require('./img-404-tp.gif'),
    oc_house = require('./oc_house.svg'),
    refresh = require('./refresh.svg'),
    pause = require('./pause-icon.png'),
    phone_banner = require('./phone_banner.svg'),
    plus_sign = require('./plus_sign.svg'),
    predicting = require('./predicting.svg'),
    referral_present = require('./referral_present.svg')
