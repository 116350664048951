import React from 'react';
import {
    LineItemSubtotalRow as Row,
    LineItemRow,
    LineItemPrice as Price,
    LineItemSubtotalLabel as Label
} from '../../../Style/Receipt';

export const SubtotalLineItem = ({ name, value, isDiscount }) => {
    return (
        <Row as={LineItemRow} isDiscount={isDiscount} key={name}>
            <td></td>
            <td></td>
            <Label>{name}:</Label>
            <Price>
                ${value}
            </Price>
        </Row>
    )
}