import axios from 'axios';
import {
    GET_FAQ_SUCCESS,
    GET_FAQ_FAIL,
    GETTING_FAQ
} from './types';
import {handleApiError} from '../helpers/apiHelper';
import getSignature from '../helpers/aws4-utl';

export const getFAQs = () => {
    return (dispatch) => {
        dispatch({ type: GETTING_FAQ });
        getSignature('GET', {}, '/faq').then(req => {
            axios.request(req.request)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_FAQ_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, GET_FAQ_FAIL))
                })
                .catch(err => {
                    dispatch(handleApiError(err, GET_FAQ_FAIL))
                })
            }).catch(err => {
                dispatch(handleApiError(err, GET_FAQ_FAIL))
            })
        }
}