import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import { Row, Col } from 'react-bootstrap';
import * as images from '../../../assets';
import { formatProductType, getUnits, getRounder } from '../../../actions'
import {Counter, MoreInfo, KitchenFlow} from '..';
import {connect} from 'react-redux';
import { InterviewQuestion } from '../Interview/InterviewQuestion';
import LaundryFrequency from '../../Interview/Laundry/LaundryFrequency';
import ContentfulImage from '../../../contentful';

class BundleRow extends Component {
    constructor(props) {
        super(props);

        this.state = {frequency: 1 };
        this.handleAdditionalClick = this.handleAdditionalClick.bind(this);
        this.setSelectedProduct = this.setSelectedProduct.bind(this);
        this.updateInterview = this.updateInterview.bind(this);
        this.closeKitchenFlow = this.closeKitchenFlow.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.decrement = this.decrement.bind(this);
        this.increment = this.increment.bind(this);
    }

    componentDidMount() {
        const {
            bundle,
            bundleNumerator,
            selectedProducts,
            productTypes,
            productGroupsMap,
            interview
        } = this.props;

        const capitalBundle = bundle === "everyday" ? "Everyday" : "Kitchen & Cleaning";

        this.setState({
            bundle,
            bundleNumerator,
            selectedProducts,
            productTypes,
            productGroupsMap,
            capitalBundle,
            interview
        })

        // ensure interview sessionStorage is set
        sessionStorage.setItem('interview', JSON.stringify(interview));

        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 768 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 767 && isMobile) {
            this.setState({isMobile: false})
        }
    }

    componentDidUpdate() {
        const { selectedProducts, bundleNumerator, newOptions, productGroupsMap, productTypes } = this.props;
        try {
            if (Object.keys(selectedProducts).length !== Object.keys(this.state.selectedProducts).length) {
                this.setState({ selectedProducts, selectedGroupName: undefined })
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err)
        }
        if (this.state.bundleNumerator !== bundleNumerator) {
            this.setState({ bundleNumerator })
        }
        if(newOptions) {
            this.props.clearnewOptions();
            this.setState({productGroupsMap, productTypes})
        }
    }

    handleAdditionalClick(groupName) {
        const { productGroupsMap } = this.state;
        const element = document.getElementById(`${groupName}-svg`);
        if (element.matches(':hover')) {
            this.props.showDescription(productGroupsMap[groupName])
        } else {
            this.setState({ selectedGroupName: groupName })
            this.props.updateSelection(productGroupsMap[groupName])
        }
    }


    updateInterview(kitchenQuestions) {
        const {gettingRounder} = this.props;
        const {need, interview, frequency, selectedGroupName, productGroupsMap} = this.state;
        if(!gettingRounder) {
            try {
                let trashType;
                switch(need) {
                    case "needsKitchenFlow":
                        const {dishwashing, housekeeping, cookingFrequency} = kitchenQuestions;
                        if(dishwashing) interview.dishwashing = dishwashing;
                        if(housekeeping) interview.housekeeping = housekeeping;
                        if(cookingFrequency) interview.cookingFrequency = cookingFrequency;
                        break;
                    case "needsKitchen":
                    case "needsSmall":
                    case "needsOutdoor":
                    case "needsCompost":
                    case "needsRecycling":
                        trashType = productGroupsMap[selectedGroupName].productType.replace("TrashBags", "");
                        break;
                    case "needsLaundry":
                        if(!interview.laundry) {
                            interview.laundry = {preferences: []}
                        }
                        interview.laundry.frequency = JSON.parse(sessionStorage.getItem('laundryFrequency'));
                        break;
                    default:
                        break;
                }
                if(trashType) {
                    const index = interview.trashPreferences.map(it => {return it.type}).indexOf(trashType);
                    if(index > -1) {
                        interview.trashPreferences.splice(index, 1);
                    }
                    interview.trashPreferences.push({
                        type: trashType,
                        frequency: frequency,
                        bags: 1
                    });
                }
                if(window.location.pathname !== "/dashboard") this.props.getRounder(interview);
                this.props.updateInterview(interview);

                this.setState({need: undefined, interview, frequency: 1})
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
    }

    renderBundleCol() {
        const { bundle, capitalBundle, selectedProducts } = this.state;
        return (
            <Col xs={12} md={4} className={`bundle-col${!selectedProducts || !Object.keys(selectedProducts).length ? ' full' : ''}`}>
                <div className="add-products-bundle-img"><img src={images[`${bundle}_bundle_icon`]} alt={bundle} /></div>
                <div className="bundle-name-container">
                    <div>{capitalBundle} Essentials</div>
                    {this.renderFulfillmentBar()}
                </div>
            </Col>
        )
    }

    renderFulfillmentBar() {
        const { bundleNumerator, selectedProducts } = this.state
        if (bundleNumerator !== undefined && selectedProducts) {
            const styles = {
                width: `${bundleNumerator / (bundleNumerator + Object.values(selectedProducts).length) * 100}%`
            }

            return (
                <div className={`bundle-progress-bar${!Object.values(selectedProducts).length ? ' full' : ''}`}>
                    <div className={`progress bundle-progress${!Object.values(selectedProducts).length ? ' rounded' : ''}`} style={styles}></div>
                    <div className="bundle-percentage">{(bundleNumerator / (bundleNumerator + Object.values(selectedProducts).length) * 100).toFixed(0)}%</div>
                </div>
            )
        }
    }

    setSelectedProduct(groupName) {
        const { selectedGroupName, productGroupsMap } = this.state;
        const { interview } = this.state;
        try {
            let trashType;
            let need;
            switch (productGroupsMap[groupName].productType.toLowerCase()) {
                case 'dishwasherdetergent':
                case 'surfacecleaner':
                case 'dishsoap':
                case 'handsoap':
                    if (interview.housekeeping === undefined)
                        need = "needsKitchenFlow";
                    break;
                case 'trashbagsoutdoor':
                case 'trashbagsrecycling':
                case 'trashbagssmall':
                case 'trashbagskitchen':
                case 'trashbagscompost':
                    trashType = productGroupsMap[groupName].productType.replace("TrashBags", "");
                    break;
                case "laundrydetergent":
                case "bleach":
                case "dryersheets":
                    if(!interview.laundry)
                        need = "needsLaundry";
                    break;
                default:
                    break;
            }
            if(trashType) {
                const index = interview.trashPreferences.map(it => { return it.type }).indexOf(trashType)
                if (index === -1 || (index > -1 && !interview.trashPreferences[index].frequency)) {
                    need = `needs${trashType}`;
                }
            }
            this.setState({need, selectedGroupName: selectedGroupName !== groupName ? groupName : false })
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    renderEditingRow(group) {
        const { selectedGroupName, productGroupsMap, productTypes, need } = this.state;
        const selected = group ? group === selectedGroupName : selectedGroupName;
        if (selected && !need) {
            try {
                const product = productGroupsMap[selectedGroupName];
                const { additional } = productTypes[product.productType];
                const additionalRows = [];

                Object.values(additional).forEach(a => {
                    const additionalProduct = productGroupsMap[a.groupName];
                    const { imageUrl, brandName, groupName, pricePerArticle, displayName, articles, articleType } = additionalProduct;
                    if (groupName !== selectedGroupName) {
                        additionalRows.push(
                            <div className="order-additional-product-container" onClick={() => this.handleAdditionalClick(groupName)}>
                                <div className="order-additional-product text-center">
                                    <svg id={`${groupName}-svg`} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" stroke-opacity="0.8" />
                                        <circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fill-opacity="0.8" />
                                        <rect className="info-rect" width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fill-opacity="0.8" />
                                    </svg>
                                    <ContentfulImage src={imageUrl} 
                                      className="order-edit-img additional"  
                                      alt={`${brandName} ${displayName}`}
                                      defaultWidth="100" defaultHeight="100"
                                    />
                                    <div className="text-center"><b>{brandName}</b></div>
                                    <div className="text-center">{displayName}</div>
                                    <div className="text-center">{articles} {getUnits(articleType, articles, true)} ${(pricePerArticle / 100 * articles).toFixed(2)}</div>
                                    <div className="text-center">@ ${(pricePerArticle / 100).toFixed(2)} each</div>
                                </div>
                            </div>
                        )
                    }
                })

                const { articles, articleType, imageUrl, brandName, displayName, pricePerArticle, groupName, productType } = product;

                const renderAdditional = () => {
                    if (additionalRows.length) {
                        return (
                            <div className="products-option-container">
                                <div className="other-options-text">Other Options:</div>
                                {additionalRows}
                            </div>
                        )
                    }
                }

                return (
                    <Col xs={12} className="bundle-row edit">
                        <div className="order-product edit text-center">
                            <ContentfulImage className="order-edit-img text-center" src={imageUrl} alt={`${brandName} ${displayName}`}
                                defaultWidth="100" defaultHeight="100"
                            />
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.props.showDescription(productGroupsMap[groupName])}>
                                <circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" stroke-opacity="0.8" />
                                <circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fill-opacity="0.8" />
                                <rect width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fill-opacity="0.8" />
                            </svg>
                            <div className="order-product-details text-center">
                                <div><b>{brandName}</b> {displayName}</div>
                                <div>{formatProductType(productType)}</div>
                                <Counter type="prediction" min={0} count={articles} increment={() => this.props.increment(productGroupsMap[groupName])} units={getUnits(articleType, articles)} decrement={() => this.props.decrement(productGroupsMap[groupName])} />
                                <div><span className="summary-product-price">${(articles * pricePerArticle / 100).toFixed(2)}</span> @ ${(pricePerArticle / 100).toFixed(2)} each</div>
                            </div>
                            <div className="add-products-btn-container stacked">
                                <button className="dashboard-btn dashboard-submit" onClick={() => this.props.addProduct(productGroupsMap[groupName])}>Add Product</button>
                                <button className="dashboard-btn dashboard-cancel-link" onClick={() => this.setState({ selectedGroupName: undefined })}>Cancel</button>
                            </div>
                        </div>
                        {renderAdditional()}
                    </Col>
                )


            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }

        }
    }
    renderDesktopEditingRow() {
        const {isMobile} = this.state;
        if(!isMobile) {
            return this.renderEditingRow()
        }
    }

    renderMobileEditingRow(groupName) {
        const {isMobile} = this.state;
        if(isMobile) {
            return this.renderEditingRow(groupName)
        }
    }

    renderProducts() {
        const { productTypes, selectedProducts, selectedGroupName } = this.state;
        if(productTypes) {
            try {
                const products = [];
                for (let i = 0; i < Object.entries(productTypes).length; i++) {
                    const productType = Object.entries(productTypes)[i];
                    if (selectedProducts[productType[0]]) {
                        if (!Object.keys(productType[1].edited).length) {
                            const { groupName } = productType[1].preferred;
                            products.push(
                                <div className={`order-additional-product-container ${selectedGroupName === groupName ? " open" : ""}`} onClick={() => this.setSelectedProduct(productType[1].preferred.groupName)}>
                                    <div className="order-additional-product text-center">
                                        <div>{formatProductType(productType[0])}</div>
                                        <ContentfulImage src={productType[1].preferred.imageUrl} alt={formatProductType(productType[0])}
                                          defaultWidth="115" defaultHeight="115"
                                        />
                                    </div>
                                </div>
                            )
                            products.push(this.renderMobileEditingRow(groupName))
                        } else {
                            const { groupName } = productType[1].edited;
                            products.push(
                                <div className={`order-additional-product-container ${selectedGroupName === groupName ? " open" : ""}`} onClick={() => this.setSelectedProduct(productType[1].edited.groupName)}>
                                    <div className="order-additional-product text-center">
                                        <div>{formatProductType(productType[0])}</div>
                                        <ContentfulImage src={productType[1].edited.imageUrl} alt={formatProductType(productType[0])}
                                          defaultWidth="115" defaultHeight="115"
                                        />
                                    </div>
                                </div>
                            )
                            products.push(this.renderMobileEditingRow(groupName))
                        }
                    }
                }
                return (
                    <Fragment>
                        <Col xs={12} md={8} className="additional-products">
                            {products}
                        </Col>
                    </Fragment>
                )

            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
                return null
            }
        }
    }

    closeKitchenFlow() {
        this.setState({selectedGroupName: false, need: false, unsavedChanges: false})
    }

    decrement() {
        const {frequency} = this.state;
        if(frequency) this.setState({frequency: frequency - 1})
    }

    increment() {
        const {frequency} = this.state;
        this.setState({ frequency: frequency + 1})
    }

    renderInterviewModal() {
        const {need, frequency} = this.state;
        let question;
        let displayProduct;
        const image = { alt: "", src: "" };
        const counter = {
            min: 0,
            increment: this.increment,
            decrement: this.decrement,
            count: frequency
        }
        let trashType;

        switch(need) {
            case "needsKitchen":
                trashType = "Kitchen Trash Bags"
                break;
            case "needsCompost":
                trashType = "Compost Trash Bags"
                break;
            case "needsRecycling":
                trashType = "Recycling Trash Bags"
                break;
            case "needsSmall":
                trashType = "Small Trash Bags"
                break;
            case "needsOutdoor":
                trashType = "Outdoor Trash Bags"
                break;
            default:
                break;
        }
        image.src =  images[`${trashType.split(' ')[0].toLowerCase()}_trash_icon`]
        image.alt =  trashType
        displayProduct = trashType.toLowerCase();
        question = `How many ${trashType.toLowerCase()} do you use each week?`
        const infoCopy = `The amount of ${displayProduct} you do helps us calculate how much detergent you’ll need each month.`;
        return (
            <InterviewQuestion
                next={this.updateInterview}
                back={() => this.setState({need: false, selectedGroupName: false, frequency: 1})}
                close={() => this.setState({need: false, selectedGroupName: false, frequency: 1})}
                infoCopy={infoCopy}
                image={image}
                question={question}
                isModal={true}
                counter={counter}
            />
        )
    }

    renderAdditionalQuestions() {
        const { need, showInfo, interview, unsavedChanges } = this.state;
        if(!showInfo) {
            if(need) {
                if (need === "needsKitchenFlow") {
                    return (
                        <div className="modal-container transparent">
                            <div className="interview-question-modal">
                                {unsavedChanges ? null : <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" alt="Info" />}
                                <img onClick={unsavedChanges ? this.closeKitchenFlow : () => this.setState({unsavedChanges: true})} className={`${unsavedChanges ? "raised " : ""}text-right order-x-icon`} src={images.btn_close_x} alt="Close" />
                                <div className={`interview-modal-body${unsavedChanges ? ' no-padding' : ''}`}>
                                    <KitchenFlow
                                        history={this.props.history}
                                        updateInterview={this.updateInterview}
                                        interview={interview}
                                        close={this.closeKitchenFlow}
                                        unsavedChanges={unsavedChanges}
                                        setUnsavedChanges={() => this.setState({unsavedChanges: true})}
                                        clearUnsavedChanges={() => this.setState({unsavedChanges: false})}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                } else if(need === "needsLaundry") {
                    return <LaundryFrequency close={() => this.setState({need: false, selectedGroupName: false})}  next={this.updateInterview} isModal={true} />
                } else {
                    return this.renderInterviewModal();
                }
            }
        }
    }

    renderInfo() {
        const {showInfo, need} = this.state;
        if(showInfo) {
            let copy;
            if (need === "needsKitchenFlow")
                copy = "By answering just a couple questions, we'll be able to calculate your kitchen & cleaning needs.";
            else
                copy = "By answering just a couple questions, we'll be able to calculate your supply needs.";
            return (
                <MoreInfo close={() => this.setState({ showInfo: false })} copy={copy} />
            )
        }
    }

    render() {
        const { selectedProducts } = this.state;
        return (
            <Row className="order-row">
                <Row className={`bundle-row${!selectedProducts || !Object.keys(selectedProducts).length ? ' full' : ''}`}>
                    {this.renderBundleCol()}
                    {this.renderProducts()}
                    {this.renderInfo()}
                </Row>
                {this.renderAdditionalQuestions()}
                {this.renderDesktopEditingRow()}
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const { rounder, rounderError, gettingRounder } = state.interview;
    return {rounder, rounderError, gettingRounder};
}

export default connect(mapStateToProps, {
    getRounder,
})(BundleRow);
