import React, { Component } from 'react';
import { CloseX } from '..';
import { 
    ModalContainer as Container,
    ModalBody as Body,
    ModalHR as HR,
    ModalTitle as Title,
    BtnPrimary as Button,
    BtnCancel as ButtonCancel
} from '../../../Style';
import * as Sentry from '@sentry/browser';

export class SingleChoiceModal extends Component {
    constructor(props) {
        super(props);

        this.state= {};

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillMount() {
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    handleClickOutside(e) {
        try {
            const onClickId = e.target.id
            if (onClickId === "choice-modal-container") {
                this.props.close();
            }
        } catch(err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    render() {
        const {
            title,
            body,
            close,
            next,
            buttonCopy,
            cancelCopy
        } = this.props;
        return (
            <Container id="choice-modal-container">
                <Body>
                    <div>
                        <Title>
                            {title}
                            <CloseX close={close} />
                        </Title>
                        <HR />
                        <div className="modal-p">{body}</div>
                    </div>
                    <div className="text-right">
                        { cancelCopy &&
                            <ButtonCancel onClick={close}>{cancelCopy}</ButtonCancel>
                        }
                        <Button onClick={next}>{buttonCopy}</Button>
                    </div>
                </Body>
            </Container>
        )
    }
}