import React from 'react';
import {
    LandingSection,
    LandingTwoUpSection as Section,
    LandingSectionCol as Col,
    LandingSectionImg as SectionImg,
    H2Amaranth as H2,
    BodyL,
    RaisedSection
} from '../../../Style';

export const LandingTwoUpSection = ({
    bodyImg,
    body,
    bodyContainerStyle,
    bodyStyle,
    cta,
    excludeHeader,
    header,
    headerSection,
    headerStyle,
    isHero,
    positionedImages,
    imgContainerStyles,
    isOverflowX,
    isOverflowY,
    isRaised,
    isReverse,
    mainImg,
    mainImgOverlay,
    overflowContainerStyles,
    overflowStyles,
    raisedSectionStyles,
    reverseHeader,
    style,
    subcopy
}) => {
    const renderBodyImg = () => {
        if (bodyImg)
            return bodyImg;
    }

    const renderSubcopy = () => {
        if (subcopy)
            return subcopy
    }
    
    const renderCTA = () => {
        if (cta)
            return cta
    }

    function renderHeader() {
        if(isHero) {
            return headerSection
        } else if (excludeHeader) {
            return
        } else {
            return <H2 bodySection={true} style={ headerStyle }>{header}</H2>
        }
    }

    const renderBody = () => {
        return (
            <Col copySection={true} bodyContainerStyle={bodyContainerStyle}>
                {!reverseHeader && renderHeader()}
                <BodyL isHero={isHero} style={ bodyStyle }>{body}</BodyL>
                {renderCTA()}
                {renderBodyImg()}
                {renderSubcopy()}
            </Col>
        )
    }

    const renderPositionedImages = () => {
        if (positionedImages)
            return positionedImages;
    }

    const renderMainImgLabel = (label) => {
        if(label)
            return label;
    }
    
    const renderMainImgOverlay = () => {
        if(mainImgOverlay)
            return mainImgOverlay
    }

    const renderMainImg = () => {
        const { alt, src, label } = mainImg;
        return (
            <Col overflowContainerStyles={overflowContainerStyles} imgContainerStyles={imgContainerStyles} isOverflowY={isOverflowY} isOverflowX={isOverflowX}>
                {reverseHeader && renderHeader()}
                {renderMainImgLabel(label)}
                {renderMainImgOverlay()}
                <SectionImg alt={alt} src={src} isOverflowY={isOverflowY} isOverflowX={isOverflowX} overflowStyles={overflowStyles} />
            </Col>
        )
    }

    const main = () => {
        if (isRaised) {
            return (
                <RaisedSection style={raisedSectionStyles}>
                    <LandingSection as={Section} isReverse={isReverse} style={style}>
                        {renderPositionedImages()}
                        {renderBody()}
                        {renderMainImg()}
                    </LandingSection>
                </RaisedSection>
            )
        } else {
            return (
                <LandingSection as={Section} isReverse={isReverse} style={style}>
                    {renderPositionedImages()}
                    {renderBody()}
                    {renderMainImg()}
                </LandingSection>
            )
        }
    }

    return main()
}