import React from 'react';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
    Caboo,
    Cascade,
    Cottonelle,
    Fantastik,
    Glad,
    Kleenex,
    MrsMeyers,
    Puracy,
    Soapbox,
    SeventhGeneration
} from '../../../assets/brands';
import { BrandImg, BrandImgContainer } from '../../../Style'

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 7,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1200, min: 757 },
        items: 3,
    },
    nextdown: {
        breakpoint: { max: 757, min: 0 },
        items: 1,
    },
}

const brands = [
    {
        img: Caboo,
        name: "Caboo"
    },
    {
        img: Cascade,
        name: "Cascade"
    },
    {
        img: Cottonelle,
        name: "Cottonelle"
    },
    {
        img: Fantastik,
        name: "Fantastik"
    },
    {
        img: Glad,
        name: "Glad"
    },
    {
        img: Kleenex,
        name: "Kleenex"
    },
    {
        img: MrsMeyers,
        name: "Mrs. Meyers"
    },
    {
        img: Puracy,
        name: "Puracy"
    },
    {
        img: SeventhGeneration,
        name: "Seventh Generation"
    },
    {
        img: Soapbox,
        name: "Soapbox Soaps"
    },
]

export const BrandsCarousel = () => {
    return (
        <MultiCarousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={1800}
            infinite={true}
            partialVisible={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop", "nextdown"]}

        >
            {brands.map(brand => {
                const { name, img } = brand;
                return (
                    <BrandImgContainer key={`${name}-carousel-img`}>
                        <BrandImg src={img} alt={name} />
                    </BrandImgContainer>
                )
            })}
        </MultiCarousel>
    )
}