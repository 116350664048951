import styled from 'styled-components';

export const CountdownAlertBody = styled.div`
    max-width: 100%;
`

export const CountdownCopy = styled.span`
    padding-top: 4px;
`

export const CountdownInputWrapper = styled.div`
    margin-top: 12px;
`