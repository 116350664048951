import React from 'react';
import * as assets from '../../../assets/investors'
import { InvestorImage } from '../../../Style';

export const InvestorLogo = ({ slug }) =>  {
    const investor = investors[slug];
    if(investor) {
        const { href, alt, src } = investor;
        return (
            <a href={href} target="_blank" rel="noopener noreferrer">
                <InvestorImage src={src} alt={alt} />
            </a>
        )
    } else {
        return <div />
    }
}

export const investors = {
    capital_factory: {
        src: assets.capital_factory,
        alt: "Capital Factory",
        href: "https://www.capitalfactory.com/"
    },
    firebrand: {
        src: assets.firebrand,
        alt: "Firebrand",
        href: "https://www.firebrandvc.com/"
    },
    live_oak: {
        src: assets.live_oak,
        alt: "Live Oak Venture Partners",
        href: "https://liveoakvp.com/"
    },
    mark_vc: {
        src: assets.mark_vc,
        alt: "mark vc",
        href: "https://markvc.com/"
    },
    next_coast_ventures: {
        src: assets.next_coast_ventures,
        alt: "Next Coast Ventures",
        href: "http://nextcoastventures.com/"
    },
    notley: {
        src: assets.notley,
        alt: "Notley",
        href: "https://www.wearenotley.com/"
    }
}