import React from 'react';
import FontAwesome from "react-fontawesome";

export const ContinueButton = ({
    next,
    back,
    disabled,
    isModal,
    text,
    loading,
    cancelText,
    fixed,
    noMargin,
    pressText,
    keyText
}) => {
    if(isModal) {
        return (
            <div className="interview-modal-button-row">
                <button className="dashboard-btn dashboard-submit white" onClick={back}>{cancelText ? cancelText : "Back"}</button>
                <button id="next-btn" className="dashboard-btn dashboard-save" disabled={disabled} onClick={next}>{text ? text : "Continue"}</button>
            </div>
        )
    } else if(fixed) { // Possibly other uses for this in the future may want to change "fixed" to something else
        return (
            <button id="next-btn" disabled={disabled} className={`interview-continue-button${noMargin ? " no-margin" : ""}`} onClick={next}>
                <span className={loading ? '' : 'hide'}><FontAwesome
                    key={"placing-order-spinner"}
                    name="spinner"
                    className="marRight5"
                    spin
                /></span>
                {text ? text : "Continue"}
            </button>
        )
    } else {
        return (
            <div className={`interview-continue-wrapper${noMargin ? " no-margin" : ""}`}>
                <button disabled={disabled} id="next-btn" className="interview-continue-button" onClick={next}>{text ? text : "Continue"}</button>
                <div className="interview-enter-text-container">
                    { (pressText && keyText) &&
                        <p className="interview-enter-text">{pressText} <b>{keyText}</b></p>
                    }
                </div>
            </div>
        )
    }
}