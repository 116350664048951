import React from 'react';
import {
	MegaMenuContainer as Container,
	MegaMenuCol as Col,
	MegaMenuImgContainer as ImgContainer,
	MegaMenuImg as Img,
	MegaMenuTitle as Title,
	MegaMenuItem as Item,
} from '../../../Style';
import { formatProductType, getBundlesSearchString } from '../../../actions';
import { bundles } from '../../../data';
import { Link } from 'react-router-dom';

export const MegaMenu = ({ history, setOpen, spaceTop }) => {
	const { search } = window.location;
	console.log(spaceTop)
	return (
		<Container onMouseEnter={() => setOpen(true)} onMouseLeave={() => { setOpen(false) }} spaceTop={spaceTop}>
			{
				Object.entries(bundles).map(entry => {
					const bundleId = entry[0], values = entry[1];
					const { title, hero, productTypes } = values;
					return (
						<Col key={bundleId}>
							<ImgContainer onClick={() => history.push({ pathname: `/products/bundles/${bundleId}`, search })}>
								<Img src={hero} alt={title} title={title} />
							</ImgContainer>
							<Title onClick={() => history.push({ pathname: `/products/bundles/${bundleId}`, search })}>{title}</Title>
							{productTypes.map(productType => {
								return <Item key={productType}><Link to={{ pathname: `/products/bundles/${bundleId}`, search: getBundlesSearchString(productType) }} title={formatProductType(productType)}>{formatProductType(productType)}</Link></Item>
							})}
						</Col>
					)
				})
			}
		</Container>
	)
}