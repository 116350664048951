export const defaultZipcodes = [
    "78702",
        "78762",
        "78741",
        "78721",
        "78701",
        "78722",
        "78704",
        "78712",
        "78773",
        "78723",
        "78742",
        "78705",
        "78778",
        "78799",
        "78711",
        "78774",
        "78769",
        "78768",
        "78767",
        "78714",
        "78703",
        "78751",
        "78713",
        "78746",
        "78764",
        "78744",
        "78772",
        "78765",
        "73301",
        "78760",
        "73344",
        "78756",
        "78745",
        "78731",
        "78763",
        "78783",
        "78752",
        "78779",
        "78757",
        "78761",
        "78716",
        "78710",
        "78749",
        "78748",
        "78766",
        "78718",
        "78715",
        "78709",
        "78708",
        "78735",
        "78759",
        "78738",
        "78758",
        "78726",
        "78727",
        "78733",
        "78747",
        "78739",
        "78753",
        "78750",
        "78729",
        "78717",
        "78613",
        "78630",
        "78664",
        "78665",
        "78680",
        "78681",
        "78682",
        "78683",
        "78728",
        "78660",
        "78691"
]