import styled, { css } from 'styled-components';
import { colors } from './Colors';

export const LineItemRow = styled.tr`
    border-left: 3px solid ${colors.titleText};
    border-bottom: 1px solid ${colors.borderLight};
    height: 27px;
    font-family: 'Muli', sans-serif;
    font-size: 11px;
    line-height: 140%;
`

export const LineItemSubtotalRow = styled.tr`
    background-color: ${`${colors.pureWhite} !important`};
    border: none;
    font-weight: bold;
    ${props => props.isDiscount && css`
        color: ${colors.textGreen};
    `}
`

export const LineItemImg = styled.img`
    width: 20px;
    height: auto;
    margin-right: 8px;
    margin-left: 8px;
`

export const LineItemPrice = styled.td`
    padding-right: 15px;
    text-align: right;
`

export const ReceiptHeader = styled.th`
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 11px;
    line-height: 120%;
    height: 27px;
`

export const LoadingContainer = styled.div`
    position: relative;
    max-width: 100%;
    height: calc(100vh - 100px);
`

export const LineItemSubtotalLabel = styled.td`
    max-width: 60px;
`