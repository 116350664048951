import React from 'react';
import {
    BundleProductTypeSectionContainer as Container,
    BundleProductTypeSectionTitle as Title,
    BundleLandingProductGroups
} from '../../../Style';
import { BundleProductGroup } from './'

export const BundleProductTypeSection = ({ formattedProductType, productGroups, onclick }) => {
    return (
        <Container>
            <Title>{formattedProductType}</Title>
            <BundleLandingProductGroups>
                {productGroups.map(productGroup => {
                    return <BundleProductGroup key={productGroup.groupName} productGroup={productGroup} onclick={() => onclick(productGroup)} />
                })}
            </BundleLandingProductGroups>
        </Container>
    )
}