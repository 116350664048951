import {
    joah_spearman,
    cate_smithson
} from '../assets/testimonials';

export const localeurTestimonials = {
    title: "What Localeurs are saying",
    subtitle: "You already trust the Localeur community to help you find the best of what’s on offer in over 180 cities. Hear what our experts have to say about Supply Drop:",
	entries: [
		{
			quote: "I love how responsive Supply Drop is! Being able to text, 'I need some dishwasher pods,' and having them delivered to my doorstep without going through the whole process of ordering on Amazon or driving to a grocery store is such a time and energy aver. This is going to help me get so much time back to focus on what really matters.",
			src: joah_spearman,
			name: "Joah Spearman, Founder and CEO of Localeur"
        },
		{
			quote: "I insist on cleaning my apartment with products that are safe for the environment – but I also need them to actually work, which isn’t always easy to find. Supply Drop takes all the guesswork out of the process! Not only is my place sparkling clean, I didn’t have to waste a ton of time researching what to buy.",
			src: cate_smithson,
			name: "Cate Smithson"
        }
	],
	isWide: true
}