import React from 'react';

export const AlertModal =({
    header,
    body,
    next,
    cancel,
    confirmText
}) => {
    return (
        <div className="modal-container transparent interview-question-modal-container">
            <div className="interview-question-modal">
                <div className="signin-modal-tab-container">
                    <div className="cancel-modal-body">
                        <p className="modal-title">
                            {header}
                        </p>
                        <hr />
                        <div className="modal-p">{body}</div>
                    </div>
                </div>
                <div className="cancel-modal-button-row">
                    <button className="dashboard-btn dashboard-submit white" onClick={cancel}>Cancel</button>
                    <button className="dashboard-btn dashboard-submit" onClick={next}>{confirmText}</button>
                </div>
            </div>
        </div>
    )
}