import React from 'react';
import * as images from '../../../assets';
import {
    FlexCol,
    InterviewHouseholdBtn,
    InterviewHouseholdBtnAge as Age,
    HouseholdMemberImg as Img
} from '../../../Style';

export const HouseholdButton = ({
    isDog,
    isCat,
    gender,
    type,
    age,
    staysHome,
    onclick,
    disabled
}) => {
    const renderCheckbox = () => {
        if (!disabled && onclick) {
            return (
                <FlexCol className="checkbox-container">
                    <div className={`checkbox${staysHome ? " checked" : ""}`} >
                        <img src={images.checkmark_white} alt="checkbox" className={staysHome ? "checkmark-white" : "hide"} />
                    </div>
                </FlexCol>
            )
        }
    }

    if(isDog || isCat)  {
        const petString = isDog ? "Dog" : "Cat";
        return (
            <InterviewHouseholdBtn onClick={disabled || !onclick ? null : onclick} disabled={disabled} isPet={true}>
                {renderCheckbox()}
                <Img disabled={true} src={images[petString.toLowerCase()]} alt={petString} className="stays-home-member-img" />
            </InterviewHouseholdBtn>
        )
    } else {
        return (
            <InterviewHouseholdBtn onClick={disabled || !onclick ? null : onclick} disabled={disabled}>
                {renderCheckbox()}
                <Img disabled={disabled} src={images[`${gender.toLowerCase()}_${type.toLowerCase()}`]} alt={`${gender} ${type}`} className="stays-home-member-img" />
                <Age disabled={disabled}>{age}</Age>
            </InterviewHouseholdBtn>
        )
    }

}