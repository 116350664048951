import styled, { css } from 'styled-components'
import {
    colors,
    autoMarginTB
} from './'
import {
    flexCol,
    flexCenter,
    flexSpaceAround
} from './Flex';

export const HamburgerContainer = styled.div`
    ${{ ...flexCenter }}
`

export const MobileMenuContainer = styled.div`
    ${{ ...flexCol, ...flexCenter }}
    &:hover {
        color: ${colors.btnBlue.idle}
    }
`

export const DesktopMenuContainer = styled.div`
    ${{ ...flexSpaceAround }};
    width: 600px;
`

export const DesktopMenuLink = styled.a`
    ${{ ...autoMarginTB }}
    cursor: pointer;
    color: ${colors.titleText};
    font-size: 18px;
    &:hover {
        color: ${colors.btnBlue.idle} !important;
    }
    > a {
        ${{ ...autoMarginTB }};
        cursor: pointer;
        color: ${colors.titleText};
        font-size: 18px;
        &:hover {
            color: ${colors.btnBlue.idle} !important;
        }

    }
`

export const NavCTAContainer = styled.div`
    ${{ ...autoMarginTB }}
    text-align: center;
    ${props => props.isMobile && css`
        margin-bottom: 20px;
        margin-right: auto;
    `}
`

export const DesktopMenuProductsLink = styled.div`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

export const NavCTA = styled.button`
    height: 54px;
    min-width: 137px;
    padding: 0 12px;
    background-color: ${colors.primary.main};
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    color: ${colors.contrastText};
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
        background-color: ${colors.primary.dark};
    }
    &:active {
        background-color: ${colors.primary.active_contained};
    }
`

export const MegaMenuContainer = styled.div`
    ${{ ...flexSpaceAround }}
    box-shadow: 0px 15px 35px rgba(43, 64, 78, 0.095505), 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 24px;
    background: ${colors.pureWhite};
    position: fixed;
    top: 90px;
    ${props => props.spaceTop && css`
        top: ${props.spaceTop + 90}px;
    `}
`

export const AboutUsContainer = styled.div`
    box-shadow: 0px 15px 35px rgba(43, 64, 78, 0.095505), 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 24px;
    background: ${colors.pureWhite};
    position: fixed;
    top: 90px;
    left: 52%;
    ${props => props.spaceTop && css`
        top: ${props.spaceTop + 90}px;
    `}
`

export const MegaMenuCol = styled.div`
    min-width: 208px;
    line-height: 280%;
`

export const MegaMenuImgContainer = styled.div`
    height: 110px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
`

export const MegaMenuImg = styled.img`
    width: 160px;
    height: auto;
    margin-top: auto;
    position: absolute;
    bottom: 0;
`
export const MegaMenuTitle = styled.h4`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    cursor: pointer;
    &:hover {
        color: ${colors.btnBlue.idle}
    }
`

export const MegaMenuItem = styled.a`
    cursor: pointer;
    display: block;
    color: ${colors.bodyText};
    &:hover {
        color: ${colors.btnBlue.idle};
    }
    > a {
        cursor: pointer;
        display: block;
        color: ${colors.bodyText};
        &:hover {
            color: ${colors.btnBlue.idle};
        }
    }
`

export const MobileMenuDrawer = styled.div`
    ${{ ...flexCol, ...flexSpaceAround }};
    padding: 40px;
    background-color: #F9F9F9;
    height: 100%;
    width: 100%;
    max-width: 320px;
    right: 0;
    position: absolute;
`

export const MobileMenuLinkContainer = styled.div`
    width: 100%;
    padding: 20px 0 10px; 
`
export const MobileMenuLink = styled.a`
    font-size: 16px;
    line-height: 20px;
    color: ${colors.titleText};
    &:hover {
        color: ${colors.titleText};
        text-decoration: underline;
    }
    ${props => props.grayed && css`
        color: ${colors.disabledBackground};
    `}
    > a {
        font-size: 16px;
        line-height: 20px;
        color: ${colors.titleText};
        &:hover {
            color: ${colors.titleText};
            text-decoration: underline;
        }
        ${props => props.grayed && css`
            color: ${colors.disabledBackground};
        `}
    }
`

export const MobileMenuHR = styled.hr`
    border: 1px solid ${colors.borderLight};
    width: 160px;
    margin-right: auto;
    margin-left: 0;
`

export const InterviewNavContainer = styled.div`
    ${props => props.spaceTopLg && css`
      padding-top: 49px;
    `}
    ${props => props.spaceTop && css`
      margin-top: ${props.spaceTop}px;
    `}
`