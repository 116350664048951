import React from 'react';
import {
    H2Amaranth as H2,
    LandingHIW as HIW,
    LandingHIWTitle as HIWTitle,
    LandingHIWImg as HIWImg,
    LandingHIWSectionBody as HIWSectionBody,
    HIWWrapper as Wrapper,
    HIWHoneycomb as Honeycomb
} from '../../../Style'
import {
    loop_house,
    loop_checklist,
    loop_touch,
    honeycomb
} from '../../../assets/'

const elements = [
    {
        src: loop_house,
        title: "We learn about you and what your household needs.",
    },
    {
        src: loop_checklist,
        title: "We create a plan to ensure your household is always in stock with your home essentials.",
    },
    {
        src: loop_touch,
        title: "We keep in touch to make sure we always deliver what you need, when you need it.",
    },
]

export const HowItWorksSection = () => {
    return (
        <HIWSectionBody>
            <H2>How it works</H2>
            <Wrapper>
                {elements.map((element, index) => {
                    const { src, title } = element;
                    return (
                        <HIW key={title}>
                            <HIWImg key={src} alt={title} src={src} />
                            <HIWTitle key={index}>{title}</HIWTitle>
                        </HIW>
                    )
                })}
            </Wrapper>
            <Honeycomb src={honeycomb} alt="" />
        </HIWSectionBody>
    )
}