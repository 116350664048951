import {
    UPCOMING_ORDER_SUCCESS,
    UPCOMING_ORDER_FAIL,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    UPDATING_ORDER,
    GET_MANIFEST_SUCCESS,
    GET_MANIFEST_FAIL,
    GETTING_MANIFEST,
    PUT_MANIFEST_SUCCESS,
    PUT_MANIFEST_FAIL,
    UPDATING_MANIFEST,
    CREATING_ORDER,
    CLEAR_ORDERS_STATE,
    CLEAR_ORDERS_ERRORS,
    GET_LANDING_FAIL,
    GET_LANDING_SUCCESS,
    GETTING_LANDING,
    GET_SHIPMENT_FAIL,
    GET_SHIPMENT_SUCCESS,
    GETTING_SHIPMENT,
    GET_TAX_SUCCESS,
    GET_TAX_FAIL,
    GETTING_TAX,
    CLEAR_CREATE_ORDERS,
    GETTING_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAIL,
    RECHARGE_SUCCESS,
    RECHARGE_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
    order: undefined,
    updated: false,
    upcomingOrder: undefined,
    orderId: undefined,
    orders: undefined,
    paymentError: false,
    creatingOrder: false,
    putManifestError: undefined,
    putManifestSuccess: undefined,
    getManifestError: '',
    delivery: undefined,
    landing: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_ORDERS_STATE:
            return { 
                ...state,
                order: undefined,
                updated: false,
                upcomingOrder: undefined,
                orderId: undefined,
                orders: undefined,
                paymentError: false,
                creatingOrder: false,
                putManifestError: undefined,
                putManifestSuccess: undefined,
                getManifestError: '',
                delivery: undefined,
                orderError: undefined,
                tax: undefined,
                getTaxError: undefined,
                rechargeError: undefined,
                rechargeSuccess: undefined
            };
        case CLEAR_ORDERS_ERRORS:
            return {...state, putManifestError: undefined, paymentError: undefined, orderError: undefined}
        case CLEAR_CREATE_ORDERS:
            return {...state, creatingOrder: undefined, createOrderSuccess: undefined, createOrderError: undefined}
        case UPCOMING_ORDER_SUCCESS:
            return { ...state, upcomingOrder: action.payload, error: '' };
        case UPCOMING_ORDER_FAIL:
            return { ...state, error: action.error }
        case CREATE_ORDER_SUCCESS:
            return { ...state, createOrderSuccess: action.payload, createOrderError: undefined, creatingOrder: false };
        case CREATE_ORDER_FAIL:
            return { ...state, createOrderError: action.error, createOrderSuccess: undefined, creatingOrder: false }
        case CREATING_ORDER:
            return {...state, createOrderSuccess: undefined, createOrderError: undefined, creatingOrder: true}
        case UPDATING_ORDER:
            return {...state, paymentError: undefined}
        case UPDATE_ORDER_SUCCESS:
            return { ...state, updated: true, paymentError: false, delivery: action.payload };
        case UPDATE_ORDER_FAIL:
            return { ...state, paymentError: action.error, updated: false, delivery: undefined }
        case GET_ORDERS_SUCCESS:
            return { ...state, orders: action.payload, getOrdersError: '' };
        case GET_ORDERS_FAIL:
            return { ...state, getOrdersError: action.error }
        case GET_MANIFEST_SUCCESS:
            return {...state, gettingManifest: false, manifest: action.payload, getManifestError: ''};
        case GET_MANIFEST_FAIL:
            return {...state, gettingManifest: false, manifest: '', getManifestError: action.error};
        case GETTING_MANIFEST:
            return {...state, gettingManifest: true, manifest: '', getManifestError: ''};
        case PUT_MANIFEST_SUCCESS:
            return {...state, updatingManifest: false, putManifestSuccess: true, putManifestError: ''};
        case PUT_MANIFEST_FAIL:
            return {...state, updatingManifest: false, putManifestSuccess: undefined, putManifestError: action.error};
        case UPDATING_MANIFEST:
            return {...state, updatingManifest: true, putManifestSuccess: undefined, putManifestError: undefined};
        case GET_LANDING_SUCCESS:
            return { ...state, landingData: action.payload, landingError: undefined, gettingLandingData: false };
        case GET_LANDING_FAIL:
            return { ...state, landingData: undefined, landingError: action.error, gettingLandingData: false };
        case GETTING_LANDING:
            return { ...state, landingData: undefined, landingError: undefined, gettingLandingData: true };
        case GET_SHIPMENT_FAIL:
            return { ...state, gettingShipment: false, shipment: undefined, getShipmentError: action.error }
        case GET_SHIPMENT_SUCCESS:
            return { ...state, gettingShipment: false, shipment: action.payload, getShipmentError: undefined }
        case GETTING_SHIPMENT:
            return { ...state, gettingShipment: true, shipment: undefined, getShipmentError: undefined }
        case GET_TAX_SUCCESS:
            return { ...state, gettingTax: false, tax: action.payload, getTaxError: undefined }
        case GET_TAX_FAIL:
            return { ...state, gettingTax: false, tax: undefined, getTaxError: action.error }
        case GETTING_TAX:
            return { ...state, gettingTax: true, tax: undefined, getTaxError: undefined }
        case GETTING_ORDER:
            return { ...state, order: undefined, getOrderError: undefined, gettingOrder: true }
        case GET_ORDER_SUCCESS:
            return { ...state, order: action.payload, getOrderError: undefined, gettingOrder: false }
        case GET_ORDER_FAIL:
            return { ...state, order: undefined, getOrderError: action.error, gettingOrder: false }
        case RECHARGE_SUCCESS:
              return { ...state, rechargeSuccess: action.payload, rechargeError: undefined}
        case RECHARGE_FAIL:
              return { ...state, rechargeSuccess: undefined, rechargeError: action.error}
        default:
            return state;
    }
};
