import React from 'react';
import {
    AboutUsContainer as Container,
    MegaMenuItem as Item,
} from '../../../Style';
import { Link } from 'react-router-dom';

export const AboutUs = ({ history, setOpen, spaceTop }) => {
    const { search } = window.location;
    return (
        <Container onMouseEnter={() => setOpen(true)} onMouseLeave={() => { setOpen(false) }} spaceTop={spaceTop}>
            <Item><Link to={{ pathname: `/about`, search }} title="About Us" style={{ paddingBottom: 20 }}>About Us</Link></Item>
            <Item><Link to={{ pathname: `/science`, search }} title="Our Science">Our Science</Link></Item>
        </Container>
    )
}