import React, { Fragment } from 'react';
import { SingleChoiceModal } from '../Modals'
export const CadenceModal = ({ close, cadenceMonths }) => {
    return (
        <SingleChoiceModal
            title={(<Fragment>Personalized Delivery {window.innerWidth < 420 && <br />}Schedule</Fragment>)}
            body={(
                <p>
                    As a result of your household size and product selections, your upcoming delivery should keep you well stocked for {cadenceMonths} month{cadenceMonths > 1 ? 's' : ''}.
                    <br /><br />
                    { cadenceMonths > 1 && "This saves boxes, saves trips, is more efficient for you and better for the environment 😉"}
                </p>
            )}
            close={close}
            next={close}
            buttonCopy="Got it, thanks!"
        />
    )
}