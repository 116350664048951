import * as Sentry from '@sentry/browser';
import awsKeys from '../aws-config';

const crypto = require('crypto');

const AWS_SHA_256 = 'AWS4-HMAC-SHA256';
const AWS4_REQUEST = 'aws4_request';
const AWS4 = 'AWS4';
const X_AMZ_DATE = 'x-amz-date';
const X_AMZ_SECURITY_TOKEN = 'x-amz-security-token';
const HOST = 'host';
const AUTHORIZATION = 'Authorization';

const hmac = (key, string, encoding) => crypto
  .createHmac('sha256', key)
  .update(string, 'utf8')
  .digest(encoding);

const hash = (string, encoding = 'hex') => crypto
  .createHash('sha256')
  .update(string, 'utf8')
  .digest(encoding);

const assertDefined = (object, name) => {
  if (!object) {
    console.error(name + ' must be defined');
    Sentry.captureException(`AGS: ${name} must be defined`);
  }
  return object;
};

const canonicalRequest = (method, path, queryParams, headers, payload) => [
  method,
  encodeURI(path),
  buildCanonicalQueryString(queryParams),
  buildCanonicalHeaders(headers),
  buildCanonicalSignedHeaders(headers),
  hash(payload)
].join('\n');

const buildCanonicalQueryString = (queryParams) =>
  Object.keys(queryParams).length < 1 ? '' : Object.keys(queryParams)
    .sort()
    .map((it) => it + '=' + fixedEncodeURIComponent(queryParams[it]))
    .join('&');

const fixedEncodeURIComponent = (str) => encodeURIComponent(str)
  .replace(/[!'()*]/g, (c) => '%' + c.charCodeAt(0).toString(16)
    .toUpperCase());

const buildCanonicalHeaders = (headers) => Object.keys(headers)
  .map((it) => it)
  .sort()
  .map((it) => it.toLowerCase() + ':' + headers[it])
  .join('\n') + '\n';

const buildCanonicalSignedHeaders = (headers) => Object.keys(headers)
  .map((property) => property.toLowerCase())
  .sort()
  .join(';');

const stringToSign = (datetime, scope, hashedCanonicalRequest) => [
  AWS_SHA_256,
  datetime,
  scope,
  hashedCanonicalRequest
].join('\n');

const credentialScope = (datetime, region, service) => datetime
  .substr(0, 8) + '/' + region + '/' + service + '/' + AWS4_REQUEST;

const calculateSigningKey = (secretAccessKey, datetime, region, service) =>
  hmac(hmac(hmac(hmac(AWS4 + secretAccessKey, datetime.substr(0, 8)),
    region), service), AWS4_REQUEST);

const calculateSignature = (key, str) => hmac(key, str, 'hex');

const authorizationHeader = (accessKeyId, scope, headers, signature) =>
  AWS_SHA_256 + ' Credential=' + accessKeyId + '/' + scope +
    ', SignedHeaders=' + buildCanonicalSignedHeaders(headers) +
    ', Signature=' + signature;

const signApiGatewayRequest = (params, credentials) => {
  const serviceName = 'execute-api';
  // BUG: credentials.identityId is sometimes undefined: https://github.com/aws-amplify/amplify-js/issues/6535
  // const region = credentials.identityId.split(':').shift();
  const region = awsKeys.Auth.region;
  const method = assertDefined(params.method, 'method');
  const path = assertDefined(params.path, 'path');
  
  // Debug logging error AGS: secretAccessKey must be defined
  if (credentials.accessKeyId === undefined || credentials.secretAccessKey === undefined) {
    console.error("Cognito creds undefined state: ", credentials);
  }

  const accessKeyId = assertDefined(credentials.accessKeyId, 'accessKeyId');
  const secretAccessKey = assertDefined(credentials.secretAccessKey,
    'secretAccessKey');
  const sessionToken = credentials.sessionToken;

  let queryParams = {...params.queryParams};
  if (!queryParams) {
    queryParams = {};
  }

  let headers = {...params.headers};
  if (!headers) {
    headers = {};
  }

  if (!headers['Accept']) {
    headers['Accept'] = '*/*';
  }

  const data = !!params.data ? JSON.stringify(params.data) :
    !params.body || method === 'GET' ? '' : params.body;

  if (!data) {
    delete headers['Content-Type'];
  }

  const datetime = new Date().toISOString()
    .replace(/\.\d{3}Z$/, 'Z')
    .replace(/[:-]|\.\d{3}/g, '');

  headers[X_AMZ_DATE] = datetime;

  headers[HOST] = params.host;

  const hcr = hash(canonicalRequest(method, path, queryParams, headers, data));
  const scope = credentialScope(datetime, region, serviceName);
  const signingKey = calculateSigningKey(secretAccessKey, datetime, region,
    serviceName);
  const signature = calculateSignature(signingKey, stringToSign(datetime,
    scope, hcr));

  headers[AUTHORIZATION] = authorizationHeader(accessKeyId, scope, headers,
    signature);

  if (!!sessionToken) {
    headers[X_AMZ_SECURITY_TOKEN] = sessionToken;
  }
  delete headers[HOST];

  let url = 'https://' + params.host + path;
  const queryString = buildCanonicalQueryString(queryParams);
  if (queryString !== '') {
    url += '?' + queryString;
  }

  return {
    method,
    url,
    headers,
    data
  };
};

export default signApiGatewayRequest;
