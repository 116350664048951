import React from 'react';
import { linkedin_logo } from '../../../assets';


export const LinkedinShare = ({
      shareProfile,
      urlToShare
  }) => {

        const LinkedinLinkToReturn = () => {
            if (shareProfile) {
                return (
                    <span>
                        <a target="_blank"
                            href={urlToShare}
                            rel="noopener noreferrer"
                        >
                            <img src={linkedin_logo} alt="linkedin_logo" />
                        </a>
                    </span>
                )
            } 
        }

        return(
            <>
                {LinkedinLinkToReturn()}
            </>
        )
}