import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { Logger } from '../helpers/Logger';
const logger = new Logger();

const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

function setUser(userId, params={}) {
  ReactGA.set({userId: userId});
  
  hotjarSetUserId(userId, params);
  
  Sentry.addBreadcrumb({
      category: "username",
      message: `User: ${userId}`,
      level: Sentry.Severity.Info
  })
}

function hotjarSetUserId(userId, params={}) {
  if (window.hj === undefined) {
    console.log("hotjar not present skipping setting userId: ", userId, params);
    return;
  }
  window.hj('identify', userId, params);
}

function trackPageView(page, options) {
  ReactGA.set({
    page,
    ...options
  });
  ReactGA.pageview(page);
  facebookPixel('track', 'PageView');
}

function facebookPixel() {
  if (prod && window.fbq) {
    try {
      window.fbq(...arguments);
    } catch (err) {
      console.error("Error tracking fb pixel: ", err, arguments)
    }
  } else {
    logger.log("fbq tracking[disabled]: ", arguments)
  }
}

export default {
  setUser,
  trackPageView,
  facebookPixel,
}
