import React, { Fragment } from 'react';
import { InterviewQuestion } from '../../Common';
import * as images from '../../../assets';
import { marginForCountdown } from '../../../actions';
import { featureFlags } from '../../../config';

class TierPreferences extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tierPreferences: [],
            tiers: [
                "EcoFriendly",
                "NationallyRecognizedBrandLead",
                "SupplyDropPick",
                "Value",
                "SensitiveSkin",
                "SocialGood",
                "AllNatural"
            ],
            infoCopy: (
                <div>
                    We’ll select the best products and brands to suit your needs based on your unique preferences.
                </div>
            )
        }

        this.toggleTier = this.toggleTier.bind(this);
        this.setPreferences = this.setPreferences.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.renderTiers = this.renderTiers.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { interview, zipcode } = signUpData;
            if (interview) {
                this.setStateForKeyValue(interview);

                this.setState({ interview })
                if (interview.tierPreferences) {
                    this.setState({ tierPreferences: interview.tierPreferences });
                    interview.tierPreferences.forEach(tier => {
                        this.setState({ [tier]: true })
                    })
                }
            } else {
                this.back();
            }

            if (zipcode)
                marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    toggleTier(tier) {
        const { tierPreferences, tiers } = this.state;
        if (this.state[tier]) {
            // remove tier
            const newPreferences = tierPreferences.filter(t => { return t !== tier });
            tiers.forEach(t => {
                const value = this.state[t];
                if (value && value > this.state[tier]) {
                    this.setState({ [t]: value - 1 })
                }
            })
            this.setState({ tierPreferences: newPreferences, [tier]: false });
        } else {
            tierPreferences.push(tier);
            this.setState({ tierPreferences, [tier]: tierPreferences.length })
        }
    }


    back() {
        const { signUpData } = this.state;
        this.props.history.push('/laundry/1', { signUpData })
    }

    next() {
        const {
            tierPreferences,
            interview,
            signUpData
        } = this.state;

        if (tierPreferences.length) {
            interview.tierPreferences = tierPreferences
            signUpData.interview = interview;
            const pathname = featureFlags.recommendation_page ? '/recommendation' : '/prediction';
            this.props.history.push(pathname, { signUpData })
        }
    }

    setPreferences() {
        const { tierPreferences } = this.state;

        const preferences = [];
        tierPreferences.forEach(p => {
            preferences.push(p.tier);
        })

        this.props.next({ tierPreferences: preferences });
    }

    renderTiers() {
        const {
            EcoFriendly,
            NationallyRecognizedBrandLead,
            SupplyDropPick,
            Value,
            SensitiveSkin,
            SocialGood,
            AllNatural
        } = this.state;

        return (
            <div className="tiers-container">
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.NationallyRecognizedBrandLead} className="tier-emoji" alt="Brands I Know" /></div>
                    <div id="brand-btn" className={`interview-pill brand ${NationallyRecognizedBrandLead ? 'selected' : ''}`} onClick={() => this.toggleTier('NationallyRecognizedBrandLead')}>Brands I Know</div>
                    <div className="tier-rank">{NationallyRecognizedBrandLead ? NationallyRecognizedBrandLead : ''}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.SupplyDropPick} alt="Premium Products" /></div>
                    <div id="premium-btn" className={`interview-pill brand ${SupplyDropPick ? 'selected' : ''}`} onClick={() => this.toggleTier('SupplyDropPick')}>Premium Products</div>
                    <div className="tier-rank">{SupplyDropPick ? SupplyDropPick : ''}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.EcoFriendly} alt="Eco-Friendly" /></div>
                    <div id="eco-btn" className={`interview-pill brand ${EcoFriendly ? 'selected' : ''}`} onClick={() => this.toggleTier('EcoFriendly')}>Eco-Friendly</div>
                    <div className="tier-rank">{EcoFriendly ? EcoFriendly : ''}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.SensitiveSkin} alt="Sensitive Skin" /></div>
                    <div id="sensitive-btn" className={`interview-pill brand ${SensitiveSkin ? 'selected' : ''}`} onClick={() => this.toggleTier('SensitiveSkin')}>Sensitive Skin</div>
                    <div className="tier-rank">{SensitiveSkin ? SensitiveSkin : ""}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.Value} alt="Value" /></div>
                    <div id="value-btn" className={`interview-pill brand ${Value ? 'selected' : ''}`} onClick={() => this.toggleTier('Value')}>Value</div>
                    <div className="tier-rank">{Value ? Value : ""}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.SocialGood} alt="Socially Conscious" /></div>
                    <div id="social-btn" className={`interview-pill brand ${SocialGood ? 'selected' : ''}`} onClick={() => this.toggleTier('SocialGood')}>Socially Conscious</div>
                    <div className="tier-rank">{SocialGood ? SocialGood : ""}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.AllNatural} alt="All Natural" /></div>
                    <div id="natural-btn" className={`interview-pill brand ${AllNatural ? 'selected' : ''}`} onClick={() => this.toggleTier('AllNatural')}>All Natural</div>
                    <div className="tier-rank">{AllNatural ? AllNatural : ''}</div>
                </div>
            </div>
        )
    }

    render() {
        const { infoCopy } = this.state;
        const { history } = this.props;
        return (
            <InterviewQuestion
                key="TierPreferences"
                id="TierPreferences"
                back={this.back}
                next={this.next}
                history={history}
                question={
                    <Fragment>
                        When buying home supplies which of these is most important to you?
                        <br />Select as many as you want in order of preference.
                    </Fragment>
                }
                body={this.renderTiers}
                infoCopy={infoCopy}
                title="Supply Drop - Personalization - Brand Preference"
            />
        )
    }

}

export default TierPreferences;