import styled, { css } from 'styled-components';
import { flexSpaceBetween, w100, autoMarginLR, autoMarginTB, mediaQueries } from './';
import { colors } from './Colors';
import { flexRow, flexSpaceAround } from './Flex';

export const interviewMaxWidth = '1110px';

export const TopRow = styled.div`
    ${{ ...flexSpaceBetween, ...w100, ...autoMarginLR }}
    max-width: ${interviewMaxWidth};
    ${props => props.landingNav && css`
        ${mediaQueries("md")`
            justify-content: space-around;
        `};
        `
	}
`

export const InterviewHouseholdBtn = styled.div`
    ${{ ...flexRow, ...flexSpaceAround }}
    background: ${colors.pureWhite};
    border: 3px solid ${colors.borderDark};
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-radius: 8px;
    padding: 15px 20px;
    width: 170px;
    height: 90px;
    margin: 15px;
    cursor: pointer;
    transition: all .2s linear;
    ${props => props.disabled && css`
        cursor: default;
        padding: 15px 32px;
        justify-content: space-between;
        width: 110px;
        padding: 15px;
        ${mediaQueries("md")`
            margin-right: auto;
            margin-left: auto;
        `}
    `}
    ${props => props.isPet && css`
        width: 72px !important;
        padding-left: 18px;
    `}
`

export const InterviewHouseholdBtnAge = styled.div`
    ${{ ...autoMarginTB }}
    ${props => props.disabled && css`
        font-size: 18px;
    `}
`

export const HouseholdMemberImg = styled.img`
    height: 63px;
    width: auto;
`