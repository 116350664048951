import React from 'react';
import { twitter_logo } from '../../../assets';
import ReactGA from 'react-ga';


export const ShareTwitter = ({
      shareProfile,
      urlToShare,
      textToShare
  }) => {

        let twitterURL=`https://twitter.com/share?text=${textToShare}&url=${urlToShare}`

        const reportGoogleEvent = () => {
              ReactGA.event({
                  category: 'DashboardUIEvents',
                  action: 'Clicked Share via Twitter',
                  label: `${urlToShare}`,
              })
        }

        const twitterLinkToReturn = () => {
            if (shareProfile) {
                return (
                    <span>
                        <a target="_blank"
                            href={urlToShare}
                            rel="noopener noreferrer"
                        >
                            <img src={twitter_logo} alt="twitter_logo" />
                        </a>
                    </span>
                )
            } else {
                return (
                    <span>
                        <a target="_blank"
                            rel="noopener noreferrer"
                            href={twitterURL}
                            className="twitter-share-button"
                        >
                            <img src={twitter_logo} onClick={reportGoogleEvent} alt="twitter_logo" />
                        </a>
                        <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                    </span>
                )
            }
        }

        return(
            <>
                {twitterLinkToReturn()}
            </>
        )
}