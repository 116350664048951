import axios from 'axios';
import {
    GET_PRODUCT_GROUPS_SUCCESS,
    GET_PRODUCT_GROUPS_FAIL,
    GETTING_PRODUCT_GROUPS
} from './types';
import { handleApiError } from '../helpers/apiHelper';
import getSignature from '../helpers/aws4-utl';

export const getProductGroups = () => {
    return async (dispatch) => {
        dispatch({ type: GETTING_PRODUCT_GROUPS });
        getSignature('GET', {}, '/product_groups').then(req => {
            axios.request(req.request)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_PRODUCT_GROUPS_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, GET_PRODUCT_GROUPS_FAIL))
                }).catch(err => {
                    dispatch(handleApiError(err, GET_PRODUCT_GROUPS_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_PRODUCT_GROUPS_FAIL))
        })
    }
}