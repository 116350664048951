import styled from 'styled-components';
import { autoMarginLR, mediaQueries } from './Common';
import { flexCol } from './Flex';

export const LoopContainer = styled.div`
    ${{ ...autoMarginLR, ...flexCol }}
    position: relative;
    width: 752px;
    margin-bottom: 40px;
    margin-top: 40px;
    ${mediaQueries("md")(`
        width: 300px;
        min-height: 500px;
        padding-bottom: 120px;
    `)}
`

export const LoopIcon = styled.img`
    ${{ ...autoMarginLR }}
`

export const LoopStepContainer = styled.div`
    width: 50%;
    text-align: center;
    ${mediaQueries("md")(`
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    `)}
`

export const LoopText = styled.div`
    ${{ ...autoMarginLR }}
    width: 90%;
    padding: 15px;
    text-align: center;
`

export const TopArrow = styled.img`

`

export const LeftArrow = styled.img`
    position: absolute;
    top: 40%;
    left: 120px;
    height: 200px;
    height: 140px;
    ${mediaQueries("md")(`
        height: 255px;
        left: -32px;
        top: 30%;
    `)}
`

export const RightArrow = styled.img`
    position: absolute;
    top: 40%;
    right: 120px;
    height: 140px;
    ${mediaQueries("md")(`
        height: 255px;
        right: -32px;
        top: 30%;
    `)}
`

export const LoopFamilyContainer = styled.div`
    ${mediaQueries("md")(`
        display: none;
    `)}
`

export const LoopFamily = styled.img`
    display: block;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    ${mediaQueries("md")`
        display: none;
    `}
`
export const ProcessLoopBody = styled.div`
    ${{ ...autoMarginLR }}
    position: relative;
    max-width: 1110px;   
`