import React, {Component} from 'react';
import {DocModal} from '..';
import {
    LndFooter,
    FooterContainer,
    FooterPoliciesContainer,
    FooterText,
    FooterMainText,
    FooterPolicies,
    FooterSocial
} from '../../../Style';
import { FacebookShare, ShareTwitter, LinkedinShare, InstagramShare } from '../Social';

export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            doc: undefined
        }

        this.showDialog = this.showDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    renderDialog() { 
        const {doc, showDialog} = this.state;
        if(showDialog && !!doc) {
            return <DocModal doc={doc} hide={this.hideDialog}/>
        }
    }

    showDialog(doc) {
        this.setState({ showDialog: true, doc })
    }

    hideDialog() {
        this.setState({ showDialog: false, doc: undefined })
    }

    render() {
        const {showDialog} = this.state;
        const {landing} = this.props;
        let gitSha = '';
        if (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT !== 'production') {
            gitSha = `Build: ${process.env.REACT_APP_GIT_SHA}`;
        }
        return (
            <FooterContainer showDialog={showDialog} landing={landing}>
                {this.renderDialog()}
                <LndFooter>
                    <FooterSocial>
                        <FacebookShare shareProfile={true} urlToShare={"https://www.facebook.com/GetSupplyDrop/"}/>
                        <ShareTwitter shareProfile={true} urlToShare={"https://twitter.com/supplydrop"}/> 
                        <InstagramShare shareProfile={true} urlToShare={"https://www.instagram.com/supplydrophq/"}/>
                        <LinkedinShare shareProfile={true} urlToShare={"http://www.linkedin.com/company/getsupplydrop"}/>
                    </FooterSocial>                        
                    <FooterPoliciesContainer>
                        <FooterPolicies>
                            <a href="/#faqs" title="FAQs">FAQ</a>
                        </FooterPolicies>
                        <FooterPolicies onClick={() => window.location.href = "https://blog.supplydrop.com"}>Blog</FooterPolicies>
                        <FooterPolicies onClick={() => this.showDialog('Terms_of_Use')}>Terms of Use</FooterPolicies>
                        <FooterPolicies onClick={() => this.showDialog('Privacy_Policy')}>Privacy Policy</FooterPolicies>
                    </FooterPoliciesContainer>
                    <FooterMainText>
                        <FooterText>© {new Date().getFullYear()} Supply Drop Online, Inc. <br/>Supply Drop, supplydrop.com, and the Supply Drop parachuting box, are trademarks of Supply Drop Online, Inc.</FooterText>
                        <FooterText><b>COVID19 Anti-Hoarding Rules are Active:</b> Supply Drop is here to get you what you need when you need it. We do not allow orders that appear to be hoarding. If an order triggers our hoarding alerts (recommended quantities are adjusted by large percentage or attempts to game the system), we may cancel it.</FooterText>
                    </FooterMainText> 
                    {gitSha}
                </LndFooter>
            </FooterContainer>
        )
    }
}
