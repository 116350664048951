import axios from 'axios';
import {
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    UPDATING_ORDER,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    GETTING_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAIL,
    GET_MANIFEST_SUCCESS,
    GET_MANIFEST_FAIL,
    GETTING_MANIFEST,
    PUT_MANIFEST_FAIL,
    PUT_MANIFEST_SUCCESS,
    UPDATING_MANIFEST,
    CREATING_ORDER,
    CLEAR_ORDERS_STATE,
    GETTING_LANDING,
    GET_LANDING_SUCCESS,
    GET_LANDING_FAIL,
    CLEAR_ORDERS_ERRORS,
    GET_SHIPMENT_FAIL,
    GET_SHIPMENT_SUCCESS,
    GETTING_SHIPMENT,
    GET_TAX_SUCCESS,
    GET_TAX_FAIL,
    GETTING_TAX,
    CLEAR_CREATE_ORDERS,
    RECHARGE_SUCCESS,
    RECHARGE_FAIL,
} from './types';
import { handleApiError, getAuthHeaders } from '../helpers/apiHelper';
import getSignature from '../helpers/aws4-utl';
import keys from '../config';
import * as mockData from '../data/mock';
import { Auth } from 'aws-amplify';

const url = keys.apiUrl;
const isMock = !!sessionStorage.getItem('isMock');

export const clearOrdersState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_ORDERS_STATE })
    }
}

export const getTax = (body) => {
    return async (dispatch) => {
        dispatch({ type: GETTING_TAX });
        getSignature('POST', body, '/tax').then(req => {
            axios.request(req.request)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_TAX_SUCCESS, payload: res.data.taxAmount })
                    else
                        dispatch(handleApiError(res.error, GET_TAX_FAIL));
                })
                .catch(err => {
                    dispatch(handleApiError(err, GET_TAX_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_TAX_FAIL))
        })
    }
}

export const setEmptyTax = () => {
    return async (dispatch) => {
        dispatch({ type: GET_TAX_SUCCESS, payload: 0 })
    }
}

export const getOrders = () => {
    return (dispatch) => {
        if (isMock) {
            dispatch({ type: GET_ORDERS_SUCCESS, payload: mockData.orders })
        } else {
            getAuthHeaders().then(headers => {
                axios.get(`${url}/orders?new=1`, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: GET_ORDERS_SUCCESS, payload: res.data.orders })
                        else
                            dispatch(handleApiError(res.error, GET_ORDERS_FAIL));
                    })
                    .catch(err => {
                        dispatch(handleApiError(err, GET_ORDERS_FAIL))
                    })
            }).catch(err => {
                dispatch(handleApiError(err, GET_ORDERS_FAIL))
            })
        }
    }
}

export const getOrder = (orderId, isNew) => {
    return (dispatch) => {
        const params = isNew ? '?new=1' : '';
        if (isMock) {
            dispatch({ type: GET_ORDER_SUCCESS, payload: mockData.order })
        } else {
            dispatch({ type: GETTING_ORDER, payload: mockData.order })
            getAuthHeaders().then(headers => {
                axios.get(`${url}/orders/${orderId}${params}`, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: GET_ORDER_SUCCESS, payload: res.data.order })
                        else
                            dispatch(handleApiError(res.error, GET_ORDER_FAIL));
                    })
                    .catch(err => {
                        dispatch(handleApiError(err, GET_ORDER_FAIL))
                    })
            }).catch(err => {
                dispatch(handleApiError(err, GET_ORDER_FAIL))
            })
        }
    }
}

export const putManifest = (products) => {
    const body = { products };
    return (dispatch) => {
        dispatch({ type: UPDATING_MANIFEST })
        if (isMock) {
            dispatch({ type: PUT_MANIFEST_SUCCESS, payload: true })
        } else {
            getAuthHeaders().then(headers => {
                axios.put(`${url}/manifest?new=1`, body, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: PUT_MANIFEST_SUCCESS, payload: res.data })
                        else {
                            dispatch(handleApiError(res.error, PUT_MANIFEST_FAIL))
                        }
                    }).catch(err => {
                        dispatch(handleApiError(err, PUT_MANIFEST_FAIL))
                    })
            }).catch(err => {
                dispatch(handleApiError(err, PUT_MANIFEST_FAIL))
            })
        }
    }
}

export const createOrder = (order, unauthedId) => {
    const body = { order };
    return (dispatch) => {
        dispatch({ type: CREATING_ORDER })
        if (isMock) {
            dispatch({ type: CREATE_ORDER_SUCCESS, payload: mockData.initOrder })
        }
        Auth.currentCredentials().then(creds => {
            getAuthHeaders({ 'X-CognitoUnauthedIdentityId': unauthedId, 'X-CognitoAuthedIdentityId': creds._identityId }).then(headers => {
                axios.post(`${url}/orders`, body, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: CREATE_ORDER_SUCCESS, payload: res.data })
                        else
                            dispatch(handleApiError(res.error, CREATE_ORDER_FAIL))
                    })
                    .catch(err => {
                        dispatch(handleApiError(err, CREATE_ORDER_FAIL))
                    })
            })
            .catch(err => {
                dispatch(handleApiError(err, CREATE_ORDER_FAIL))
            })
        }).catch(err => {
            dispatch(handleApiError(err, CREATE_ORDER_FAIL))
        })
    }
}

export const updateOrder = (source, orderId) => {
    const body = { source };
    return (dispatch) => {
        dispatch({ type: UPDATING_ORDER })
        getAuthHeaders().then(headers => {
            axios.put(`${url}/orders/${orderId}`, body, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, UPDATE_ORDER_FAIL));
                })
                .catch(err => {
                    dispatch(handleApiError(err, UPDATE_ORDER_FAIL));
                })
        })
    }
}

export const getManifest = () => {
    return (dispatch) => {
        dispatch({ type: GETTING_MANIFEST })
        getAuthHeaders().then(headers => {
            axios.get(`${url}/manifest?new=1`, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_MANIFEST_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, GET_MANIFEST_FAIL))
                })
                .catch(err => {
                    dispatch(handleApiError(err, GET_MANIFEST_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_MANIFEST_FAIL))
        })
    }
}

export const getLanding = (search) => {
    return async (dispatch) => {
        getSignature('GET', undefined, '/landing', Object.fromEntries(new URLSearchParams(search))).then(req => {
            dispatch({ type: GETTING_LANDING })
            axios.request(req.request)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_LANDING_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, GET_LANDING_FAIL));
                }).catch(err => {
                    dispatch(handleApiError(err, GET_LANDING_FAIL));
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_LANDING_FAIL));
        })
    }
}

export const getShipmentDate = (zipcode) => {
    return async (dispatch) => {
        dispatch({ type: GETTING_SHIPMENT })
        getSignature('GET', undefined, `/shipment/${zipcode}`).then(req => {
            axios.request(req.request)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_SHIPMENT_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, GET_SHIPMENT_FAIL));
                }).catch(err => {
                    dispatch(handleApiError(err, GET_SHIPMENT_FAIL));
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_SHIPMENT_FAIL));
        })
    }
}

export const clearOrderErrors = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_ORDERS_ERRORS })
    }
}

export const clearCreateOrderState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_CREATE_ORDERS })
    }
}

export const recharge = (orderId, isNew) => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.put(`${url}/orders/${orderId}/recharge`, {}, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: RECHARGE_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, RECHARGE_FAIL))
				})
				.catch(err => {
						dispatch(handleApiError(err, RECHARGE_FAIL))
				})
        }).catch(err => {
            dispatch(handleApiError(err, RECHARGE_FAIL))
        })
	}
}
