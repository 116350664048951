import React, { Fragment, useEffect} from 'react';
import ReactGA from 'react-ga';
import {SingleChoiceModal} from './SingleChoiceModal'

export const MoreInfo = (props) => {

	const {close, copy} = props

	useEffect(()=>{
		ReactGA.pageview('/more-info');
		return ()=>{
			ReactGA.pageview(window.location.pathname + window.location.search)
		}
	},[])

	if(close && copy) {
		return (
			<SingleChoiceModal close={close} body={copy} next={close} buttonCopy="Go Back" />
		)
	} else {
		return (
			<Fragment/>
		)
	}
}