import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import Cleaning from '../../Interview/Kitchen/Cleaning';
import Cooking from '../../Interview/Kitchen/Cooking';
import DishwashingFrequency from '../../Interview/Kitchen/DishwashingFrequency';

export class KitchenFlow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentQuestion: "onCooking"
        };

        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.close = this.close.bind(this);
    }
    
    componentDidMount() {
        const {interview} = this.props;
        this.setState({interview});
    }

    sendInterview(housekeeping) {
        const {cookingFrequency, dishwashing} = this.state;
        const kitchenQuestions = {
            cookingFrequency,
            housekeeping,
            dishwashing
        };
        this.props.updateInterview(kitchenQuestions);
    }

    next(question) {
        try {
            const questionKey = Object.keys(question)[0];
            const value = Object.values(question)[0];
            let currentQuestion;
            let complete;
            switch(questionKey) {
                case 'cookingFrequency':
                    currentQuestion = 'onDishwashingFrequency';
                    break;
                case 'dishwashing':
                    // currentQuestion = this.state.currentQuestion === 'onDishwashingProducts' || !value.frequency ? 'onHousekeeping' : 'onDishwashingProducts';
                    currentQuestion = 'onHousekeeping';
                    break;
                default:
                    complete = true;
                    break;
            }
            this.setState({[questionKey]: value})
            if(complete) {
                this.sendInterview(value);
            } else {
                this.setState({currentQuestion})
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    back() {
        const {currentQuestion} = this.state;
        try {
            switch(currentQuestion) {
                case 'onDishwashingFrequency':
                    this.setState({currentQuestion: 'onCooking'});
                    break;
                // case 'onDishwashingProducts':
                //     this.setState({currentQuestion: 'onDishwashingFrequency'});
                //     break;
                case 'onHousekeeping':
                    // if(this.state.dishwashingFrequency) {
                    //     this.setState({currentQuestion: 'onDishwashingProducts'});
                    // } else {
                        this.setState({currentQuestion: 'onDishwashingFrequency'});
                    // }
                    break;
                default:
                    this.props.close('onCooking');
                    break;
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    renderCurrentQuestion() {
        const {currentQuestion, unsavedChanges} = this.state;
        const {interview} = this.props;
        if(!unsavedChanges) {
            switch(currentQuestion) {
                case 'onDishwashingFrequency':
                    return <DishwashingFrequency interview={interview} isModal={true} next={this.next} back={this.back} close={this.close} history={this.props.history} />
                // case 'onDishwashingProducts':
                //     return <DishwashingProducts isModal={true} next={this.next} back={this.back} />
                case 'onHousekeeping':
                    return <Cleaning interview={interview} isModal={true} next={this.next} back={this.back} close={this.close} history={this.props.history} />
                default:
                    return <Cooking interview={interview} isModal={true} next={this.next} back={this.back} close={this.close} history={this.props.history} />
            }
        }
    }

    close() {
        const {currentQuestion} = this.state;
        this.props.close(currentQuestion)
    }

    render() {
        return this.renderCurrentQuestion()
    }
}