import React from 'react';
import { BundleProduct } from '..';

export const ProductsModal = ({
    cta,
    bundleTitle,
    productGroups,
    productTypes,
    selectedProductGroup,
    formattedProductType,
    selectProductGroup,
    onQuantityChange,
    showOtherBrands,
    showDescription,
    rounderSet,
    close,
    bundleShortName,
    interview,
    handleDropdownSelect,
    displayUnits,
    isDashboard
}) => {
    const productGroupRows = [];
    const selectedProductGroupRow = [];
    const trashTypes = ["Kitchen", "Small", "Compost", "Recycling", "Outdoor"]

    const getTrashNeeds = (interview) => {
        const res = {};
        const trashMap = {};

        interview.trashPreferences.forEach(t => {
            const { frequency, type } = t;
            trashMap[type] = frequency;
        });

        trashTypes.forEach(trashType => {
            if (!trashMap[trashType]) {
                res[`TrashBags${trashType}`] = true;
            }
        })
        return res;
    }
    
    if (cta === "change") {
        for (let i = 0; i < productGroups.length; i++) {
            const productGroup = productGroups[i];
            const { groupName, pricePerArticle } = productGroup;
            if (selectedProductGroup && selectedProductGroup.groupName === groupName) {
                selectedProductGroupRow.push(
                    <BundleProduct
                        key={groupName}
                        productGroup={productGroup}
                        bundleShortName={bundleShortName}
                        onQuantityChange={onQuantityChange}
                        showDescription={showDescription}
                        selected={true}
                        selectProductGroup={selectProductGroup}
                        hideProductType={true}
                        cta={cta}
                        isDashboard={isDashboard}
                        showRemove={true}
                    />
                )
                selectedProductGroupRow.push(<hr />)
            } else {
                productGroupRows.push(
                    <BundleProduct
                        key={groupName}
                        productGroup={productGroup}
                        bundleShortName={bundleShortName}
                        onQuantityChange={onQuantityChange}
                        hideProductType={true}
                        showDescription={showDescription}
                        selected={false}
                        selectProductGroup={selectProductGroup}
                        pricePerArticle={pricePerArticle}
                        cta={cta}
                        isDashboard={isDashboard}
                    />
                )
                if (productGroups.length) {
                    if (i < productGroups.length - 1) {
                        productGroupRows.push(<hr />)
                    }
                }
            }
        }
    } else {
        const needsTrashTypes = getTrashNeeds(interview);

        for (let i = 0; i < Object.entries(productTypes).length; i++) {
            const productGroup = Object.values(productTypes)[i].preferred;
            const { groupName, articles, productType, proposedArticles } = productGroup;


            const onlyOffering = !Object.values(rounderSet[productType].additional).length;
            productGroupRows.push(
                <BundleProduct
                    key={groupName}
                    productGroup={productGroup}
                    bundleShortName={bundleShortName}
                    onQuantityChange={onQuantityChange}
                    showDescription={showDescription}
                    showOtherBrands={() => showOtherBrands(productType, bundleShortName)}
                    selectProductGroup={selectProductGroup}
                    onlyOffering={onlyOffering}
                    selected={false}
                    hideProductType={false}
                    articles={articles !== undefined ? articles : proposedArticles}
                    cta={cta}
                    handleDropdownSelect={handleDropdownSelect}
                    needsTrashQuestion={needsTrashTypes[productType]}
                    isDashboard={isDashboard}
                />
            )
            if (Object.entries(productTypes).length) {
                if (i < Object.entries(productTypes).length - 1) {
                    productGroupRows.push(<hr />)
                }
            }
        }
    }
    return (
        <div className="modal-container" id={`${cta}-products-modal-container`}>
            <div id={`${cta}-products-modal`} className="products-modal slideInRight animated-fast">
                <div className="products-modal-top-row">
                    <div onClick={() => close(cta)} className="fa fa-chevron-up products-modal-back" />
                    <div className="products-modal-title">{cta === "add" ? `${bundleTitle}` : `Change ${formattedProductType}`}</div>
                </div>
                <div className="products-modal-body">
                    {selectedProductGroupRow}
                    {productGroupRows}
                </div>
            </div>
        </div>
    )
}
