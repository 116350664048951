import React from 'react';
import {
    LineItemRow as Row,
    LineItemImg as Img,
    LineItemPrice as Price
} from '../../../Style/Receipt';

export const ProductLineItem = ({
    imageUrl,
    brandName,
    displayName,
    articlesPerPackage,
    quantity,
    productId,
    priceCents,
    units
}) => {
    return (
        <Row key={productId}>
            <td>
                <Img src={imageUrl} alt={`${brandName} ${displayName}`} />
            </td>
            <td>
                {brandName} {displayName}
            </td>
            <td>
                {quantity} X {articlesPerPackage} {units}
            </td>
            <Price>
                ${(priceCents / 100).toFixed(2)}
            </Price>
        </Row>
    )
}