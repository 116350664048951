import React, { Component, Fragment } from 'react';
import { getDeliveryDate } from '../../helpers';
import * as images from '../../assets';
import { getShipmentDate } from '../../actions';
import { connect } from 'react-redux';
import moment from 'moment';
import { MetaTags, Nav, ContinueButton, Loading, OutageBanner } from '../Common';
import Countdown from '../Common/Connected/Countdown';
import { CouponAlert } from '../Common/Alerts/CouponAlert';
import * as Sentry from '@sentry/browser';
import { featureFlags } from '../../config';
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

class AvailabilitySuccess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isZipValid: true,
            isEmailValid: true,
            zipcode: "",
            email: ""
        };
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
        clearInterval(this.setDeliveryDate)
    }

    handleKeydown(e) {
        if (e.keyCode && e.keyCode === 13) {
            this.next();
        }
    }

    componentDidMount() {
        if (prod) {
            window.rdt('track', 'ViewContent')
        }

        if (this.props.location && this.props.location.state && this.props.location.state.signUpData) {
            this.setSignUpData(this.props.location.state.signUpData)
        } else {
            Sentry.captureException(new Error('No signUpData'));
            this.props.history.push('/check-availbility')
        }
    }

    componentDidUpdate() {
        const { shipment } = this.props;

        if (shipment && !this.state.shipment) {
            this.setState({ shipment })
            const { deliveryDate } = getDeliveryDate(shipment);
            if (deliveryDate)
                this.setState({ deliveryDate })
        }
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData, ...signUpData })
            this.setStateForKeyValue(signUpData);

            const { rc } = signUpData;

            if (rc !== "sd4me") {
                this.props.getShipmentDate(signUpData.zipcode)
            }
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        this.props.history.goBack();
    }

    renderDeliveryDate() {
        const { deliveryDate } = this.state;
        if (deliveryDate) {
            return (
                <p>
                    <b>Estimated Delivery: {moment(deliveryDate).format('MMMM Do')}</b><br /><br />
                    Complete your signup before 12AM CT tonight to get your Supply Drop on {moment(deliveryDate).format('MMMM Do')}
                </p>
            )
        }
    }
    renderCountdown() {
        const { zipcode, shipment } = this.state;
        if (zipcode && !shipment) return <Countdown zipcode={zipcode} shipment={shipment} />
    }
    renderImg() {
        return <div className="interview-header-img"><img src={images["availability"]} alt="Availability" /></div>

    }
    renderLoading() {
        const { gettingShipment } = this.props;
        if (gettingShipment) {
            return (
                <div style={{ marginTop: 320 }}>
                    <Loading />
                </div>
            )
        }
    }
    renderContinue() {
        const { shipment } = this.state;
        if(this.props.location.state.signUpData) {
            const { rc } = this.props.location.state.signUpData;
            if (shipment || rc === "sd4me") {
                return (
                    <ContinueButton next={this.next} text={"Next"} pressText="press" keyText="enter" />
                )
            }

        }
    }
    renderSubheader() {
        try {
            const { signUpData } = this.state;
            if (signUpData && signUpData.rc === "sd4me") {
                return (
                    <div>
                        <h3 className="gate-header">Welcome friend!</h3>
                        <p className="interview-copy small">We’ll confirm your delivery date at the completion of your order.
                        <br /><br />You can expect to receive your delivery within 5-7 business days.</p>
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <h3 className="gate-header">Good news</h3>
                        <div className="interview-copy small">
                            Supply Drop is available in your area!<br /><br />
                            {this.renderDeliveryDate()}
                        </div>
                    </div>)
            }
        } catch(err) {
            Sentry.captureException(err);
            console.error(err)
        }
    }
    next() {
        const { signUpData } = this.state;
        this.props.history.push('/start/1', { signUpData });
    }

    renderCouponAlert() {
        const {
            coupon,
            newCoupon,
            sdCredit,
            isEmployeePerk,
            utm_campaign,
            utm_content
        } = this.state;
        const { gettingShipment } = this.props;

        if (!gettingShipment && (coupon || newCoupon || sdCredit)) {
            return (
                <CouponAlert
                    coupon={coupon}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    isEmployeePerk={isEmployeePerk}
                    utm_campaign={utm_campaign}
                    utm_content={utm_content}
                />
            )
        }
    }

    renderAlert() {
        if(featureFlags.block_signups) {
            return <OutageBanner />
        } else {
            return this.renderCountdown()
        }
    }

    render() {
        const {
            approved,
            zipcode,
        } = this.state;
        return (
            <Fragment>
                <MetaTags title="Supply Drop - Check Availability" />
                {this.renderAlert()}
                <Nav history={this.props.history} spaceTopLg={zipcode && approved} back={this.back} />
                <div className="interview-outer">
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        {this.renderImg()}
                        {this.renderSubheader()}
                        {this.renderCouponAlert()}
                        {this.renderLoading()}
                        {this.renderContinue()}
                    </div>
                </div>
            </Fragment>
        )


    }
}

const mapStateToProps = (state) => {
    const { gettingShipment, shipment, getShipmentError } = state.order;
    return { gettingShipment, shipment, getShipmentError };
}

export default connect(mapStateToProps, {
    getShipmentDate
})(AvailabilitySuccess)
