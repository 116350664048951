import {
    GET_FAQ_SUCCESS,
    GET_FAQ_FAIL,
    GETTING_FAQ
} from '../actions/types';

const INITIAL_STATE = {
    FAQError: undefined,
    FAQs: undefined,
    gettingFAQs: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GETTING_FAQ:
            return { ...state, FAQError: undefined, FAQs: undefined, gettingFAQs: true }
        case GET_FAQ_SUCCESS:
            return { ...state, FAQError: undefined, FAQs: action.payload, gettingFAQs: false };
        case GET_FAQ_FAIL:
            return { ...state, FAQError: action.error, FAQs: undefined, gettingFAQs: false };
        default:
            return state;
    }
};