import React, { Component } from 'react';
import * as images from '../../../assets';
import { InterviewQuestion } from '../../Common';
import { marginForCountdown } from '../../../actions';

class Cleaning extends Component {
    constructor(props) {
        super(props);

        this.state = {
            housekeeping: 1,
            infoCopy: (
                <div>
                    Some of us are clean freaks and others just tidy up from time to time.
                    Help us understand how much you clean your home, so we can make sure to send you the right amount of cleaning supplies.
                </div>
            ),
            image: {
                src: images.housekeeping,
                alt: "Broom"
            }
        };
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentDidMount() {
        if (this.props.interview && !this.state.interview) {
            this.setState({ interview: this.props.interview });
        }
        window.scrollTo(0, 0);

        this.setState({ isModal: this.props.isModal });

        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);
    }

    setSignUpData(signUpData) {
        try {
            this.setState({signUpData})
            this.setStateForKeyValue(signUpData);
            const { interview } = signUpData;
            if (interview) {
                this.setStateForKeyValue(interview);
            } else {
                this.back();
            }

            if (signUpData.zipcode)
                marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        const { signUpData } = this.state;
        this.props.history.push('/kitchen/2', { signUpData })
    }

    next() {
        const {
            housekeeping,
            interview,
            signUpData
        } = this.state;
        interview.housekeeping = housekeeping;
        const { isModal } = this.props;

        if (isModal) {
            this.props.next({ housekeeping })
        } else {
            signUpData.interview = interview;
            this.props.history.push('/laundry/1', { signUpData });
        }
    }

    decrement() {
        let { housekeeping } = this.state;
        if (housekeeping > 0) housekeeping--;
        this.setState({ housekeeping })
    }

    increment() {
        let { housekeeping } = this.state;
        housekeeping++;
        this.setState({ housekeeping })
    }

    render() {
        const { housekeeping, isModal, image, infoCopy } = this.state;
        const { history, close } = this.props;

        const counter = {
            min: 0,
            max: 31,
            count: housekeeping,
            increment: this.increment,
            decrement: this.decrement
        }

        return (
            <InterviewQuestion
                key="Cleaning"
                id="Cleaning"
                back={isModal ? this.props.back : this.back}
                next={this.next}
                close={close}
                history={history}
                image={image}
                question="How many times a month do you clean your home?"
                title="Supply Drop - Personalization - Kitchen & Cleaning"
                isModal={isModal}
                counter={counter}
                infoCopy={infoCopy}
            />
        );
    }
}

export default Cleaning;