import React from 'react';
import { coupon_checkmark } from '../../../assets/miscellaneous';
import { getCouponVal, getCouponDuration, getCreditVal } from '../../../actions';
import { campaigns } from '../../../data';
import {
    CouponAlertContainer as Container,
    CouponAlertBody as Body,
    FlexCol
} from '../../../Style';

export const CouponAlert = ({
    newCoupon,
    sdCredit,
    coupon,
    utm_content,
    utm_campaign,
    isEmployeePerk,
    spaceBottom
}) => {
    const { couponVal } = getCouponVal(newCoupon ? newCoupon : coupon);
    const { creditVal } = getCreditVal(sdCredit)
    const isCustomerReferral = utm_campaign && utm_campaign.includes("customer_referring_") && !isNaN(sdCredit)
    
    const durationString = getCouponDuration(coupon, newCoupon);
    if (couponVal) {
        let copy;
        if (isEmployeePerk) {
            if (campaigns[utm_content])
                copy = <span>All <b>{campaigns[utm_content].displayName} employees</b> receive {couponVal} off{durationString}</span>
        } else if (utm_campaign && utm_campaign.includes("fetch_atx")) {
            copy = <span>All <b>Fetch Residents</b> receive {couponVal} off{durationString}!</span>
        } else {
          if (isCustomerReferral) {
              copy = <span>Your special discount of {couponVal} and referral credit of {creditVal} will be applied at checkout!</span>    
          } else {
              copy = <span>Your special discount of {couponVal} will be applied at checkout!</span>    
          }
        }
        if (copy) {
            return (
                <Container spaceBottom={spaceBottom} className="animated-fast slideInDown">
                    <FlexCol>
                        <img alt="coupon" src={coupon_checkmark} />
                    </FlexCol>
                    <Body>
                        {copy}
                    </Body>
                </Container>
            )
        }
    } else if (isCustomerReferral) {
        let copy = <span>Your special referral credit of {creditVal} will be applied at checkout!</span>
        return (
            <Container spaceBottom={spaceBottom} className="animated-fast slideInDown">
                <FlexCol>
                    <img alt="coupon" src={coupon_checkmark} />
                </FlexCol>
                <Body>
                    {copy}
                </Body>
            </Container>
        )
    }
    return <div />;
}
