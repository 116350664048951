import React, { Component, Fragment } from 'react';
import { InterviewQuestion } from '..'
import * as images from '../../../assets';

export class InterviewQuestionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frequency: 1
        };

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.setCopy = this.setCopy.bind(this);
    }

    componentDidMount() {
        const { need } = this.props;
        this.setCopy(need);
    }

    setCopy(need) {
        const type = need.replace("needs", "");
        let image, question, displayProduct;
        if (type !== "Laundry") {
            image = {
                alt: type,
                src: images[`${type.split(' ')[0].toLowerCase()}_trash_icon`]
            };
            question = `How many ${type.toLowerCase()} bags do you use each week?`
            displayProduct = type.toLowerCase() + " trash bags";
        } else {
            image = {
                alt: "Laundry",
                src: images.laundryFrequency
            }
            question = "How many loads of laundry do you do every week?";
            displayProduct = type.toLowerCase();
        }
        const infoCopy = `The amount of ${displayProduct} you do helps us calculate how much detergent you’ll need each month.`;
        this.setState({ image, question, infoCopy })
    }

    increment() {
        const { frequency } = this.state;
        this.setState({ frequency: frequency + 1 })
    }

    decrement() {
        const { frequency } = this.state;
        if (frequency > 1)
            this.setState({ frequency: frequency + 1 })
    }

    renderQuestion() {
        const { back, next } = this.props;
        const { question, frequency, image, infoCopy } = this.state;
        if (question) {
            const counter = {
                min: 1,
                increment: this.increment,
                decrement: this.decrement,
                count: frequency
            }

            return (
                <InterviewQuestion
                    next={() => next(frequency)}
                    back={back}
                    close={back}
                    infoCopy={infoCopy}
                    image={image}
                    question={question}
                    isModal={true}
                    history={this.props.history}
                    counter={counter}
                />
            )
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderQuestion()}
            </Fragment>
        )
    }
}