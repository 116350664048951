import React, { Component, Fragment } from 'react';
import {
    Nav,
    MetaTags,
    OrderSummary,
    Loading,
    ContinueButton,
    CadenceModal,
    CadenceAlert
} from '../Common';
import PhoneVerification from '../Common/Connected/PhoneVerification';
import moment from 'moment';
import { getUser, getCadenceMonths } from '../../actions';
import * as Sentry from '@sentry/browser';
import keys from '../../config';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import * as images from '../../assets';
import { Auth } from 'aws-amplify';
import Confetti from 'react-confetti';
import { CheckoutBody as Body } from '../../Style';
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

class OrderComplete extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.sendGATransaction = this.sendGATransaction.bind(this);
        this.next = this.next.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.setToVerified = this.setToVerified.bind(this);
        this.setToLimitExceeded = this.setToLimitExceeded.bind(this);
        this.setStepOneBody = this.setStepOneBody.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);

        this.props.getUser();

        this.handleResize();

        Auth.currentAuthenticatedUser().then(user => {
            if (user.attributes && user.attributes.phone_number_verified) {
                this.setState({ verified: true })
            }
        }).catch(err => {
            Sentry.captureEvent(new Error('Auth.currentAuthenticatedUser errror'))
            Sentry.captureException(err)
        })
    }

    componentDidUpdate() {
        const { user } = this.props;
        if (user && !this.state.user) {
            this.setState({ user, customer: user.customer });
            try {
                const { cadenceWeeks } = user.manifest;
                const { cadenceMonths } = getCadenceMonths(cadenceWeeks);
                this.setState({ cadenceMonths });
            } catch (err) {
                console.error(err);
                this.setState({ cadenceMonths: { title: 'Monthly', int: 1 } })
                Sentry.captureException(err);
            }
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const { showCadenceModal } = this.state;

        if (e.keyCode && e.keyCode === 27) {
            if (showCadenceModal)
                this.setState({ showCadenceModal: false })
        }
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { completedOrder, customer, productGroupsMap, newCoupon, sdCredit } = signUpData;
            this.setTax(completedOrder);
            this.sendGATransaction(completedOrder.order, customer, productGroupsMap, newCoupon, sdCredit);
        } catch (err) {
            console.error(err);
        }
    }

    next() {
        const { verified } = this.state;
        if (prod)
            ReactGA.event({ category: 'DashboardView', action: 'After-Interview' })

        this.props.history.push('/dashboard/orders', { dashboardData: { verified } });
    }

    setTax(completedOrder) {
        try {
            const { order, expectedDeliveryDate } = completedOrder;
            const { taxes, subtotalCents } = order;
            let taxTotal = 0;
            taxes.forEach(tax => {
                taxTotal += tax.amountCents;
            })
            this.setState({ expectedDeliveryDate, taxTotal })
            if (prod) {
                window.fbq('track', 'Purchase', { value: (subtotalCents/100).toFixed(2), currency: 'USD' });
                window.rdt('track', 'Purchase');
            }
        } catch (err) {
            this.setState({ orderError: err })
            console.error(err);
            Sentry.captureException(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    handleResize(e) {
        const { isMobile } = this.state;
        if (window.innerWidth < 992 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 991 && isMobile) {
            this.setState({ isMobile: false })
        }
    }

    setToVerified() {
        this.setState({ verified: true })
    }

    setToLimitExceeded() {
        this.setState({ limitExceeded: true })
    }

    sendGATransaction(order, customer, productGroupsMap, coupon) {
        try {
            const { orderId } = order;
            const items = [];
            const { gtag } = window;

            // enhanced ecomm
            let total = order.totalCents / 100.0;
            let tax = 0;
            
            order.taxes.forEach(t => {
              tax += t.amountCents / 100.0
            })

            order.productGroupEntries.forEach(productGroup => {
                const { groupName, articleCount } = productGroup;
                const { productType, pricePerArticle, brandName, displayName, description } = productGroupsMap[groupName];
                ReactGA.plugin.execute('ec', 'addProduct', {
                    id: groupName,
                    name: groupName,
                    price: pricePerArticle / 100,
                    brand: brandName,
                    variant: displayName,
                    quantity: articleCount,
                    category: productType,
                    creative: description,
                });
            })
            
            ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
                id: orderId,
                affiliation: customer.campaignCode,
                items, 
                coupon: coupon ? coupon.code : "",
                shipping: 0.0,
                tax,
                revenue: total
            });
            
            // Google ad-words conversion tracking
            if (gtag) {
              gtag('event', 'conversion', {
                  'send_to': keys.gaAdConversionTrackingId,
                  'value': total,
                  'currency': 'USD',
                  'transaction_id': orderId
              });     
            }
            
            // send goal event 
            ReactGA.event({
                category: 'OrderStatus',
                action: 'OrderComplete',
                label: 'AllQuestions'
            }) 
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    renderDYK(isMobile) {
        if (!!isMobile === !!this.state.isMobile) {
            const points = [
                (
                    <Fragment>
                        <b>You can text us at 30389</b> with messages like “When is my next delivery?” or “I need less toilet paper” and we’ll update your subscription.
                    </Fragment>
                ),
                (
                    <Fragment>
                        <b>Running low?</b> Text us so we can get you an emergency order ahead of your next delivery. No need to run out or run to the store; just text us!
                    </Fragment>
                ),
                (
                    <Fragment>
                        <b>We predict how much you’ll need every month.</b> If you would like to make adjustments, you can easily change your product selection and quantity.
                    </Fragment>
                )
            ]

            let i = 0;
            const rows = points.map(point => {
                const row = (
                    <div key={point.props.children[1]} className="did-you-know-row">
                        <img alt="Did you know?" src={images[`dyk_${i}`]} className="did-you-know-img" />
                        <div className="did-you-know-copy">
                            {point}
                        </div>
                    </div>
                )
                i++
                return row;
            })


            return (
                <div id="oc-did-you-know">
                    <div id="did-you-know-header">Did you know?</div>
                    {rows}
                </div>
            )
        }
    }

    renderDesktopOrderSummary() {
        const {
            isMobile,
            manifestBundles,
            taxTotal,
            coupon,
            employer,
            newCoupon,
            sdCredit,
            isEmployeePerk,
            refCust
        } = this.state;
        if (!isMobile) {
            return (
                <OrderSummary
                    manifestBundles={manifestBundles}
                    tax={taxTotal}
                    coupon={coupon}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    employer={employer}
                    isEmployeePerk={isEmployeePerk}
                    subRows={this.renderDYK()}
                    showReferral={refCust ? true : false}
                />
            )
        }
    }

    renderMobileOrderSummary() {
        const {
            isMobile,
            manifestBundles,
            taxTotal,
            coupon,
            isEmployeePerk,
            employer,
            newCoupon,
            sdCredit
        } = this.state;
        if (isMobile) {
            return (
                <Body>
                    <div className="checkout-main">
                        <OrderSummary
                            manifestBundles={manifestBundles}
                            orderComplete={true}
                            tax={taxTotal}
                            employer={employer}
                            coupon={coupon}
                            isEmployeePerk={isEmployeePerk}
                            newCoupon={newCoupon}
                            sdCredit={sdCredit}
                        />
                        {this.renderDYK(true)}
                    </div>
                </Body>
            )
        }
    }

    renderHeader() {
        const { expectedDeliveryDate } = this.state;
        if (expectedDeliveryDate) {
            return (
                <div className="order-complete-header">
                    Success! Your first delivery with your Essentials is arriving on {moment(expectedDeliveryDate).format("MMMM Do")}
                </div>
            )
        }
    }

    setStepOneBody() {
        const { verified, customer, limitExceeded } = this.state;
        let copy, button;
        if (verified) {
            copy = "Thanks, you’ve successfully verified your phone number!";
            button = <div className="text-center"><ContinueButton text="Go to your dashboard" next={this.next} fixed={true} noMargin={true} /></div>
        } else if (limitExceeded) {
            copy = "Limit reached for phone verification. Please try again another time."
            button = <div className="text-center"><ContinueButton text="Go to your dashboard" next={this.next} fixed={true} noMargin={true} /></div>
        } else {
            copy = <span>Thanks for signing up. You should have received a text from us <b>(30389)</b> with a numeric code to verify your account.</span>
            button = <PhoneVerification limitExceeded={this.setToLimitExceeded} email={customer.email} phone={customer.phone} setToVerified={this.setToVerified} />
        }
        return { copy, button }
    }

    renderCTA() {
        const { customer } = this.state;
        if (customer) {
            const { copy, button } = this.setStepOneBody()
            return (
                <Fragment>
                    <div className="whats-next-step">
                        <div className="whats-next-section-container">
                            <div className="whats-next-number-circle">
                                <div className="whats-next-number">1</div>
                            </div>
                        </div>
                        <div className="whats-next-section-container">
                            <div className="whats-next-copy">
                                {copy}
                            </div>
                        </div>
                    </div>
                    {button}
                </Fragment>
            )
        }
    }

    renderWhatsNextBullets() {
        const { expectedDeliveryDate, cadenceMonths } = this.state;
        
        var whatsNextDelivery = "You will receive your Supply Drop each month.";
        if (cadenceMonths.int > 1) {
          whatsNextDelivery = `Starting with your 2nd delivery, you will receive your Supply Drop ${cadenceMonths.title} so each delivery will have ${cadenceMonths.int} months worth of supplies.`
        }

        return (
            <Fragment>
                {this.renderCTA()}
                <div className="whats-next-step">
                    <div className="whats-next-section-container">
                        <div className="whats-next-number-circle">
                            <div className="whats-next-number">2</div>
                        </div>
                    </div>
                    <div className="whats-next-section-container">
                        <div className="whats-next-copy">
                            You'll receive your first Supply Drop on {moment(expectedDeliveryDate).format('MMMM Do')}.
                        </div>
                    </div>
                </div>
                <div className="whats-next-step">
                    <div className="whats-next-section-container">
                        <div className="whats-next-number-circle">
                            <div className="whats-next-number">3</div>
                        </div>
                    </div>
                    <div className="whats-next-section-container">
                      <div className="whats-next-copy">
                        {whatsNextDelivery}
                      </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    renderWhatsNext() {
        const { expectedDeliveryDate, cadenceMonths } = this.state;
        if (cadenceMonths && expectedDeliveryDate) {
            return (
                <Fragment>
                    <div className="order-complete-header">What's next:</div>
                    {this.renderWhatsNextBullets()}
                </Fragment>
            )
        } else {
            return (
                <div id="order-complete-loading-container">
                    <Loading />
                </div>
            )
        }
    }

    renderMain() {
        return (
            <Fragment>
                <div className="text-center">
                    <img alt="Happy house" id="oc-img" src={images.oc_house} />
                </div>
                {this.renderHeader()}
                {this.renderCadenceAlert()}
                {this.renderWhatsNext()}
            </Fragment>
        )
    }

    renderConfetti() {
        return (
            <Confetti
                height={400}
                recycle={false}
            />
        )
    }

    renderCadenceAlert() {
        const { cadenceMonths } = this.state;
        if (cadenceMonths)
            return <CadenceAlert months={cadenceMonths.int} title={cadenceMonths.title} onclick={() => this.setState({ showCadenceModal: true })} />
    }

    renderCadenceModal() {
        const { cadenceMonths, showCadenceModal } = this.state;
        if (showCadenceModal && cadenceMonths) {
            return (
                <CadenceModal cadenceMonths={cadenceMonths.int} close={() => this.setState({ showCadenceModal: false })} />
            )
        }
    }

    render() {
        return (
            <Fragment>
                <MetaTags title="Supply Drop - Thank You!" />
                <Nav pathname="/dashboard/orders" />
                {this.renderCadenceModal()}
                <div className="has-fixed-continue">
                    <Body>
                        <div className="checkout-main">
                            <div className="checkout-container">
                                {this.renderConfetti()}
                                {this.renderMain()}
                            </div>
                        </div>
                        {this.renderDesktopOrderSummary()}
                    </Body>
                    {this.renderMobileOrderSummary()}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError } = state.user;
    return { user, getUserError }
}

export default connect(mapStateToProps, {
    getUser
})(OrderComplete);
