export const 
    bathrooms = require('./bathrooms.svg'),
    cat = require('./cat.svg'),
    compost_trash_icon = require('./icon-compost.svg'),
    cookingFrequency = require('./chefs.svg'),
    dishwashingFrequency = require('./dishwashingFrequency.svg'),
    dishwashingProducts = require('./sparkle.svg'),
    dog = require('./dog.svg'),
    female_adult = require('./female_adult.svg'),
    female_child = require('./female_child.svg'),
    house = require('./house.svg'),
    household = require('./household.svg'),
    housekeeping = require('./broom.svg'),
    kitchen_trash_icon = require('./icon-kitchen-trash.svg'),
    laundryFrequency = require('./laundryFrequency.svg'),
    male_adult = require('./male_adult.svg'),
    male_child = require('./male_child.svg'),
    outdoor_trash_icon = require('./icon-outdoor-trash.svg'),
    recycling_trash_icon = require('./icon-recycle.svg'),
    small_trash_icon = require('./icon-small-trash.svg')