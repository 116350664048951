import React from 'react';
import {
    LandingTwoUpSection,
} from '../../Common'
import {
    LandingHeadline as Headline,
    HomeBlockCTA,
    HeroTextContainer
} from '../../../Style';
import { SDPartnerLogos, CouponAlert } from '..'

export const Hero = ({
    coupon,
    sdCredit,
    ctaCopy,
    handleCTAClick,
    header,
    headerStyles,
    heroImg,
    hideCouponAlert,
    imgContainerStyles,
    isEmployeePerk,
    isMobile,
    isRaised,
    isSignedIn,
    isTablet,
    positionedImages,
    raisedSectionStyles,
    subheader,
    utm_campaign,
    utm_content,
    style,
    additionalOverflowStyles
}) => {
    function renderLogo() {
        if (!isMobile && utm_campaign) {
            if(utm_campaign.includes('fetch')) {
                return <SDPartnerLogos partner="fetch" />
            } else if(utm_campaign.includes('localeur')) {
                return <SDPartnerLogos style={{ marginBottom: 40 }} partner="localeur" />
            }
        }
    }

    function renderHeroCopy() {
        return (
            <HeroTextContainer>
                <Headline style={headerStyles}>{header}</Headline>
                {renderCouponNotif()}
            </HeroTextContainer>
        )
    }

    function renderMain() {
        return (
            <div>
                {renderLogo()}
                {renderHeroCopy()}
            </div>
        )
    }

    function renderCTA() {
        const buttonCopy = isSignedIn ? "My Dashboard" : ctaCopy;
        if(handleCTAClick) {
            return (
                <div className="text-center space-bottom">
                    <HomeBlockCTA onClick={handleCTAClick} id={isSignedIn ? "home-block-cta-signed-in" : "home-block-cta"}>{buttonCopy}</HomeBlockCTA>
                </div>
            )
        }
    }

    function renderCouponNotif() {
        if (!isSignedIn && (coupon || sdCredit) && !hideCouponAlert) {
            return (
                <CouponAlert
                    newCoupon={coupon}
                    sdCredit={sdCredit}
                    utm_campaign={utm_campaign}
                    utm_content={utm_content}
                    isEmployeePerk={isEmployeePerk}
                />
            )
        }
    }

    let overflowStyles = isMobile ? {
        marginLeft: -20,
        marginRight: -20,
        maxWidth: "calc(100% + 40px)",
        maxHeight: 420,
    } : isTablet ? { maxHeight: 420 } : {};

    if(additionalOverflowStyles) {
        overflowStyles = Object.assign(overflowStyles, additionalOverflowStyles)
    }

    return (
        <LandingTwoUpSection
            body={subheader}
            cta={renderCTA()}
            headerSection={renderMain()}
            isHero={true}
            mainImg={heroImg}
            positionedImages={positionedImages}
            style={{
                paddingTop: 0,
                paddingBottom: 0,
                ...style
            }}
            isRaised={isRaised}
            raisedSectionStyles={raisedSectionStyles}
            imgContainerStyles={{
                padding: 0,
                marginLeft: -20,
                ...imgContainerStyles
            }}
            overflowStyles={overflowStyles}
        />
    )
}