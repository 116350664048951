export function customerReferralCampaign(id) {
   return {
        campaignLanding: '/landing/customer_referral',
        utm_campaign: 'customer_referring_' + id,
        utm_content: 'customer_referral',
        utm_source: 'customer_referral',
        utm_medium: 'share_url',
        refCust: id,
   }
}

function employerCampaign(campaign) {
    return {
        campaignLanding: `/employee_perk/${campaign}`,
        utm_campaign: `employer_${campaign}`,
        utm_source: 'supply_drop',
        utm_content: campaign,
        utm_medium: 'short_url',
        employer: `${campaign}`,
    }
}

function fetchCampaign(shortName, longName) {
    return {
        campaignLanding: `/landing/${longName}`,
        utm_campaign: `fetch_atx_${shortName}`,
        utm_source: 'fetch',
        utm_content: shortName,
        utm_medium: 'short_url',
        rc: 'fetch_atx_20'
    }
}

export function getRefCampaign(id) {
    if(id === "waitlist%C2%A0") id = "waitlist";
    return {
        campaignLanding: '/landing/friends_and_family',
        utm_campaign: 'sd_employee_' + id,
        utm_content: 'employee_referral',
        utm_source: 'supplydrop',
        utm_medium: 'share_url',
        rc: 'friends_and_family_20',
    }
}

export function getCouponCampaign(id) {
    return {
        campaignLanding: '/landing/coupon_'+id,
        utm_campaign: 'coupon_' + id,
        utm_content: 'coupon',
        utm_source: 'supplydrop',
        utm_medium: 'short_url',
        rc: id,
    }
}

export const campaigns = {
    sdn: fetchCampaign('sdn', 'standarddomain'),
    wpk: fetchCampaign('wpk', 'waterspark'),
    westlake: {
        campaignLanding: '/landing/westlake',
        utm_campaign: 'sd_atx_westlake',
        utm_content: 'short_url',
        utm_source: 'supplydrop',
        utm_medium: 'mailer',
        rc: 'westlake_atx_20',
    },
    westlake_qr: {
        campaignLanding: '/landing/westlake_qr',
        utm_campaign: 'sd_atx_westlake',
        utm_content: 'qr',
        utm_source: 'supplydrop',
        utm_medium: 'mailer',
        rc: 'westlake_atx_20',
    },
    ojo: {
        ...employerCampaign('ojo'),
        displayName: "OJO Labs",
        domain: "ojolabs.com"
    },
    sde: {
        ...employerCampaign('sde'),
        displayName: "Supply Drop",
        domain: "supplydrop.com"
    },
    'sd-influencer': {
        ...employerCampaign('sd-influencer'),
        displayName: "Supply Drop Influencer"
    },
    'gables-terrace': getCouponCampaign('gables-terrace-oct'),
    'gables-terrace-oct': getCouponCampaign('gables-terrace-oct'),
    'the-w': getCouponCampaign('the-w-oct'),
    'the-w-oct': getCouponCampaign('the-w-oct'),
    'bowie': getCouponCampaign('bowie-oct'),
    'bowie-oct': getCouponCampaign('bowie-oct'),
    'northshore': getCouponCampaign('northshore-oct'),
    'northshore-oct': getCouponCampaign('northshore-oct'),
    'gables-republic-square': getCouponCampaign('gables-republic-square-oct'),
    'gables-republic-square-oct': getCouponCampaign('gables-republic-square-oct'),
    'belterra': getCouponCampaign('belterra-oct'),
    'belterra-oct': getCouponCampaign('belterra-oct'),
    'grimes-property-group': getCouponCampaign('grimes-property-group-50'),
    'grimes-property-group-oct': getCouponCampaign('grimes-property-group-50'),
    'ashton': getCouponCampaign('ashton-oct'),
    'ashton-oct': getCouponCampaign('ashton-oct'),
    'whitley': getCouponCampaign('whitley-oct'),
    'whitley-oct': getCouponCampaign('whitley-oct'),
    'lenox-boardwalk': getCouponCampaign('lenox-boardwalk-oct'),
    'lenox-boardwalk-oct': getCouponCampaign('lenox-boardwalk-oct'),
    'independent': getCouponCampaign('independent-oct'),
    'independent-oct': getCouponCampaign('independent-oct'),
    'friends': getCouponCampaign('friends-50'),
    'austonian': getCouponCampaign('austonian-oct'),
    'austonian-oct': getCouponCampaign('austonian-oct'),
    'four-seasons': getCouponCampaign('four-seasons-oct'),
    'four-seasons-oct': getCouponCampaign('four-seasons-oct'),
    'towers-townlake': getCouponCampaign('towers-townlake-oct'),
    'towers-townlake-oct': getCouponCampaign('towers-townlake-oct'),
    'walsh': getCouponCampaign('walsh-oct'),
    'walsh-oct': getCouponCampaign('walsh-oct'),
    'westerly-360': getCouponCampaign('westerly-360-oct'),
    'westerly-360-oct': getCouponCampaign('westerly-360-oct'),
    'windsor-lake': getCouponCampaign('windsor-lake-oct'),
    'windsor-lake-oct': getCouponCampaign('windsor-lake-oct'),
    'monarch-oct': getCouponCampaign('monarch-oct'),
    'monarch': getCouponCampaign('monarch-oct'),
    'gift-bag-oct': getCouponCampaign('gift-bag-oct'),
    'gift-bag': getCouponCampaign('gift-bag-oct'),
    'brookdale-westlake-hills-oct': getCouponCampaign('brookdale-westlake-hills-oct'),
    'brookdale-westlake-hills': getCouponCampaign('brookdale-westlake-hills-oct'),
    'hunters-chase-oct': getCouponCampaign('hunters-chase-oct'),
    'hunters-chase': getCouponCampaign('hunters-chase-oct'),
    'brookdale-gaines-ranch-oct': getCouponCampaign('brookdale-gaines-ranch-oct'),
    'brookdale-gaines-ranch': getCouponCampaign('brookdale-gaines-ranch-oct'),
    'muse-oct': getCouponCampaign('muse-oct'),
    'muse': getCouponCampaign('muse-oct'),
    '5th-street-commons-oct': getCouponCampaign('5th-street-commons-oct'),
    '5th-street-commons': getCouponCampaign('5th-street-commons-oct'),
    'edison-oct': getCouponCampaign('edison-oct'),
    'edison': getCouponCampaign('edison-oct'),
    'eleven-oct': getCouponCampaign('eleven-oct'),
    'eleven': getCouponCampaign('eleven-oct'),
    '3500-westlake-oct': getCouponCampaign('3500-westlake-oct'),
    '3500-westlake': getCouponCampaign('3500-westlake-oct'),
    'timbercreek-oct': getCouponCampaign('timbercreek-oct'),
    'timbercreek': getCouponCampaign('timbercreek-oct'),
    'windsor-south-lamar-oct': getCouponCampaign('gift-bag-oct'),
    'windsor-south-lamar': getCouponCampaign('windsor-south-lamar-oct'),
    'five-two-oct': getCouponCampaign('five-two-oct'),
    'five-two': getCouponCampaign('five-two-oct'),
    'round-top-oct': getCouponCampaign('round-top-oct'),
    'round-top': getCouponCampaign('round-top-oct'),
    'e6': getCouponCampaign('e6-nov'),
    'e6-nov': getCouponCampaign('e6-nov'),
    'localeur': getCouponCampaign('localeur'),
    'tx': getRefCampaign('tx'),
}
