import {
    GET_PRODUCT_GROUPS_SUCCESS,
    GET_PRODUCT_GROUPS_FAIL,
    GETTING_PRODUCT_GROUPS
} from '../actions/types';

const INITIAL_STATE = {
    productGroupsError: undefined,
    productGroups: undefined,
    gettingProductGroups: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GETTING_PRODUCT_GROUPS:
            return { ...state, productGroupsError: undefined, productGroups: undefined, gettingProductGroups: true }
        case GET_PRODUCT_GROUPS_SUCCESS:
            return { ...state, productGroupsError: undefined, productGroups: action.payload.productGroups, gettingProductGroups: false };
        case GET_PRODUCT_GROUPS_FAIL:
            return { ...state, productGroups: undefined, productGroupsError: action.error, gettingProductGroups: false };
        default:
            return state;
    }
};