import * as Sentry from '@sentry/browser';
import moment from 'moment';

export const getDeliveryDate = (shipment) => {
    try {
        let cutoff, delivery;
        shipment.orderWindows.sort((a,b) => {
            if(moment(a.createdEndInclusive).isBefore(b.createdEndInclusive)) return -1
            if(moment(b.createdEndInclusive).isBefore(a.createdEndInclusive)) return 1
            return 0
        })
    
        for(let i = 0; i < shipment.orderWindows.length; i++) {
            const {createdEndInclusive, deliveryDate} = shipment.orderWindows[i];
            if(moment.utc(createdEndInclusive).isAfter(moment.utc())) {
                cutoff = moment.utc(createdEndInclusive);
                delivery = deliveryDate
                break;
            }
        }
    
        return {cutoff, deliveryDate: delivery}
    } catch(error) {
        Sentry.captureException(error);
        return {error}
    }
}