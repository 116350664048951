import React, {Component, Fragment} from 'react';
import {Auth} from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
    ContinueButton,
    FormError,
    CloseX
} from '../';
import { 
    reverseFormatPhone,
    updateCustomer,
    formatPhone,
    clearCustomerState
} from '../../../actions';
import {connect} from 'react-redux';

class PhoneVerification extends Component {
    constructor(props) {
        super(props);

        this.state = {}

        this.requestCode = this.requestCode.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.verify = this.verify.bind(this);
        this.setDisplayNumber = this.setDisplayNumber.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
        this.toggleChangingNumber = this.toggleChangingNumber.bind(this);
    }

    componentDidMount() {
        const {phone} = this.props;
        this.requestCode();
        this.setDisplayNumber(phone);
        // Ensure sms verification input has focus as soon as modal opens so user can quickly autofill/enter in code
        this.otpInput.focus();    
    }

    componentDidUpdate() {
        const {sentNewCode, clearingNewCode, isChangingPhone, phone} = this.state;
        const {updateCustomerSuccess} = this.props;
        if(updateCustomerSuccess && isChangingPhone) {
            this.setDisplayNumber(phone);
            this.requestCode()
            this.setState({isChangingPhone: false})
            this.props.clearCustomerState();
        }
        if(sentNewCode && !clearingNewCode) {
            this.setState({clearingNewCode: true})
            setTimeout(() => {
                this.setState({sentNewCode: false, clearingNewCode: false})
            }, 2000)
        }
    }

    handleKeydown(e) {
        const {isChangingPhone} = this.state;
        if (e.key && e.key.toLowerCase() === 'enter') {
            if(isChangingPhone)
                this.updatePhoneNumber()
            else
                this.verify()
        }
    }

    setDisplayNumber(phone) {
        const displayNumber = reverseFormatPhone(phone);
        this.setState({displayNumber})
    }

    toggleChangingNumber() {
        const {isChangingPhone} = this.state;
        this.setState({isChangingPhone: !isChangingPhone, inputError: false, formattedPhone: "", otp: ""})
    }

    handleInputChange(name, value) {
        this.setState({[name]: value, inputError: false})
    }

    verify() {
        const {otp} = this.state;
        this.setState({ verifying: true });
        Auth.verifyCurrentUserAttributeSubmit('phone_number', otp)
            .then(() => {
                this.props.setToVerified()
            }).catch(e => {
                console.error(e)
                if(e.code === "CodeMismatchException")
                    this.setState({inputError: e.message})
                else {
                    Sentry.captureException(e);
                    this.setState({inputError: "Sorry, we're unable to verify your number at this time"})
                }
            });
    }

    updatePhoneNumber() {
        const {formattedPhone} = this.state;
        const phone = formatPhone(formattedPhone);
        const isPhoneValid = isValidPhoneNumber(phone);
        if(isPhoneValid) {
            this.setState({phone})
            this.props.updateCustomer({phone})
        } else {
            this.setState({inputError: "Please provide a valid phone number"})
        }
    }

    requestCode() {
        Auth.verifyCurrentUserAttribute('phone_number')
            .then(() => {
                this.setState({sentNewCode: true})
            }).catch(e => {
                if(e.code === "LimitExceededException") {
                    this.props.limitExceeded();
                } else {
                    Sentry.captureException('Auth.verifyCurrentUserAttribute error');
                    Sentry.captureException(e);
                }
                console.error('failed with error', e);
            });
    }

    renderNewCodeLink() {
        const {sentNewCode, isChangingPhone} = this.state;
        if(!isChangingPhone) {
            return (
                <div className="sd-modal-footer">
                    <div className="flex-col-left no-margin full-color">
                        Didn't receive a confirmation code?<br/>
                        <div className="hyperlink" onClick={this.requestCode}>Request new code.</div>
                        <div className={sentNewCode ? "green" : "hide"}> A new code has been sent!</div>
                    </div>
                </div>
            )
        }
    }
    
    renderChangeNumberLink() {
        const {displayNumber, isChangingPhone} = this.state;
        if(displayNumber && !isChangingPhone) {
            return (
                <div className="sd-modal-footer">
                    <div className="flex-col-left no-margin full-color">
                        Is {displayNumber} your number?<br/>
                        <div className="hyperlink" onClick={this.toggleChangingNumber}>Update your phone number.</div>
                    </div>
                </div>
            )
        }
    }

    renderInput() {
        const {otp, inputError, isChangingPhone} = this.state;
        if(isChangingPhone) {
            return (
                <Fragment>
                    <label className="modal-input-label">Change Phone Number</label>
                    <input
                        autoComplete="home tel-national"
                        name="phone"
                        type="tel"
                        className={`phone-verification-input modal-input${!inputError ? "" : " invalid"}`}
                        placeholder="(512) 555-1234"
                        onKeyDown={this.handleKeydown}
                        onChange={(e) => this.handleInputChange('formattedPhone', e.target.value)}
                    />
                    {this.renderError()}
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <label className="modal-input-label">Enter Code</label>
                    <input
                        id="sms-otp"
                        ref={(input) => { this.otpInput = input; }} 
                        name="otp"
                        className={`phone-verification-input modal-input${!inputError ? "" : " invalid"}`}
                        value={otp}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        autoComplete="one-time-code"
                        onChange={(e) => this.handleInputChange('otp', e.target.value)}
                        placeholder="Code from text message"
                        onKeyDown={this.handleKeydown}
                    />
                    {this.renderError()}
                </Fragment>
            )
        }
    }

    renderError() {
        const {inputError} = this.state;
        if(inputError) {
            return (
                <FormError copy={inputError} />
            )
        }
    }

    renderMain() {
        const {isModal} = this.props;
        if(isModal) {
            return (
                <div className="modal-container transparent">
                    {this.renderBody()}
                </div>
            )
        } else {
            return this.renderBody();
        }
    }

    renderButton() {
        const {isChangingPhone} = this.state;
        const {updatingCustomer} = this.props;
        if(isChangingPhone) {
            return (
                <div className="phone-verification-btn-container">
                    <ContinueButton next={this.updatePhoneNumber} text="Update mobile number" noMargin={true} fixed={true} loading={updatingCustomer} />
                    <div className="text-center">
                        <button className="dashboard-btn dashboard-cancel-link" style={{color: "#465967", fontWeight: "normal"}} onClick={this.toggleChangingNumber}>Cancel</button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="phone-verification-btn-container">
                    <ContinueButton next={this.verify} text="Verify mobile number" noMargin={true} fixed={true}/>
                </div>
            )
        }
    }

    renderCloseBtn() {
        const {isModal, close} = this.props;
        if(isModal) {
            return <CloseX close={close} />
        }
    }

    renderBody() {
        return (
            <div className="phone-verification-container">
                {this.renderCloseBtn()}
                {this.renderInput()}
                {this.renderButton()}
                {this.renderNewCodeLink()}
                {this.renderChangeNumberLink()}     
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                {this.renderMain()}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { updateCustomerSuccess, updateCustomerError, updatingCustomer} = state.user;
    return { updateCustomerSuccess, updateCustomerError, updatingCustomer}
}

export default connect(mapStateToProps, {
    updateCustomer,
    clearCustomerState
})(PhoneVerification)
