import React from 'react';
import {
    FAQBody as Body,
    LandingFAQTitle as Title,
    FAQContainer as Container
} from '../../../Style';
import { formatStringForId } from '../../../actions';

export const FAQ = ({ title, bodyHtml }) => {
    if(title && typeof(title) === "string" && bodyHtml) {
        let id = "";
        if(title.toLowerCase() === "how can i contact you?")
            id = "faqs-contact-us";
        else
            id = "faqs-" + formatStringForId(title)
    
        return (
            <Container id={id}>
                <Title>{title}</Title>
                <Body className="faq-body" dangerouslySetInnerHTML={{ __html: bodyHtml }}></Body>
            </Container>
        )
    }
}