import React from 'react';
import * as images from '../../../assets';
import {
    ValuePropsContainer as Container,
    ValueProp,
    ValuePropsImg as Img,
    ValuePropsTitle as Title,
    ValuePropsSubtext as Subtext,
    ValuePropImgWrapper as ImgWrapper,
} from '../../../Style';

export const ValueProps = ({ isCheckout, isLanding }) => {
    const values = [
        {
            title: "Best Prices Guarantee",
            subtext: "Compare us to your local grocery and online stores!",
            img: images.vp_price
        },
        {
            title: "Personalized Deliveries",
            subtext: "Item quantities are tuned to just what you need.",
            img: images.vp_amount
        },
        {
            title: "Easy Adjustments",
            subtext: "Skip a delivery, change your items, or cancel anytime.",
            img: images.vp_skip

        }
    ]

    const valuesMap = values.map(value => {
        const { title, img, subtext } = value;
        return (
            <ValueProp key={title} isCheckout={isCheckout} isLanding={isLanding}>
                <ImgWrapper><Img src={img} alt={title} isLanding={isLanding} /></ImgWrapper>
                <div>
                    <Title isLanding={isLanding}>{title}</Title>
                    <Subtext isLanding={isLanding}>{subtext}</Subtext>
                </div>
            </ValueProp>
        )
    })

    return (
        <Container isCheckout={isCheckout} isLanding={isLanding}>
            {valuesMap}
        </Container>
    )
}