import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import {
    CardElement,
    injectStripe
} from 'react-stripe-elements';

class StripeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.createSource = this.createSource.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleFocus() {
        this.props.setParentState({ isStripeFocused: true })
    }

    async createSource() {
        this.props.setParentState({ creatingSource: true })
        try {
            const stripeRes = await this.props.stripe.createSource({
                type: 'card'
            })
            const { source, error } = stripeRes;
            if (source) {
                this.props.setParentState({ source, creatingSource: false });
            } else if (error) {
                this.setState({ error: error.message });
                this.props.setParentState({ error: error.message, creatingSource: false });
            }
        } catch (error) {
            console.error(error);
            Sentry.captureException(error)
        }
    }

    render() {
        return (
            <div className="modal-input-container full">
                <label className="modal-label">Credit Card</label>
                <CardElement className="stripe-input" onBlur={this.createSource} onFocus={this.handleFocus} />
            </div>
        )
    }
}

export default injectStripe(StripeForm)
