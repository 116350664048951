import React from 'react';
import { HouseholdButton } from '../Buttons';
import { HouseholdGroupingContainer as Container } from '../../../Style';

const household = [
    {
        id: 0,
        gender: "Male",
        type: "Adult",
        age: 45
    },
    {
        id: 1,
        gender: "Female",
        type: "Adult",
        age: 44
    },
    {
        id: 2,
        gender: "Female",
        type: "Child",
        age: 15
    },
    {
        id: 3,
        gender: "Male",
        type: "Child",
        age: 13
    },
    {
        id: 4,
        isDog: true
    },
]

export const HouseholdGrouping = () => {
    return (
        <Container>
            {household.map(member => {
                const { age, type, gender, id, isDog } = member;
                return (
                    <HouseholdButton
                        key={id}
                        isDog={isDog}
                        disabled={true}
                        gender={gender}
                        age={age}
                        type={type}
                    />
                )
            })}
        </Container>
    )
}