import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import {Col, Row, Container, Alert} from 'react-bootstrap';
import * as images from '../../../assets';
import BundleRow from '../Connected/BundleRow';

export class AddProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bundles: {
                everyday: [
                    "ToiletPaper",
                    "PaperTowels",
                    "TrashBagsKitchen",
                    "TrashBagsOutdoor",
                    "TrashBagsRecycling",
                    "TrashBagsSmall",
                    "FacialTissue",
                    "HandWipes",
                    "HandSanitizer"
                ],
                kitchen: [
                    "HandSoap",
                    "SurfaceCleaner",
                    "DishSoap",
                    "DishwasherDetergent",
                ],
                laundry: [
                    "Bleach",
                    "DryerSheets",
                    "LaundryDetergent"
                ]
            }
        };
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.setSubtotal = this.setSubtotal.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
    }

    componentDidMount() {
        const {productTypes, productGroupsMap, interview, subtotal} = this.props;
        this.setState({productTypes, productGroupsMap, interview, subtotal});
        this.setPercentages(productTypes);
    }

    componentDidUpdate() {
        const {newOptions, productGroupsMap, productTypes} = this.props;
        if(newOptions) {
            this.props.clearNewOptions();
            this.setState({productGroupsMap, productTypes, newOptions})
        }
    }

    setSubtotal(products, productGroupsMap) {
        try {
            let subtotal = 0;
            Object.values(products).forEach(type => {
                if(Object.values(type.edited).length) {
                    const {groupName} = type.edited;
                    const product = productGroupsMap[groupName];
                    const {articles, pricePerArticle} = product;
                    subtotal += (articles * pricePerArticle / 100);
                }
            })
            this.setState({subtotal});
            return subtotal;
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    setPercentages(productTypes) {
        try{
            const {bundles} = this.state;
            if(bundles) {
                const bundleNumerators = {};
                const preferredProducts = {};
                Object.entries(bundles).forEach(entry => {
                    const bundle = entry[0]
                    bundleNumerators[bundle] = 0;
                })
                Object.entries(productTypes).forEach(entry => {
                    const type = entry[0];
                    const products = entry[1];
                    const {edited, preferred} = products;
                    if(Object.keys(edited).length) {
                        for(let i = 0; i < Object.entries(bundles).length; i++) {
                            const bundle = Object.keys(bundles)[i];
                            const types = Object.values(bundles)[i];
                            if(types.indexOf(type) > -1) {
                                bundleNumerators[bundle]++
                                break;
                            }
                        }
                    } else {
                        preferredProducts[type] = preferred;
                    }
                })
                this.setState({bundleNumerators, preferredProducts});
            }
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    increment(selectedProduct) {
        const {productTypes, productGroupsMap} = this.state;
        try {
            const {possibleArticleCounts} = selectedProduct;
            const index = possibleArticleCounts.indexOf(selectedProduct.articles);
            const proposedArticles = selectedProduct.proposedArticles ? selectedProduct.proposedArticles : possibleArticleCounts[0];
            const max = selectedProduct.productType.toLowerCase() === "toiletpaper" || selectedProduct.productType.toLowerCase() === "papertowels" ? 2 : 1.5;
            if(possibleArticleCounts.length <= index + 1 || possibleArticleCounts[index + 1] > (proposedArticles * max)) {
                const quantityLimitReached = `${selectedProduct.brandName} ${selectedProduct.displayName}`
                this.setState({quantityLimitReached});
                setTimeout(() => {
                    this.setState({quantityLimitReached: false});
                }, 3000);
            } else {
                selectedProduct.articles = possibleArticleCounts[index + 1];
                productTypes[selectedProduct.productType].edited = selectedProduct;
                const subtotal = this.setSubtotal(productTypes, productGroupsMap);
                if(subtotal > 200) {
                    selectedProduct.articles = possibleArticleCounts[index];
                    productTypes[selectedProduct.productType].edited  = selectedProduct;
                }
                this.setState({productGroupsMap, newChanges: true, priceLimitReached: subtotal > 200, quantityLimitReached: false});
                this.setSubtotal(productTypes, productGroupsMap)
                
                this.setState({productTypes, edits: true});
            }
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    decrement(selectedProduct) {
        const {productTypes} = this.state;
        try {
            const {possibleArticleCounts} = selectedProduct;
            const index = possibleArticleCounts.indexOf(selectedProduct.articles);

            if(index > 0) selectedProduct.articles = possibleArticleCounts[index - 1];
            else selectedProduct.articles = 0;

            productTypes[selectedProduct.productType].edited = selectedProduct;
            this.setState({productTypes, edits: true, priceLimitReached: false, quantityLimitReached: false});
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    saveChanges() {
        const {productTypes} = this.state;

        Object.values(productTypes).forEach(value => {
            value.selected = value.edited;
        });

        this.props.addProducts(productTypes)
    }

    addProduct(selectedProduct) {
        const {productTypes} = this.state;
        try {
            productTypes[selectedProduct.productType].edited = selectedProduct;
            this.setState({productTypes, edits: true});
            this.setPercentages(productTypes);
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    updateSelection(selectedProduct) {
        const {productTypes} = this.state;
        productTypes[selectedProduct.productType].edited = selectedProduct;
    }
    
    cancelChanges() {
        const {productTypes} = this.state;
        Object.entries(productTypes).forEach(entry => {
            productTypes[entry[0]].edited = productTypes[entry[0]].selected;
        })
        this.setState({edits: false, productTypes})
        this.props.cancelChanges();
    }

    handleCloseClick() {
        const {edits} = this.state;
        if(edits) {
            this.setState({showModal: true})
        } else {
            this.props.cancelChanges();
        }
    }

    renderPriceLimitReached() {
        const {priceLimitReached} = this.state;
        if(priceLimitReached) {
            return <Alert variant="warning" className="alert-fixed-bottom">Max order price is $200</Alert>
        }
    }

    renderQuantityLimitReached() {
        const {quantityLimitReached} = this.state;
        if(quantityLimitReached) {
            return <Alert variant="warning" className="alert-fixed-bottom">You have reached the maximum amount for {quantityLimitReached}</Alert>
        }
    }

    renderSaveModal() {
        const {showModal, productTypes} = this.state;
        if(showModal) {
            return (
                <div className="modal-container transparent">
                    <div className="modal-dialog">
                        <img onClick={() => {this.setState({showModal: false})}} className="text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="signin-modal-tab-container">
                            <div className="prediction-modal-body">
                                <p className="modal-title">
                                    Do you want to save the changes made?
                                </p><br />
                                <p className="modal-p">
                                    Your changes will be lost if you don't save them.
                                </p>
                            </div>
                        </div>
                        <button className="dashboard-btn dashboard-submit white" onClick={this.cancelChanges}>Don't Save</button>
                        <button className="dashboard-btn dashboard-save" onClick={() => this.props.addProducts(productTypes)}>Save Changes</button>
                    </div>
                </div>
            )
        }
    }

    renderBundle() {
        const {bundles, bundleNumerators, productGroupsMap, productTypes, preferredProducts, newOptions} = this.state;
        const {interview} = this.props;
        if(bundles && bundleNumerators) {
            const rows = [];
            Object.entries(bundles).forEach(entry => {
                const bundleTypes = {};
                const bundle = entry[0];
                const bundleNumerator = bundleNumerators[bundle];
                const types = entry[1];
                Object.entries(preferredProducts).forEach(p => {
                    const productType = p[0];
                    const product = p[1];
                    if(types.indexOf(productType) > - 1) {
                        bundleTypes[productType] = product;
                    }
                })
                rows.push(
                    <BundleRow
                        bundle={bundle}
                        bundleNumerator={bundleNumerator}
                        selectedProducts={bundleTypes}
                        productTypes={productTypes}
                        productGroupsMap={productGroupsMap}
                        increment={this.increment}
                        decrement={this.decrement}
                        updateSelection={this.updateSelection}
                        updateInterview={this.props.updateInterview}
                        newOptions={newOptions}
                        clearnewOptions={() => this.setState({newOptions: false})}
                        interview={interview}
                        history={this.props.history}
                        showDescription={this.props.showDescription}
                        addProduct={this.addProduct}
                    />
                )
            })
            return rows;
        }
    }

    renderBtnRow() {
        const {edits} = this.state;
        if(edits) {
            return (
                <div className="order-row btn-row">
                    <div>
                        <button className="dashboard-btn dashboard-save" onClick={this.saveChanges}>Save Changes</button>
                        <button className="dashboard-btn dashboard-cancel-link" onClick={this.cancelChanges}>Cancel</button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <Col xs={12} className="space-bottom lg">
                {this.renderSaveModal()}
                <Container className="order container">
                    <Row className="order-row top">
                        <Col xs={6} className="text-left order-label bold">Add products</Col>
                        <Col xs={6} className="bold"><img onClick={this.handleCloseClick} className="text-right order-x-icon" src={images.btn_close_x} alt="close" /></Col>
                    </Row>
                    {this.renderBundle()}
                    {this.renderBtnRow()}
                    {this.renderPriceLimitReached()}
                    {this.renderQuantityLimitReached()}
                </Container>
            </Col>
        )
    }
}