import React, {Component} from 'react';
import {
    CardElement,
    injectStripe
} from 'react-stripe-elements';
import * as images from '../../../assets';
import { FormError } from '../../Common';
import { FailedPaymentText } from '../../../Style';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { Logger } from '../../../helpers/Logger';
const logger = new Logger();

class StripeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameValid: true,
            addressValid: true,
            zipcodeValid: true,
            cityValid: true
        };

        this.updateBilling = this.updateBilling.bind(this);
        this.cancelBillingEdits = this.cancelBillingEdits.bind(this);
    }

    componentDidMount() {
        const {customer} = this.props;
        const {paymentSource, shipping} = customer;
        this.setState({customer, shipping});

        if(paymentSource && !this.state.card) {
            this.setState({card: paymentSource});
        }
    }

    componentDidUpdate() {
        const {
            editing,
            states
        } = this.props;
        if(editing !== this.state.editing) {
            this.setState({ editing });
        }
        if(states && !this.state.states) {
            this.setState({states});
        }
    }

    cancelBillingEdits() {
        this.setState({sourceError: undefined})
        this.props.cancel();
    }

    async updateBilling() {
        const {
            customer,
        } = this.state;
        this.setState({sourceError: undefined})

        const {source, error} = await this.props.stripe.createSource({
            type: 'card',
            owner: {
                email: customer.email.toLowerCase(),
                phone: customer.phone,
            }
        });

        if(source) {
            this.props.updateSource(source.id);
        } else {
            Sentry.captureException(error);
            this.setState({sourceError: error.message});
        }
    }

    renderError() {
        return <FormError copy={this.state.sourceError} />
    }

    renderActionButton() {
        const { order } = this.props;

        try {
            if (order) {
                const shipDatePlus10 = moment(order.expectedShipDate).add(10,"days");
                const rechargeNotAllowed = moment().isAfter(shipDatePlus10,"day");
                return (
                    <button onClick={this.updateBilling} className="dashboard-btn dashboard-save" >
                        {((order.status === "PaymentFailed" || order.status === "AwaitingPayment") && !rechargeNotAllowed) ?
                        "Retry Payment" : "Save Changes"}
                    </button>
                )

            } else {
                return(
                    <button onClick={this.updateBilling} className="dashboard-btn dashboard-save">Save Changes</button>
                )
            }
        }
        catch(err) {
            logger.error(err)
        }
    }

    renderFailedPaymentText() {
    const { order } = this.props;
        try {
            if (order) {
                const shipDatePlus10 = moment(order.expectedShipDate).add(10,"days");
                const rechargeNotAllowed = moment().isAfter(shipDatePlus10,"day");
                const formattedDeliveryDate = moment(order.expectedDeliveryDate).format('ll');

                if ((order.status === "PaymentFailed" || order.status === "AwaitingPayment") && !rechargeNotAllowed) {
                    return (
                        <FailedPaymentText>
                            We were unable to process your payment. Please update your payment information to retry your {formattedDeliveryDate} order.
                        </FailedPaymentText>
                    )
                }
            }
        }
        catch(err) {
            logger.error(err)
        }
    }

    render() {
        const { sourceError } = this.state;
        return (
            <div id="non-account-modal-container" className="modal-container transparent">
                <div className="interview-question-modal">
                    <img onClick={this.cancelBillingEdits} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                    <div className="interview-modal-body$ no-padding">
                        <div className="signin-modal-tab-container">
                            <div className="cancel-modal-body">
                                <p className="modal-title">
                                    Update Payment Info
                                </p>
                                <hr />
                                <div className="modal-input-container full">
                                    <label className="modal-label">Credit Card</label>
                                    <CardElement className={`stripe-input${sourceError ? " invalid" : ""}`} />
                                </div>
                                {this.renderError()}
                            </div>
                            {this.renderFailedPaymentText()}
                            <div className="cancel-modal-button-row">
                                <button onClick={this.cancelBillingEdits} className="dashboard-btn dashboard-submit white">Cancel</button>
                                {this.renderActionButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectStripe(StripeForm);