import React, { Component } from 'react';
import { navigation_share } from '../../../assets';
import ReactGA from 'react-ga';


export class NavigationShare extends Component {
    constructor(props){
        super(props);
        this.state = {}

        this.shareNative = this.shareNative.bind(this);
    }

    componentDidMount() {
        const shareButton = document.querySelector('.native-share-button');
        shareButton.addEventListener('click', this.shareNative, false)
    }

    componentWillUnmount() {
        const shareButton = document.querySelector('.native-share-button');
        shareButton.removeEventListener('click', this.shareNative, false)
    }

    shareNative() {
        const { textToShare, urlToShare, title } = this.props;

        if (navigator.share) {
            navigator.share({
                title: `${title}`,
                text: `${textToShare}`,
                url: `${urlToShare}`
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        }
        ReactGA.event({
            category: 'DashboardUIEvents',
            action: 'Clicked Share via Navigator Share',
            label: `${urlToShare}`,
        })
    }

    render() {
        return(
            <span style={{cursor: "pointer"}} className="native-share-button">
                <img src={navigation_share}
                    alt="facebook_logo"
                    onClick={() => this.shareNative()}
                />
            </span>
        )
    }
}