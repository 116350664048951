import React from 'react';

export const FormError = ({copy}) => {
    const error = () => {
        if(copy) {
            return (
                <div>
                    <i className="fa fa-times-circle form-error-icon"></i>
                    <span>{copy}</span>
                </div>
            )
        }
    }
    return (
        <div className="form-error">
            {error()}
        </div>
    )
}