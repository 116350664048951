export const
    // Interview
    GET_INTERVIEW_ROUNDER_SUCCESS = 'GET_INTERVIEW_ROUNDER_SUCCESS',
    GET_INTERVIEW_ROUNDER_FAIL = 'GET_INTERVIEW_ROUNDER_FAIL',
    GETTING_ROUNDER = 'GETTING_ROUNDER',
    POST_INTERVIEW_SUCCESS = 'POST_INTERVIEW_SUCCESS',
    POST_INTERVIEW_FAIL = 'POST_INTERVIEW_FAIL',
    POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS',
    POST_FEEDBACK_FAIL = 'POST_FEEDBACK_FAIL',
    GET_PROJECTIONS_FAIL = 'GET_PROJECTIONS_FAIL',
    GET_PROJECTIONS_SUCCESS = 'GET_PROJECTIONS_SUCCESS',
    CLEAR_PROJECTIONS = 'CLEAR_PROJECTIONS',
    CLEAR_ROUNDER = 'CLEAR_ROUNDER',
    CLEAR_INTERVIEWS = 'CLEAR_INTERVIEWS',
    PUT_INTERVIEW_SUCCESS = 'PUT_INTERVIEW_SUCCESS',
    PUT_INTERVIEW_FAIL = 'PUT_INTERVIEW_FAIL',
    PUTTING_INTERVIEW = 'PUTTING_INTERVIEW',

    // Products
    GET_PRODUCT_GROUPS_SUCCESS = 'GET_PRODUCT_GROUPS_SUCCESS',
    GET_PRODUCT_GROUPS_FAIL = 'GET_PRODUCT_GROUPS_FAIL',
    GETTING_PRODUCT_GROUPS = 'GETTING_PRODUCT_GROUPS',

    //FAQs
    GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS",
    GET_FAQ_FAIL = "GET_FAQ_SUCCESS",
    GETTING_FAQ = "GETTING_FAQ",

    // Orders
    CLEAR_ORDERS_STATE = 'CLEAR_ORDERS_STATE',
    CLEAR_ORDERS_ERRORS = 'CLEAR_ORDERS_ERRORS',
    CLEAR_CREATE_ORDERS = 'CLEAR_CREATE_ORDERS',
    CLEAR_RECHARGING = 'CLEAR_RECHARGING',
    ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS',
    ADD_ORDER_FAIL = 'ADD_ORDER_FAIL',
    ADD_INTERVIEW_SUCCESS = 'ADD_INTERVIEW_SUCCESS',
    ADD_INTERVIEW_FAIL = 'ADD_INTERVIEW_FAIL',
    UPCOMING_ORDER_SUCCESS = 'UPCOMING_ORDER_SUCCESS',
    UPCOMING_ORDER_FAIL = 'UPCOMING_ORDER_FAIL',
    UPDATING_ORDER = 'UPDATING_ORDER',
    UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL',
    GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS',
    GET_ORDERS_FAIL = 'GET_ORDERS_FAIL',
    CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL',
    GETTING_ORDER = 'GETTING_ORDER',
    GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS',
    GET_ORDER_FAIL = 'GET_ORDER_FAIL',
    GETTING_MANIFEST = 'GETTING_MANIFEST',
    GET_MANIFEST_SUCCESS = 'GET_MANIFEST_SUCCESS',
    GET_MANIFEST_FAIL = 'GETTING_MANIFEST_FAIL',
    PUT_MANIFEST_FAIL = 'PUT_MANIFEST_FAIL',
    PUT_MANIFEST_SUCCESS = 'PUT_MANIFEST_SUCCESS',
    UPDATING_MANIFEST = 'UPDATING_MANIFEST',
    CREATING_ORDER = 'CREATING_ORDER',
    GETTING_LANDING = 'GETTING_LANDING',
    GET_LANDING_SUCCESS = 'GET_LANDING_SUCCESS',
    GET_LANDING_FAIL = 'GET_LANDING_FAIL',
    GET_SHIPMENT_FAIL = 'GET_SHIPMENT_FAIL',
    GET_SHIPMENT_SUCCESS = 'GET_SHIPMENT_SUCCESS',
    GETTING_SHIPMENT = 'GETTING_SHIPMENT',
    GET_TAX_SUCCESS = 'GET_TAX_SUCCESS',
    GET_TAX_FAIL = 'GET_TAX_FAIL',
    GETTING_TAX = 'GETTING_TAX',
    RECHARGING = 'RECHARGING',
    RECHARGE_SUCCESS = 'RECHARGE_SUCCESS',
    RECHARGE_FAIL = 'RECHARGE_FAIL',
    UNAUTHORIZED = 'UNAUTHORIZED',
    
    // Users
    LOGIN_FAILED = 'LOGIN_FAILED',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    REGISTER_FAILED = 'REGISTER_FAILED',
    REGISTER_SUCCESS = 'REGISTER_SUCCESS',
    GETTING_USER = 'GETTING_USER',
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',
    GET_USER_FAIL = 'GET_USER_FAIL',
    UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL',
    UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL',
    UPDATING_STATUS = 'UPDATING_STATUS',
    UPDATING_CUSTOMER = 'UPDATING_CUSTOMER',
    UPDATE_SOURCE_SUCCESS = 'UPDATE_SOURCE_SUCCESS',
    UPDATE_SOURCE_FAIL = 'UPDATE_SOURCE_FAIL',
    UPDATING_SOURCE = 'UPDATING_SOURCE',
    CLEAR_CUSTOMER_STATE = 'CLEAR_CUSTOMER_STATE',
    CLEAR_CUSTOMER = 'CLEAR_CUSTOMER',
    NEW_MAGIC_LINK_FAIL = 'NEW_MAGIC_LINK_FAIL',
    NEW_MAGIC_LINK_SUCCESS = 'NEW_MAGIC_LINK_SUCCESS',
    GETTING_MAGIC_LINK = 'GETTING_MAGIC_LINK',
    USER_DOESNT_EXIST = 'USER_DOESNT_EXIST',
    POST_PROSPECT_FAIL = 'POST_PROSPECT_FAIL,',
    POST_PROSPECT_SUCCESS = 'POST_PROSPECT_SUCCESS,',
    POSTING_PROSPECT = 'POSTING_PROSPECT',
    ENROLL_CUSTOMER_SUCCESS = 'ENROLL_CUSTOMER_SUCCESS',
    ENROLL_CUSTOMER_ERROR = 'ENROLL_CUSTOMER_ERROR',
    ENROLLING_CUSTOMER = 'ENROLLING_CUSTOMER',

    // Auth Error Types
    SIGNED_IN_AUTH_ERROR = 'SIGNED_IN_AUTH_ERROR',
    NEW_USER_AUTH_ERROR = 'NEW_USER_AUTH_ERROR',
    CLEAR_GLOBAL_ERRORS = 'CLEAR_GLOBAL_ERRORS'