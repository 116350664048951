import React from 'react';
import { instagram_logo } from '../../../assets';

export const InstagramShare = ({
      shareProfile,
      urlToShare
  }) => {

        const InstagramLinkToReturn = () => {
            if (shareProfile) {
                return (
                    <span>
                        <a target="_blank"
                            href={urlToShare}
                            rel="noopener noreferrer"
                        >
                            <img src={instagram_logo} alt="instagram_logo" />
                        </a>
                    </span>
                )
            } 
        }

        return(
            <>
                {InstagramLinkToReturn()}
            </>
        )
}