import React from 'react';
import ReactContentfulImage, { setup as reactContentfulImageSetup } from 'react-contentful-image';

const screenXs = '360px';
const screenSm = '600px';
const screenMd = '960px';
const screenLg = '1280px';
const screenXl = '1920px';
 
const media = {
  xs: `(min-width: ${screenXs})`,
  sm: `(min-width: ${screenSm})`,
  md: `(min-width: ${screenMd})`,
  lg: `(min-width: ${screenLg})`,
  xl: `(min-width: ${screenXl})`,
  dpr2: '(min-resolution: 144dpi)', // 1.5x devices and up get 2x images
  dpr3: '(min-resolution: 240dpi)', // 2.5x devices and up get 3x images
  portrait: '(orientation: portrait)',
  landscape: '(orientation: landscape)',
};
 
// Object keys are media query names from above. 'default' means no media query (default match).
// E.g. for mobile first 'default' would be the mobile media query
const variants = {
  default: {
    quality: 85,
    density: 1,
  },
  dpr2: {
    quality: 35,
    density: 2,
  },
  dpr3: {
    quality: 25,
    density: 3,
  },
};

export function init() {
  // TODO: passing in appendHTTPS: false is broken in library
  reactContentfulImageSetup(media, variants, {appendHTTPS: false});
}

/**
 * Generates a react element that displays different images for various media queries.
 *
 * @param {Object} props Normal img element props along with a sizes prop specifying
 *  the image sizes to generate. See getMultiScaledImages() description for details.
 * @returns {Object} React element, either <picture> or <img>
 */
const ContentfulImage = ({ src, defaultWidth, defaultHeight, ...props }) => {
  // hack because appendHTTPS:false doesn't work we need to remove that so it can re-apply it
  if (src) {
    src = src.replace(/^(https:)/,"")  
  }
  
  // width and height are used as base media query sizes that will be scaled
  // on devices with different pixel density e.g. 2x 3x on iOS retina
  const sizes = [
    {
      mediaQuery: 'default',
      params: { w: defaultWidth, h: defaultHeight },
    }
  ];
  return <ReactContentfulImage src={src} sizes={sizes} {...props}/>
};

export default ContentfulImage;
