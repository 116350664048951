import   React, {Component, Fragment} from 'react';
import {Nav, MoreInfo, ContinueButton, Loading} from '../Common';
import * as images from '../../assets';
import {Auth}from 'aws-amplify';
import {Alert} from 'react-bootstrap';
import * as Sentry from '@sentry/browser';

class TraditionalLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moreInfoCopy: "Please enter the password you set when signing up"
        }
        this.handleKeydown = this.handleKeydown.bind(this);
        this.signIn = this.signIn.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    componentDidMount() {
        Auth.configure({
            authenticationFlowType: 'USER_SRP_AUTH'
        });
    }

    handleKeydown(e) {
        const {showInfo, email, password} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else if(email && password) {
                this.signIn();
            }
        }
    }

    renderInfo() {
		const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    signIn() {
        const {email, password} = this.state;
        this.setState({loading: true})
        Auth.signIn(email.toLowerCase(), password).then(data => {
            this.props.history.push('/dashboard');
        }).catch(err => {
            if(err.code === "UserNotConfirmedException") {
                Auth.resendSignUp(email.toLowerCase())
                    .then(() => {
                        this.setState({ unconfirmed: true, loading: false});
                    }).catch(e => {
                        Sentry.captureException(err);
                        console.error('failed with errror ', e)
                        this.setState({error: e.message, loading: false})
                    });

                } else if(err.message === "Incorrect username or password." || err.message === "Password attempts exceeded") {
                    this.setState({error: err.message, loading: false});
                } else {
                    Sentry.captureException(err);
                    console.error('failed with errror ', err)
                    this.setState({error: "Something went wrong. Please contact care@supplydrop.com for assistance.", loading: false})
                }
        })
    }

    onFieldChange(field, value) {
        this.setState({[field]: value, error: undefined})
    }

    renderError() {
        const {error} = this.state;
        if(error) {
            return (
                <Alert className="signin-alert" variant="danger">Incorrect email or password.</Alert>
            )
        }
    }

    renderMain() {
        const {email, loading, password} = this.state;
        if(!loading) {
            return (
                <div className="flex-col-center">
                     <input
                        autoComplete
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email address"
                        className="signin-modal-input margin-auto"
                        onChange={(e) => this.onFieldChange('email', e.target.value)}
                    />
                     <input
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        className="signin-modal-input margin-auto"
                        onChange={(e) => this.onFieldChange('password', e.target.value)}
                    />
                    {this.renderError()}
                </div>
            )
        } else {
            return (
                <div className="signin-loading-container">
                    <Loading />
                </div>
            )
        }
    }

    render() {
        const {email, password, loading} = this.state;
        return (
            <Fragment>
                <Nav pathname="/" />
                {this.renderInfo()}
                <div className="interview-outer">
                    <div className="interview-nav-container">
                        <div className="interview-top-row signin">
                            <div></div>
                            <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" alt="Info" />
                        </div>
                    </div>
                    <div className="interview-inner text-center">
                        <div className="interview-header-img"><img src={images.login} alt="Security" /></div>
                        <div className="signin-main-header">Your account</div>
                        {this.renderMain()}
                    </div>
                    <ContinueButton disabled={(!email || !password) || loading} next={this.signIn} text="Sign In" />
                </div>
            </Fragment>
        )
    }
}

export default TraditionalLogin;
