import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import ContentfulImage from '../../../../contentful';
import { SuggestedProductGroupPaper, SuggestedProductGroupLabel as Label } from '../../../../Style/Manifest';
import { formatProductType } from '../../../../actions';
import { FlexColCenter } from '../../../../Style';

export const SuggestedProductGroup = (props) => {
    const { productGroup, showOtherBrands, isMobile } = props;
    const { imageUrl, displayName, brandName, productType } = productGroup
    const formattedProductType = formatProductType(productType);
    const name = brandName + " " + displayName + " " + formattedProductType;

    const [elevation, setElevation] = useState(2);

    return (
        <SuggestedProductGroupPaper
            as={Paper}
            elevation={elevation}
            onClick={showOtherBrands}
            onMouseOut={() => setElevation(2)}
            onMouseOver={() => setElevation(5)}
        >
            <FlexColCenter>
                <ContentfulImage
                    src={imageUrl}
                    defaultWidth={isMobile ? "105" : "120"}
                    defaultHeight={isMobile ? "105" : "120"}
                    alt={name}
                />
                <Label>{name}</Label>
            </FlexColCenter>
        </SuggestedProductGroupPaper>
    )
}