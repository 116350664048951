import React, {Component, Fragment} from 'react';
import {Alert} from 'react-bootstrap';
import {InterviewQuestion} from '../../Common';
import * as images from '../../../assets';
import { marginForCountdown } from '../../../actions';
import { featureFlags } from '../../../config';
import ReactGA from 'react-ga';

class Household extends Component {
    constructor(props) {
        super(props);

        this.state = {
            family: [],
            pets: {
                Cat: 0,
                Dog: 0
            },
            id: 0,
            infoCopy: (
                <div>
                    Every household is unique. 
                    Some people have pets.
                    Some people work from home.
                    Some people have 8.5 bathrooms!
                    Understanding who lives in your home allows us to curate the perfect order just for you.
                </div>
            ),
            image: {
                src: images.household,
                alt: "Household"
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.renderPetRows = this.renderPetRows.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.addMember = this.addMember.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if(this.props.household) {
            const {pets, family} = this.props.household;
            this.setState({pets, family, id: family.length});
        }

        if(this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);

        if(featureFlags.isUngated)
            ReactGA.pageview('how-it-works');
    }

    setSignUpData(signUpData) {
        this.setState({signUpData})
        this.setStateForKeyValue(signUpData);
        if(signUpData.interview) {
            this.setStateForKeyValue(signUpData.interview);
            this.setState({interview: signUpData.interview})
        } else {
            const {family, pets} = this.state
            const interview = {family, pets};
            this.setState({interview})
        }

        if(signUpData.zipcode)
            marginForCountdown();
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if(value !== undefined) {
                    this.setState({[key]: value})
                }
            })
        } catch(err) {
            console.error(err);
        }
    }

    handleChange(age, id) {
        const {family} = this.state;
        const member = family.filter(m => m.id === id)[0];
        const newFamily = family.filter(m => m.id !== id);
        member.age = age;
        
        newFamily.push(member);
        newFamily.sort((a, b) => {
            return a.id - b.id;
        });

        this.setState({family: newFamily, emptyAgeWarning: ""})
    }

    handleBlur(age, id) {
        const {family} = this.state;
        const member = family.filter(m => m.id === id)[0];
        const newFamily = family.filter(m => m.id !== id);
        member.age = isNaN(age) ? undefined : age <= 99 ? age : 99;
        
        newFamily.push(member);
        newFamily.sort((a, b) => {
            return a.id - b.id;
        });

        this.setState({family: newFamily, emptyAgeWarning: "", activeMember: ""})
    }

    back() {
        const { signUpData, landingData, signupsOpen } = this.state;
        const pushInterviewFlow = (featureFlags.isUngated && landingData && signupsOpen);
        const pathname = pushInterviewFlow ? '/' : '/how-it-works';

        this.props.history.push({
            pathname,
            search: window.location.search,
            state: { signUpData }
        });
    }

    next() {
        let { interview } = this.state;
        const {
            family,
            pets,
            signUpData
        } = this.state;

        if(!interview)
            interview = {};

        const {isModal} = this.props;

        if(family.length) {
            let emptyAgeWarning;
            let noAdultWarning = "Please add a member of age 18+ to your household";
            family.forEach(member => {
                let { age } = member;
                if(age === undefined)
                    emptyAgeWarning = "Please specify an age for each family member";
                else if (age > 17) {
                    noAdultWarning = '';

                    if (age > 99)
                        age = 99;
                }
            })
            if(!emptyAgeWarning && !noAdultWarning) {
                if(isModal) {
                    this.props.next({family, pets});
                } else {
                    const newFamily = family.map(member => {
                        const {gender, age, staysHome, isolated, type, id} = member
                        return {gender, age, staysHome, isolated, type, id}
                    });
                    interview.pets = pets;
                    interview.family = newFamily;
                    signUpData.interview = interview;
    
                    this.props.history.push('/start/2', {signUpData});
                }

            } else {
                this.setState({emptyAgeWarning, noAdultWarning})
            }
        }
    }

    renderAlert() {
        const {emptyAgeWarning, noAdultWarning} = this.state;
        if(emptyAgeWarning)
            return <Alert key={0} variant="warning">{emptyAgeWarning}</Alert>
        else if (noAdultWarning)
            return <Alert key={0} variant="warning">{noAdultWarning}</Alert>
    }

    renderPetRows() {
        const {pets} = this.state;
        const rows = [];       
        
        if(pets.Dog) {
            for(let i = 0; i < pets.Dog; i++){
                rows.push(
                    <div key={"dog"+i} className="household-pet-wrapper">
                        <img src={images.x_icon} className="pet-x-icon" onClick={() => this.removePet("Dog")} alt="Remove Dog" />
                        <div className="interview-household-img"><img src={images.dog} alt="Dog" /></div>
                    </div>
                )
            }
        }
        if(pets.Cat) {
            for(let i = 0; i < pets.Cat; i++){
                rows.push( 
                    <div key={"cat"+i} className="household-pet-wrapper">
                        <img src={images.x_icon} className="pet-x-icon" onClick={() => this.removePet("Cat")} alt="Remove Cat" />
                        <div className="interview-household-img"><img src={images.cat} alt="Cat" /></div>
                    </div>
                )
            }
        }

        return (
            <div className="household-icons-row">
                {rows}
            </div>
        )
    }

    removeFamilyMember(item) {
        const {family} = this.state;
        const index = family.map( item => { return item.id }).indexOf(item.id);
        const newFamily = [ ...family ];
        newFamily.splice(index, 1);

        this.setState({ family: newFamily });
    }

    removePet(type) {
        const {pets} = this.state;
        pets[type]--;
        this.setState({ pets });
    }

    renderFamily() {
        const {family, activeMember} = this.state;
        if(family.length) {
            const rows = family.map((member, index) => {
                return (
                    <div key={index} className="household-pill-container">
                        <img src={images.x_icon} className="household-x-icon" onClick={() => this.removeFamilyMember(member)} alt="Remove Family Member" />
                        <div className={`household-pill ${activeMember === member.id ? "active" : member.age === undefined ? "invalid" : ""}`} onClick={() => document.getElementById(`age-input-${member.id}`).focus()}>
                            <div className="household-pill-inner img"><img src={images[`${member.gender.toLowerCase()}_${member.type.toLowerCase()}`]} alt="Family member" /></div>
                            <div>
                                <input 
                                    id={`age-input-${member.id}`}
                                    autoFocus
                                    className="household-age-input"
                                    type="number"
                                    value={member.age !== undefined && !isNaN(member.age) ? member.age : ""}
                                    placeholder="Age"
                                    inputMode="numeric"
                                    pattern="[0-9]{2}"
                                    max="99"
                                    min="0"
                                    onSelect={() => this.setState({inputtingAge: true, activeMember: member.id})}
                                    onChange={(e) => this.handleChange(parseInt(e.target.value), member.id)}
                                    onBlur={(e) => this.handleBlur(parseInt(e.target.value), member.id)}
                                />
                            </div>
                        </div>
                    </div>
                )
            })
            return (
                <div className="household-rows-container">
                    {rows}
                </div>
            )
        }
    }

    addMember(type, gender) {
        const {id, pets, family} = this.state;
        if(type === "Adult" || type === "Child") {
            family.push({type, gender, id, age: undefined, staysHome: false, isolated: false});
            this.setState({family, id: id + 1 });
        } else {
            pets[type]++;
            this.setState({pets});
        }
        const element = document.getElementById(`${type}${gender ? gender : ""}`);
        element.classList.add("fadeOutDown");
        setTimeout(function(){ element.classList.remove("fadeOutDown"); }, 1000);
    }

    renderAddButtons() {
        return (
            <div className="household-icons-container">
                <div className="household-icons-row">
                    <div id="AdultFemale" className="interview-household-img animated-medium" onClick={() => this.addMember("Adult", "Female")}><img src={images.female_adult} alt="Add adult female" /></div>
                    <div id="AdultMale" className="interview-household-img animated-medium" onClick={() => this.addMember("Adult", "Male")}><img src={images.male_adult} alt="Add adult male" /></div>
                    <div id="ChildFemale" className="interview-household-img animated-medium" onClick={() => this.addMember("Child", "Female")}><img src={images.female_child} alt="Add female child" /></div>                
                    <div id="ChildMale" className="interview-household-img animated-medium" onClick={() => this.addMember("Child", "Male")}><img src={images.male_child} alt="Add male child"/></div>
                    <div id="Dog" className="interview-household-img animated-medium" onClick={() => this.addMember("Dog")}><img src={images.dog} alt="Add dog"/></div>    
                    <div id="Cat" className="interview-household-img animated-medium" onClick={() => this.addMember("Cat")}><img src={images.cat} alt="Add cat"/></div>    
                </div>
            </div>
        )
    }

    renderMyHousehold() {
        const {family, pets} = this.state;
        let hasPets = 0;
        Object.values(pets).forEach(value => {
            hasPets += value;
        })
        if(family.length || hasPets) {
            return (
                <p className="interview-copy">My household:</p>
            )
        }
    }

    renderBirthdateCopy() {
        const {family} = this.state;
        if(family.length) {
            return (
                <p className="interview-copy">Your age matters because it helps us<br/>predict what you'll need.</p>
            )
        }
    }

    renderBody() {
        return (
            <Fragment>
                {this.renderAddButtons()}
                {this.renderMyHousehold()}
                {this.renderFamily()}
                {this.renderPetRows()}
                {this.renderBirthdateCopy()}
                {this.renderAlert()}
            </Fragment>
        )
    }

    render() {
        const {image, infoCopy, family} = this.state;
        const {history, isModal, back, close, continueText, cancelText} = this.props;
        return (
            <InterviewQuestion
                key="Household"
                id="Household"
                back={isModal ? back : this.back}
                next={this.next}
                close={close}
                history={history}
                image={image}
                question="Who lives in your home?"
                isModal={isModal}
                infoCopy={infoCopy}
                body={this.renderBody}
                disabled={!family.length}
                continueText={continueText}
                cancelText={cancelText}
            />
        )
    }
}

export default Household;
