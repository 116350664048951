import React from 'react';
import ContentfulImage from '../../../contentful';
import { ProductDropdown } from '..';
import { formatProductType, getConsumptionEstimateMonths, getUnits } from '../../../actions';
import { CadenceCalendar } from '../Alerts/CadenceCalendar';
import { ConsumptionEstimateText, ConsumptionEstimateContainer, FlexCol } from '../../../Style';
import { Logger } from '../../../helpers/Logger';
const logger = new Logger();

export const BundleProduct = ({
    productGroup,
    cta,
    showRemove,
    last,
    selected,
    selectProductGroup,
    bundleShortName,
    onQuantityChange,
    hideProductType,
    onlyOffering,
    showDescription,
    showOtherBrands,
    view,
    handleDropdownSelect,
    needsTrashQuestion,
    isDashboard
}) => {
    const {
        articlesPerDay,
        articleType,
        brandName,
        cappedArticleCounts,
        displayName,
        displayUnits,
        groupName,
        imageUrl,
        proposedArticles,
        pricePerArticle,
        productType,
        replaceWith,
    } = productGroup;

    const articles = productGroup.articles !== undefined ? productGroup.articles : proposedArticles;
    if(isNaN(articles)) {
        logger.error(new Error("invalid articles value in BundleProduct"))
    }

    const months = articlesPerDay ? getConsumptionEstimateMonths(articlesPerDay, articles, proposedArticles, isDashboard) : 1;

    const singleUnit = getUnits(articleType, 1, true);
    const units = getUnits(articleType, articles, true);
    const ctaButton = () => {
        if ((cta === "add" || cta === "change") && view !== "complete") {
            let copy = "Select"
            if (cta === "add" && !needsTrashQuestion) copy = "Add to plan"
            else if (selected) copy = "Selected"
            else if (!needsTrashQuestion) copy = "Change to this brand"

            const clickFunction = () => needsTrashQuestion ? handleDropdownSelect(productType) : selectProductGroup(groupName, cta, bundleShortName);

            return (
                <div className="products-modal-btn-container">
                    <button className="products-modal-btn" onClick={clickFunction} disabled={selected}>{copy}</button>
                </div>
            )
        }
    }

    const refillAlert = () => {
        if (replaceWith) {
            return (
                <div className="refill-notif-container" onClick={() => showDescription(replaceWith)}>
                    <div className="refill-notif-copy">Future deliveries will be refills</div>
                </div>
            )
        }
    }

    const brandLink = () => {
        if ((!cta || cta === "add") && view !== "complete") {
            if (!onlyOffering) {
                return <div className="link" style={{ 'paddingLeft': '4' }} onClick={showOtherBrands}>Change Brand</div>
            }
        }
    }

    const renderDropdown = () => {
        if (view !== "complete" && !needsTrashQuestion) {
            return (
                <ProductDropdown
                    onQuantityChange={onQuantityChange}
                    possibleArticleCounts={cappedArticleCounts}
                    articles={articles}
                    articleType={articleType}
                    pricePerArticle={pricePerArticle}
                    showRemove={showRemove}
                    groupName={groupName}
                    productType={productType}
                />
            )
        }
    }

    const renderStaticPrice = () => {
        if (view === "complete") {
            return (
                <div className="order-complete-line-price"><b>{articles} {units}</b> - ${(pricePerArticle * articles / 100).toFixed(2)}</div>
            )
        }
    }

    const thisLastMonths = () => {
        if (months > 0) {
            let displayMonths = Math.floor(months);
            if(!displayMonths) displayMonths = 1;
            return (
                <ConsumptionEstimateContainer>
                    <CadenceCalendar months={displayMonths} hasArrow={true} />
                    <FlexCol>
                        <ConsumptionEstimateText>
                            This should last you {displayMonths} month{displayMonths > 1 ? 's' : ''}.{/* TODO: Implement modal with 'learn why' instructions */}
                        </ConsumptionEstimateText>
                    </FlexCol>
                </ConsumptionEstimateContainer>
            )
        }
    }

    return (
        <div className={`bundle-product${last ? " last" : ""}`}>
            <div className={hideProductType ? "hide" : "bundle-product-type"}>{formatProductType(productType)}</div>
            <div className="bundle-product-main">
                <div className="flex-column flex-center bundle-product-img-container pointer" onClick={() => showDescription(groupName)}>
                    <ContentfulImage className="bundle-product-img" src={imageUrl} alt={`${brandName} ${displayName}`}
                        defaultWidth="100" defaultHeight="100"
                    />
                </div>
                <div className="bundle-product-body-container">
                    <div className={`bundle-product-body${needsTrashQuestion || view === "complete" ? " no-dropdown" : ""}`}>
                        {renderDropdown()}
                        <div className="bundle-product-details">
                            <div className="bundle-product-name">{brandName} {displayName}</div>
                            {renderStaticPrice()}
                            <div className="bundle-product-price">${(pricePerArticle / 100).toFixed(2)} per {!!displayUnits ? displayUnits : singleUnit}</div>
                        </div>
                    </div>
                    {refillAlert()}
                    {brandLink()}
                    {thisLastMonths()}
                    {ctaButton()}
                </div>
            </div>
        </div>
    )
}
