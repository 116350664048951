import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import Analytics from "../../analytics";
import { connect } from 'react-redux';
import { signIn } from './SignIn';
import {
    Loading,
    ProductDetail,
    MetaTags,
    FixedContinueBtn,
    ProductsModal,
    KitchenFlow,
    CancelModal,
    AlertModal,
    InterviewQuestionModal,
    ManifestBundle,
    OrderSummary,
    PhoneBanner,
    CadenceModal,
    CadenceAlert
} from '../Common';
import { bundles } from '../../data';
import PhoneVerification from '../Common/Connected/PhoneVerification';
import { Snackbar } from '@material-ui/core';
import {
    appendAddProductModalNoScroll,
    appendBodyNoScroll,
    changeBundleProductQuantity,
    checkIfProductInBundle,
    clearCustomerState,
    clearInterviews,
    clearOrdersState,
    createGlobalAuthError,
    createOrderFromManifestBundles,
    formatInterview,
    formatProductType,
    getBundleProducts,
    getCadenceMonths,
    getMagicLink,
    getManifest,
    getProductGroups,
    getRounder,
    getTax,
    getUser,
    handleNewInterviewData,
    putInterview,
    putManifest,
    refreshCappedValues,
    removeAddProductModalNoScroll,
    removeBodyNoScroll,
    removeProduct,
    setCancelText,
    setManifest,
    setSubtotal,
    setEmptyTax,
} from '../../actions';
import Menu from './Subcomponents/Menu';
import CustomerReferral from './Subcomponents/CustomerReferral';
import {
    PredictionHeaderWrapper as Wrapper,
    CheckoutBody as Body,
    BannerSpacing,
    DashboardNameHeader as Header,
    DashboardNameHeaderContainer as HeaderContainer,
    DashboardNameHeaderSection as HeaderSection
} from '../../Style';
import moment from 'moment';
import { Logger } from '../../helpers/Logger'
import { signOut } from '../../helpers/SignOut';
import Alert from '@material-ui/lab/Alert';
import { shippingChargeCents, freeShippingMinCents } from '../../data/ShippingCharges';
import { getDiscountDollars } from '../../helpers/pricing';
const logger = new Logger();

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orderGroups: {},
            productChanges: {},
            quantityChanges: {},
            subtotal: 0,
            productCount: 0,
            products: {},
            dashboardData: {}
        };

        this.showDescription = this.showDescription.bind(this);
        this.setProductsMap = this.setProductsMap.bind(this);
        this.updateInterview = this.updateInterview.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setManifest = this.setManifest.bind(this);
        this.showOtherBrands = this.showOtherBrands.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.selectProductGroup = this.selectProductGroup.bind(this);
        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.setUnsaved = this.setUnsaved.bind(this);
        this.closeInterviewModal = this.closeInterviewModal.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
        this.skipLastProduct = this.skipLastProduct.bind(this);
        this.setAllToZero = this.setAllToZero.bind(this);
        this.getDashboardData = this.getDashboardData.bind(this);
        this.handleSignedInUser = this.handleSignedInUser.bind(this);
        this.setDiscount = this.setDiscount.bind(this);
        this.setCredits = this.setCredits.bind(this);
        this.renderCustomerReferral = this.renderCustomerReferral.bind(this);
    }

    handleSignedInUser(user) {
        try {
            Analytics.setUser(user.username);
            const { location } = this.props;
            if (user.attributes && !user.attributes.phone_number_verified && (!location.state || !location.state.dashboardData || !location.state.dashboardData.verified)) {
                this.setState({ isUnverified: true })
            }
            this.setState({ signedIn: true })

            ReactGA.event({ category: 'Manifest', action: 'View' })
        }
        catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }
    }

    getDashboardData() {
        try {
            const { location } = this.props;
            if (location && location.state && location.state.dashboardData) {
                const { dashboardData } = location.state;
                const { productGroupsMap } = dashboardData;

                this.props.getUser();

                if (productGroupsMap)
                    this.setState({ productGroupsMap })
                else
                    this.props.getProductGroups();

                this.setState({ dashboardData });
            } else {
                throw new Error("No location state data");
            }
        } catch (err) {
            if (err.message !== "No location state data") {
                console.error(err);
                Sentry.captureException(err);
            }
            this.props.getUser();
            this.props.getProductGroups();
        }
    }

    handleDropdownSelect(productType) {
        try {
            const need = productType.replace("TrashBags", "");
            this.setState({ need: `needs${need}` })
            const element = document.getElementById(`product-select-${productType}`);
            element.blur();
        } catch (err) {
            console.error(err)
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
        window.addEventListener('keydown', this.handleKeydown, false);
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
        window.removeEventListener('keydown', this.handleKeydown, false);
        window.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    componentDidMount() {
        this.props.clearCustomerState(true);

        signIn(this.props.location.search, this.props.history).then(user => {
            this.handleSignedInUser(user);
            this.getDashboardData();
        }).catch(({ error, magicLinkParams }) => {
            console.error(error)
            if (error && error.name === 'AuthError')
                this.props.createGlobalAuthError('signed in user Auth error')
            if (magicLinkParams) {
                const { u, s, channel } = magicLinkParams;
                this.props.getMagicLink(null, window.location.pathname, channel, u);
                this.setState({ magicLinkPath: `u=${u}&s=${s}` })
            } else
                this.props.history.push('/login')
        })

        this.handleResize(null, true);
    }

    componentDidUpdate() {
        const {
            user,
            putManifestError,
            putManifestSuccess,
            getUserError,
            productGroups,
            putInterviewError,
            gettingTax,
            tax,
            getTaxError,
            signedInAuthErr,
            nonSignedInAuthErr,
            history,
            magicLink,
            magicLinkErr,
            gettingMagicLink
        } = this.props;

        const {
            customer,
            loading,
            productGroupsMap,
            manifestBundles,
            refreshTotals,
            newRounder,
            saveOrderFailed,
            globalError,
            magicLinkPath
        } = this.state;

        if (user && !customer) {
            const { interview, error } = formatInterview(user.customer.interview);
            const { cadenceMonths } = getCadenceMonths(user.manifest.cadenceWeeks);
            this.setDiscount(user.manifest.discounts)
            this.setCredits(user.customer.sdCredit, user.customer.referralLink)
            const { employer } = user.customer;
            const isEmployeePerk = !!employer;
            if (error) {
                Sentry.captureException(error);
                console.error(error)
                this.setState({ interview: user.customer.interview, customer: user.customer, employer, isEmployeePerk, manifest: user.manifest, user, cadenceMonths })

            } else {
                this.setState({ interview, customer: user.customer, employer, isEmployeePerk, manifest: user.manifest, user, cadenceMonths, sdCredit: user.customer.sdCredit });
            }
            this.props.clearCustomerState();
        }
        if (this.state.user && productGroupsMap && !manifestBundles)
            this.setManifest(this.state.user.roundedProducts, productGroupsMap, this.state.user.manifest.products, true);

        if (manifestBundles && loading) {
            this.setState({ loading: false })
        }

        if (putManifestSuccess && this.state.updating) {
            this.setState({ updating: false, edits: false });
            setTimeout(() => {
                this.props.clearOrdersState();
            }, 5000);
        }
        if (putManifestError && this.state.updating) {
            this.setState({ error: putManifestError, updating: false });
            setTimeout(() => {
                this.props.clearOrdersState();
            }, 5000);
        }
        if (getUserError && !this.state.error) {
            this.setState({ error: getUserError });
            Sentry.captureException(new Error('Get User Error on dashboard'))
            this.props.clearCustomerState(true);
            signOut(history, true)
        }

        if (productGroups && !this.state.productGroups) {
            this.setState({ productGroups });
            this.setProductsMap(productGroups);
        }

        if (putInterviewError && !this.state.putInterviewError) {
            this.setState({ putInterviewError });
            Sentry.captureException(putManifestError);
            console.error(putManifestError);
        }
        if (refreshTotals) {
            this.setState({ refreshTotals: false })
        }

        if (customer && manifestBundles && this.state.tax === undefined && tax === undefined && !gettingTax && !getTaxError) {
            const { shipping } = customer;
            this.postTax(shipping, manifestBundles);
        }
        if (tax !== undefined && tax !== this.state.tax) {
            this.setState({ tax });
        }

        if (newRounder && this.props.rounder && Object.keys(productGroupsMap)) {
            this.setState({ rounderSet: this.props.rounder.productSets, newRounder: false });
            this.refreshCappedValues(this.props.rounder.productSets, productGroupsMap);
        }
        if (saveOrderFailed) {
            setTimeout(() => {
                this.setState({ saveOrderFailed: false })
            }, 5000);
        }

        if ((signedInAuthErr || nonSignedInAuthErr) && !globalError) {
            this.setState({ globalError: true });
            signOut(history, true)
        }

        if ((gettingMagicLink || magicLink) && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push(`/magiclink?${magicLinkPath}`);
        }

        if (magicLinkErr && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push("/login");
        }
    }

    setDiscount(discounts) {
        try {
            if (discounts && discounts.length) {
                const discount = discounts[0];
                const { coupon } = discount;
                this.setState({ newCoupon: coupon })
            }
        } catch (err) {
            console.error(err);
            Sentry.captureEvent(err);
            Sentry.captureException(new Error('Could not set discount from manifest'));
        }
    }

    setCredits(credits, referralLink) {
        try {
            if (credits) {
                this.setState({ sdCredit: credits })
            }
            if (referralLink) {
                this.setState({ referralLink })
            }
        } catch (err) {
            console.error(err);
            Sentry.captureEvent(err);
            Sentry.captureException(new Error('Could not set sdCredit from customer'));
        }

    }

    async refreshCappedValues(productSets, productGroupsMap) {
        const { newProductsMap, error } = await refreshCappedValues(productSets, productGroupsMap);
        if (newProductsMap)
            this.setState({ productGroupsMap: newProductsMap })
        else {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    async postTax(shipping, manifestBundles) {
        const { coupon, newCoupon, sdCredit, customer } = this.state;
        let amount = 0;
        Object.values(manifestBundles).forEach(bundle => {
            amount += bundle.subtotal;
        })

        const discountDollars = getDiscountDollars(coupon, newCoupon, amount);
        const creditCents = sdCredit ? sdCredit : 0;
        const shippingCents = customer.chargeShipping && amount < freeShippingMinCents ? shippingChargeCents : 0;
        if (amount && discountDollars >= 0) {
            amount = Math.max(0, amount - (discountDollars * 100) - creditCents + shippingCents)
            this.props.getTax({
                amount,
                state: shipping.state,
                postal_code: shipping.zipcode,
            })
        } else if (amount) {
            this.props.getTax({
                amount,
                state: shipping.state,
                postal_code: shipping.zipcode,
            })
        } else {
            await this.props.clearOrdersState();
            this.setState({ tax: 0, refreshTotals: true })
        }

    }

    handleResize(e, init) {
        const { isMobile } = this.state;
        if (window.innerWidth < 1200 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 1200 && (isMobile || init)) {
            this.setState({ isMobile: false })
        }
    }

    async setManifest(rounder, productGroupsMap, selectedProductTypes) {
        const res = await setManifest(rounder, productGroupsMap, selectedProductTypes, true);
        if (!res.error) {
            this.setState({ rounderSet: res.rounderSet, manifestBundles: res.manifestBundles, productGroupsMap: res.productGroupsMap, productCount: res.productCount })
        } else {
            console.error(res.error);
            Sentry.captureException(res.error);
        }
    }

    setProductsMap(productGroups) {
        const { dashboardData } = this.state;
        const productGroupsMap = {};
        productGroups.forEach(pg => {
            productGroupsMap[pg.groupName] = pg;
        })
        dashboardData.productGroupsMap = productGroupsMap;
        this.setState({ productGroupsMap, dashboardData });
    }

    async onQuantityChange(groupName, value) {
        try {
            let { productCount } = this.state;
            const { productGroupsMap, manifestBundles, customer } = this.state;
            const productGroup = productGroupsMap[groupName];
            const isProductInBundle = await checkIfProductInBundle(manifestBundles, productGroup)
            let res;
            if (isProductInBundle) {
                if (value === "remove") {
                    if (productCount > 1) {
                        res = await removeProduct(manifestBundles, productGroup)
                        productCount--;
                    } else {
                        this.setState({ lastProductGroup: productGroup });
                    }
                } else {
                    res = await changeBundleProductQuantity(manifestBundles, productGroup, value)
                }
                if (res && res.manifestBundles) {
                    this.postTax(customer.shipping, res.manifestBundles)
                    this.saveOrder(res.manifestBundles);
                    this.setState({ newChanges: true, manifestBundles: res.manifestBundles, refreshTotals: true, productCount });
                } else {
                    logger.error(new Error("changeBundleProductQuantity failed"))
                    this.setState(res.error, { saveOrderFailed: true })
                }
            } else {
                // in case the productGroup hasn't been added to the manifest yet, but user is making quantity changes
                productGroupsMap[groupName].articles = parseInt(value);
                this.setState({ productGroupsMap, newChanges: true })
            }
        } catch (err) {
            logger.error(err, new Error('onQuantityChangeError'));
        }
    }

    setAllToZero() {
        try {
            const { manifestBundles } = this.state;
            Object.values(manifestBundles).forEach(bundle => {
                Object.values(bundle.productGroups).forEach(product => {
                    product.articles = 0;
                })
                bundle.subtotal = 0;
            })
            this.saveOrder(manifestBundles);
            this.props.setEmptyTax();
            this.setState({ newChanges: true, manifestBundles, refreshTotals: true });
        } catch (err) {
            console.error(err)
            Sentry.captureException(err);
        }
    }

    closeInterviewModal() {
        this.setState({ need: false, unfinishedQuestion: undefined, frequency: 1 })
        this.closeModal("add");
    }

    async skipLastProduct() {
        const { lastProductGroup, manifestBundles } = this.state;
        const res = await changeBundleProductQuantity(manifestBundles, lastProductGroup, 0)
        if (res.manifestBundles) {
            this.saveOrder(res.manifestBundles);
            this.setState({ newChanges: true, manifestBundles: res.manifestBundles, refreshTotals: true, lastProductGroup: undefined, tax: 0 });
        }
    }

    showOtherBrands(productType, shortName) {
        const { manifestBundles, rounderSet, productGroupsMap, viewBundle } = this.state;
        try {
            const selectedProductGroup = manifestBundles[shortName].productGroups[productType];
            const productTypeSet = rounderSet[productType].additional.map(pg => {
                return pg.groupName
            }).filter(groupName => productGroupsMap[groupName] && productGroupsMap[groupName].status === "Active");

            productTypeSet.push(rounderSet[productType].preferred.groupName);
            const selectedProductGroups = productTypeSet.map(groupName => {
                return productGroupsMap[groupName];
            }).filter(productGroup => productGroup.available === true)
            if (viewBundle) {
                appendAddProductModalNoScroll();
            } else {
                appendBodyNoScroll();
            }

            let selectedBundle = { shortName, title: bundles[shortName].title }

            this.setState({ selectedProductGroup, selectedProductGroups, selectedProductType: productType, selectedBundle });
        } catch (err) {
            console.error(err);
            // Sentry.captureException(err)
        }
    }

    selectProductGroup(groupName, cta, shortName) {
        try {
            let { productCount } = this.state;
            const { manifestBundles, productGroupsMap, customer } = this.state;
            const selectedProduct = productGroupsMap[groupName];
            const { articles, proposedArticles, cappedArticleCounts, productType } = selectedProduct;

            let refreshView;

            if (articles === undefined)
                selectedProduct.articles = proposedArticles ? proposedArticles : cappedArticleCounts.length ? cappedArticleCounts[0] : 0;

            if (!manifestBundles[shortName].productGroups[productType]) {
                productCount++;
            }

            manifestBundles[shortName].productGroups[productType] = selectedProduct;

            if (cta === "change") {
                this.closeModal(cta);
                const addModal = document.getElementById("add-products-modal");
                if (addModal)
                    refreshView = true
            }

            if (Object.values(manifestBundles[shortName].productGroups).length === bundles[shortName].productTypes.length) {
                this.closeModal("add");
                manifestBundles[shortName].hasAll = true;
                removeBodyNoScroll();
                setTimeout(() => {
                    this.setState({ viewBundle: false })
                }, 500)
            } else {
                refreshView = true;
            }
            const res = setSubtotal(manifestBundles, shortName);
            if (res.manifestBundles) {
                this.postTax(customer.shipping, res.manifestBundles)
                this.saveOrder(res.manifestBundles);
                this.setState({ manifestBundles: res.manifestBundles, refreshTotals: true, productCount });
                if (refreshView) {
                    // this ensures once a product is added, it does not continue to show in modal
                    this.viewBundleProducts(shortName);
                }
            } else {
                throw res.error;
            }
        } catch (err) {
            console.error(err)
            Sentry.captureException(err);
        }
    }

    async saveOrder(manifestBundles) {
        try {
            this.setState({ updating: true })
            const { rounderSet } = this.state;
            const order = await createOrderFromManifestBundles(manifestBundles, rounderSet);
            this.props.putManifest(order.products);
            ReactGA.event({ category: 'Manifest', action: 'Update' })
        } catch (error) {
            logger.error(error, new Error("Manifest failed to update (frontend)"))
            this.setState({ updating: false, saveOrderFailed: true })
        }
    }

    renderUnsavedQuestionModal() {
        const { unfinishedQuestion, need } = this.state;
        if (unfinishedQuestion && need) {
            const copy = setCancelText(unfinishedQuestion, need);
            return (
                <CancelModal
                    next={this.closeInterviewModal}
                    cancel={() => this.setState({ unfinishedQuestion: undefined })}
                    copy={copy}
                />
            )
        }
    }

    renderAddProductsModal() {
        const { viewBundle, addModalProductTypes, interview, rounderSet, showProduct } = this.state;
        if (viewBundle && addModalProductTypes && !showProduct) {
            return (
                <ProductsModal
                    bundleShortName={viewBundle.shortName}
                    bundleTitle={viewBundle.title}
                    cta="add"
                    close={this.closeModal}
                    handleDropdownSelect={this.handleDropdownSelect}
                    interview={interview}
                    isDashboard={true}
                    onQuantityChange={this.onQuantityChange}
                    productTypes={addModalProductTypes}
                    rounderSet={rounderSet}
                    selectProductGroup={this.selectProductGroup}
                    showDescription={this.showDescription}
                    showOtherBrands={this.showOtherBrands}
                />
            )
        }
    }

    renderCustomerName(active) {
        const { customer, isUnverified } = this.state;

        if (customer && customer.firstName && !!active === !!isUnverified) {
            return (
                <Fragment>
                    <BannerSpacing show={isUnverified} />
                    <HeaderSection id="sd-customer-name-section" isUnverified={isUnverified}>
                        <HeaderContainer>
                            <Header>Welcome {customer.firstName}</Header>
                        </HeaderContainer>
                    </HeaderSection>
                </Fragment>
            )
        }
    }

    renderBundles() {
        const {
            coupon,
            customer,
            employer,
            isEmployeePerk,
            isMobile,
            isUnverified,
            manifestBundles,
            newCoupon,
            referralLink,
            rounderSet,
            sdCredit,
            tax,
        } = this.state;

        if (manifestBundles && rounderSet) {
            try {
                const rows = Object.entries(manifestBundles).map(entry => {
                    const shortName = entry[0]
                    const { title } = bundles[shortName];
                    const { productGroups, subtotal, hasAll } = entry[1];
                    return (
                        <ManifestBundle
                            title={title}
                            bundleShortName={shortName}
                            productGroups={Object.values(productGroups)}
                            rounderSet={rounderSet}
                            onQuantityChange={this.onQuantityChange}
                            showOtherBrands={this.showOtherBrands}
                            hasAllBundleTypes={hasAll}
                            showDescription={this.showDescription}
                            selectProductGroup={this.selectProductGroup}
                            subtotal={subtotal}
                            isDashboard={true}
                            isMobile={isMobile}
                            viewBundleProducts={() => this.viewBundleProducts(shortName)}
                            key={entry}
                        />
                    )
                })
                if (isMobile) {
                    return rows;
                } else {
                    return (
                        <Fragment>
                            <BannerSpacing show={isUnverified} />
                            <Body withBanner={isUnverified}>
                                <div className="desktop-bundle-container">
                                    <Wrapper>
                                        {this.renderHeader(false)}
                                        {this.renderCadenceAlert(false)}
                                    </Wrapper>
                                    {rows}
                                </div>
                                <OrderSummary
                                    manifestBundles={manifestBundles}
                                    coupon={coupon}
                                    tax={tax}
                                    newCoupon={newCoupon}
                                    isEmployeePerk={isEmployeePerk}
                                    employer={employer}
                                    sdCredit={sdCredit}
                                    showReferral={referralLink ? true : false}
                                    chargeShipping={customer.chargeShipping}
                                />
                            </Body>
                        </Fragment>
                    )
                }
            }
            catch (err) {
                console.error(err)
            }
        }
    }

    async updateInterview(data) {
        const { need, interview } = this.state;
        const res = await handleNewInterviewData(need, data, interview)
        if (res.interview) {
            this.props.putInterview(res.interview);
            this.props.getRounder(res.interview)
            if (need !== "needsKitchen" || need !== "needsLaundry") {
                removeAddProductModalNoScroll();
            }
            this.setState({ need: undefined, interview: res.interview, newRounder: true, frequency: 1 })
        } else {
            this.setState({ need: undefined, frequency: 1 })
        }
    }

    showDescription(groupName) {
        try {
            const { productGroupsMap } = this.state;
            const showProduct = productGroupsMap[groupName];
            this.setState({ showProduct })
        } catch (error) {
            Sentry.captureException(error)
            console.error(error);
        }
    }

    viewBundleProducts(shortName) {
        try {
            const { interview } = this.state;
            if (shortName === "kitchen" && interview.housekeeping === undefined) {
                this.setState({ need: "needsKitchenFlow" })
            } else if (shortName === "laundry" && (!interview.laundry || !interview.laundry.frequency)) {
                this.setState({ need: "needsLaundry" });
            }
            appendBodyNoScroll();
            this.setViewBundle(shortName);
        } catch (err) {
            console.error(err);
            // Sentry.captureException(err);
        }
    }

    async setViewBundle(shortName) {
        let { viewBundle, productGroupsMap, manifestBundles, rounderSet } = this.state;

        if (!viewBundle)
            viewBundle = { shortName }

        const productTypes = await getBundleProducts(shortName, productGroupsMap, manifestBundles, rounderSet);
        viewBundle.title = bundles[shortName].title;

        this.setState({ viewBundle, addModalProductTypes: productTypes })
    }

    closeModal(cta) {
        const { viewBundle, selectedProductType } = this.state;
        const element = document.getElementById(`${cta}-products-modal`);
        element.classList.add("slideOutRight");
        if (selectedProductType) {
            if (viewBundle) {
                removeAddProductModalNoScroll();
            } else {
                removeBodyNoScroll();
            }
            setTimeout(() => {
                let selectedProductGroup, selectedProductGroups, selectedProductType, selectedBundle = undefined;
                this.setState({ selectedProductGroup, selectedProductGroups, selectedProductType, selectedBundle })
            }, 500)
        } else {
            removeBodyNoScroll();
            setTimeout(() => {
                this.setState({ viewBundle: false })
            }, 500)
        }
    }

    handleKeydown(e) {
        const {
            viewBundle,
            selectedProductType,
            showProduct,
            addModalProductTypes,
            showCadenceModal
        } = this.state;

        if (e.keyCode && e.keyCode === 27) {
            if (selectedProductType && !showProduct) {
                this.closeModal("change")
            }
            if (viewBundle && addModalProductTypes && !showProduct && !selectedProductType) {
                this.closeModal("add")
            }
            if (showCadenceModal)
                this.setState({ showCadenceModal: false })
        }
    }

    handleClickOutside(e) {
        const onClickId = e.target.id
        if (onClickId === "change-products-modal-container") {
            this.closeModal("change")
        }
        if (onClickId === "add-products-modal-container") {
            this.closeModal("add")
        }
    }

    setUnsaved(unfinishedQuestion) {
        const { need } = this.state;
        if (!unfinishedQuestion) {
            const question = need.replace("needs", "");
            this.setState({ unfinishedQuestion: question })
        } else {
            this.setState({ unfinishedQuestion })
        }
    }

    renderProductDetail() {
        const { showProduct } = this.state;
        if (showProduct) {
            //            window.removeEventListener('keydown', this.handleKeydown, false);
            return <ProductDetail productGroup={showProduct} close={() => this.setState({ showProduct: undefined })} />
        }
    }

    renderHeader(isMobile) {
        const { manifest } = this.state;
        if (manifest && isMobile === this.state.isMobile) {
            const date = moment(manifest.deliverAt).format('MMMM Do')
            return <div className="dashboard-header">Here's your next delivery for {date}</div>
        }
    }

    renderCadenceAlert(isMobile) {
        const { cadenceMonths } = this.state;
        if (cadenceMonths && isMobile === this.state.isMobile)
            return <CadenceAlert title={cadenceMonths.title} months={cadenceMonths.int} onclick={() => this.setState({ showCadenceModal: true })} />
    }

    renderLoadingModal() {
        const { loading } = this.state;
        if (loading) {
            return (
                <Loading />
            )
        }
    }

    renderChangeProductsModal() {
        const { selectedProductGroup, selectedProductGroups, selectedProductType, selectedBundle, showProduct } = this.state;
        if (selectedProductType && !showProduct) {
            const formattedType = formatProductType(selectedProductType)

            return (
                <ProductsModal
                    bundleShortName={selectedBundle.shortName}
                    bundleTitle={selectedBundle.title}
                    close={this.closeModal}
                    cta="change"
                    formattedProductType={formattedType}
                    isDashboard={true}
                    onQuantityChange={this.onQuantityChange}
                    productGroups={selectedProductGroups}
                    selectedProductGroup={selectedProductGroup}
                    selectProductGroup={this.selectProductGroup}
                    showDescription={this.showDescription}
                />
            )
        }
    }

    renderAdditionalQuestions() {
        const { need, showInfo, interview, unfinishedQuestion } = this.state;
        if (!showInfo && !unfinishedQuestion && need) {
            if (need === "needsKitchenFlow") {
                return (
                    <KitchenFlow
                        history={this.props.history}
                        updateInterview={this.updateInterview}
                        interview={interview}
                        close={this.setUnsaved}
                        clearUnsavedChanges={() => this.setState({ unsavedChanges: false })}
                    />
                )
            } else {
                return <InterviewQuestionModal need={need} next={this.updateInterview} back={this.setUnsaved} close={this.setUnsaved} history={this.props.history} />
            }
        }
    }

    renderStatus() {
        const { updating, saveOrderFailed } = this.state;
        const { putManifestError, putManifestSuccess } = this.props;
        if (updating || saveOrderFailed || putManifestError || putManifestSuccess) {
            const vertical = 'bottom', horizontal = 'center';
            return (
                <Snackbar
                    style={{ marginBottom: 90 }}
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical, horizontal }}
                    open={true}
                    key={'snackbar-' + vertical + horizontal}
                >
                    {this.renderOrderStatusAlert()}
                </Snackbar>
            )
        }
    }

    renderOrderStatusAlert() {
        const { updating, saveOrderFailed } = this.state;
        const { putManifestError, putManifestSuccess } = this.props;

        if (updating) {
            return (
                <Alert severity="info" role="alert">
                    Saving...
                </Alert>
            )
        } else if (saveOrderFailed || putManifestError) {
            return (
                <Alert severity="error" role="alert">
                    Order could not be saved
                </Alert>
            )
        } else if (putManifestSuccess) {
            return (
                <Alert severity="success" role="alert">
                    Order saved
                </Alert>
            )
        }
    }

    renderNoEmptyManifestModal() {
        const { lastProductGroup } = this.state;
        if (lastProductGroup) {
            const body = (
                <p>
                    You are unable to remove all products from a delivery.
                    <br /><br />
                    Would you like to skip this delivery instead?
                </p>
            )
            return <AlertModal header="Uh oh, you need at least 1 product" confirmText="Yes, skip this delivery" body={body} next={this.skipLastProduct} cancel={() => this.setState({ lastProductGroup: undefined })} />
        }
    }

    renderPhoneBanner() {
        const { isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && customer && !limitExceeded) {
            return <PhoneBanner next={() => this.setState({ showVerificationModal: true })} style={{ marginBottom: 100 }} />
        }
    }

    renderPhoneModal() {
        const { showVerificationModal, isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && showVerificationModal && customer && !limitExceeded) {
            return (
                <PhoneVerification
                    isModal={true}
                    limitExceeded={() => this.setState({ limitExceeded: true, showVerificationModal: false })}
                    email={customer.email}
                    phone={customer.phone}
                    setToVerified={() => this.setState({ isUnverified: false })}
                    close={() => this.setState({ showVerificationModal: false })}
                />
            )
        }
    }

    renderCadenceModal() {
        const { cadenceMonths, showCadenceModal } = this.state;
        if (showCadenceModal && cadenceMonths) {
            const { int } = cadenceMonths;
            return (
                <CadenceModal close={() => this.setState({ showCadenceModal: false })} cadenceMonths={int} />
            )
        }
    }

    renderSkipAll() {
        return <div className="link skip-all-link" onClick={this.setAllToZero}>Skip this delivery</div>
    }

    renderCustomerReferral() {
        const { isUnverified, limitExceeded, isMobile, referralLink } = this.state;
        if (referralLink) {
            return (
                <CustomerReferral
                    isMobile={isMobile}
                    isUnverified={isUnverified}
                    limitExceeded={limitExceeded}
                    referralLink={referralLink}
                />
            )
        }
    }

    render() {
        const {
            manifestBundles,
            refreshTotals,
            tax,
            isMobile,
            isUnverified,
            dashboardData,
            newCoupon,
            isEmployeePerk,
            employer,
            sdCredit,
            referralLink
        } = this.state;
        return (
            <div className="no-cloud">
                <MetaTags title="Supply Drop - Dashboard" />
                {this.renderProductDetail()}
                {this.renderChangeProductsModal()}
                {this.renderAdditionalQuestions()}
                {this.renderNoEmptyManifestModal()}
                {this.renderUnsavedQuestionModal()}
                {this.renderAddProductsModal()}
                {this.renderCadenceModal()}
                {this.renderPhoneModal()}
                <Menu history={this.props.history} dashboardData={{ ...dashboardData, verified: !isUnverified }} />
                {this.renderCustomerName(false)}
                <div className="dashboard-body-container next-delivery">
                    {this.renderPhoneBanner()}
                    {this.renderCustomerName(true)}
                    {this.renderCustomerReferral()}
                    {this.renderHeader(true)}
                    {this.renderCadenceAlert(true)}
                    {this.renderBundles()}
                    {this.renderLoadingModal()}
                    {this.renderStatus()}
                    <FixedContinueBtn
                        isDashboard={true}
                        manifestBundles={manifestBundles}
                        skipAll={this.renderSkipAll()}
                        refreshTotals={refreshTotals}
                        newCoupon={newCoupon}
                        isEmployeePerk={isEmployeePerk}
                        employer={employer}
                        tax={tax}
                        isMobile={isMobile}
                        sdCredit={sdCredit}
                        showReferral={referralLink ? true : false}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError, magicLink, magicLinkErr, gettingMagicLink } = state.user;
    const { productGroups, productGroupsError } = state.product;
    const { signedInAuthErr, nonSignedInAuthErr } = state.globalErrors;
    const { putInterviewError, puttingInterview, putInterviewSuccess, rounder, gettingRounder, rounderError } = state.interview;
    const { manifest, getManifestError, putManifestError, putManifestSuccess, updating, tax, getTaxError, gettingTax } = state.order;
    return {
        user,
        manifest,
        getManifestError,
        putManifestError,
        putManifestSuccess,
        getUserError,
        updating,
        productGroups,
        productGroupsError,
        putInterviewError,
        puttingInterview,
        putInterviewSuccess,
        tax,
        getTaxError,
        gettingTax,
        rounder,
        gettingRounder,
        rounderError,
        signedInAuthErr,
        nonSignedInAuthErr,
        magicLink,
        magicLinkErr,
        gettingMagicLink
    };
}

export default connect(mapStateToProps, {
    getUser,
    getManifest,
    putManifest,
    clearOrdersState,
    clearCustomerState,
    getProductGroups,
    putInterview,
    clearInterviews,
    getTax,
    setEmptyTax,
    getRounder,
    createGlobalAuthError,
    getMagicLink
})(Dashboard);
