import React from 'react';
import * as images from '../../../assets';

export const Loading = () =>
{
    return (
        <svg className="loading-icon" xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 200 200">
            <clipPath id="loading-icon-clip-path">
                <circle r="95"></circle>
            </clipPath>
            <circle className="loading-icon__background" r="95" fill="#f3f3f7"></circle>
            <circle className="loading-icon__animated-stroke" clipPath="url(#loading-icon-clip-path)" r="100" fill="none" strokeWidth="20px" stroke="#aaaaaa"></circle>
            <circle className="loading-icon__static-stroke-section" clipPath="url(#loading-icon-clip-path)" r="100" fill="none" strokeWidth="20px" stroke="#aaaaaa"></circle>
            <image href={images.parachute}  x="-50" y="-50" width="100" height="100" opacity="1" className="grow"></image>
        </svg>
    )
}
