import React from 'react';
import * as images from '../../../assets';

export const ManifestBundleHeader = ({
    title,
    bundleShortName,
    subtotal,
    onHeaderClick,
    hideBundle,
    roundedBottom,
    isDetailsView,
    noMargin
}) => {
    const renderSubtext = () => {
        if(subtotal) {
            return (
                <div className="bundle-header-subtotal">Subtotal: ${(subtotal/100).toFixed(2)}</div>
            )
        } else {
            return (
                <div className="bundle-header-unincluded">
                    Not part of your plan.<br/>Would you like to add?
                </div>
            )
        }
    }

    const renderImg = () => {
        if(subtotal) {
            return (
                <div className="bundle-header-col">
                    <div className={`fa ${!hideBundle ? "fa-chevron-up" : "fa-chevron-down"}`} />
                </div>
            )
        } else {
            return (
                <div className="bundle-header-col">
                    <img src={images.add_bundle} alt={`add ${title}`} className="add-bundle-img" />
                </div>
            )
        }
    }

    return (
        <div className={`bundle-header ${bundleShortName}-bundle-header${roundedBottom ? " rounded-bottom" : ""}${isDetailsView ? " details-view" : ""}${noMargin && hideBundle ? " no-margin" : ""}`} onClick={onHeaderClick}>
            <div className="bundle-header-left">
                <img src={images[`${bundleShortName}_bundle_icon`]} alt={title} className="bundle-header-img" />
                <div className="bundle-header-col">
                    <div className="bundle-header-title">{title}</div>
                    {renderSubtext()}
                </div>
            </div>
            {renderImg()}
        </div>
    )
}