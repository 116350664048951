import { Auth } from 'aws-amplify';
import { parseSearchParams } from '../../actions';

export function signIn(searchString, history) {
    return new Promise((resolve, reject) => {
        try {
            const { searchData } = searchString ? parseSearchParams(searchString) : { searchData: null };
            validateCurrentAuthenticatedUser()
                .then(data => {
                    if (searchData && searchData.u)
                        redirectWithoutMagiclink(searchData, history)

                    resolve(data)
                })
                .catch(err => {
                    if (searchData) {
                        const { u, c, s, employer } = searchData;
                        if (u) {
                            signInFromParams(u, c, s)
                                .then(data => {
                                    if (u)
                                        redirectWithoutMagiclink(searchData, history)
                                        
                                    resolve(data)
                                })
                                .catch(err => reject(err))
                        } else if (employer) {
                            validateCurrentAuthenticatedUser()
                                .then(data => resolve(data))
                                .catch(userErr => reject(userErr))
                        } else {
                            // missing auth params
                            reject({ error: new Error('Expected `u` param') });
                        }
                    } else {
                        reject(err);
                    }
                })
        } catch (error) {
            error.name = 'AuthError';
            reject({ error })
        }
    })
}

function signInFromParams(u, c, s) {
    return new Promise((resolve, reject) => {
        Auth.configure({
            authenticationFlowType: 'CUSTOM_AUTH'
        });

        const magicLinkParams = { u, s, channel: s === "e" ? "email" : "sms" };

        Auth.signIn(u).then(user => {
            Auth
                .sendCustomChallengeAnswer(user, c)
                .then(data => {
                    resolve(data)
                }).catch(err => {
                    reject({ error: err, magicLinkParams });
                })
        }).catch(error => {
            // expired magiclink
            reject({ error, magicLinkParams });
        })
    })
}

function validateCurrentAuthenticatedUser() {
    return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser().then(data => {
            resolve(data);
        }).catch(error => {
            reject({ error });
        })
    })
}

function redirectWithoutMagiclink(searchData, history) {
    const employer = searchData ? searchData.employer : null;
    const search = employer ? `employer=${employer}` : "";
    const { pathname } = window.location;
    history.push(pathname, { search });
}
