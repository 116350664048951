import {
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    GETTING_USER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    UPDATING_CUSTOMER,
    CLEAR_CUSTOMER_STATE,
    CLEAR_CUSTOMER,
    UPDATING_SOURCE,
    UPDATE_SOURCE_SUCCESS,
    UPDATE_SOURCE_FAIL,
    NEW_MAGIC_LINK_FAIL,
    NEW_MAGIC_LINK_SUCCESS,
    GETTING_MAGIC_LINK,
    USER_DOESNT_EXIST,
    POST_PROSPECT_SUCCESS,
    POST_PROSPECT_FAIL,
    POSTING_PROSPECT,
    ENROLL_CUSTOMER_SUCCESS,
    ENROLL_CUSTOMER_ERROR,
    ENROLLING_CUSTOMER
} from '../actions/types';
import { Logger } from '../helpers/Logger';
const logger = new Logger();

const INITIAL_STATE = {
    getUserError: '',
    user: undefined,
    updatingCustomer: undefined,
    updateCustomerSuccess: undefined,
    updateCustomerError: undefined,
    updatingSource: undefined,
    putSourceSuccess: undefined,
    putSourceError: undefined,
    magicLink: undefined,
    magicLinkErr: undefined,
    gettingMagicLink: false,
    noUser: false
};

export default (state = INITIAL_STATE, action) => {
    // Leaving this here to track redux actions in dev environments
    logger.log(action.type)
    switch (action.type) {
        case GET_USER_SUCCESS:
            return { ...state, user: action.payload, getUserError: '' };
        case GET_USER_FAIL:
            return { ...state, getUserError: action.error };
        case GETTING_USER:
            return { ...state, getUserError: undefined, user: undefined };
        case UPDATING_CUSTOMER:
            return { ...state, updatingCustomer: true, updateCustomerSuccess: undefined, updateCustomerError: undefined };
        case UPDATE_CUSTOMER_SUCCESS:
            return { ...state, updatingCustomer: undefined, updateCustomerSuccess: action.payload, updateCustomerError: undefined };
        case UPDATE_CUSTOMER_FAIL:
            return { ...state, updatingCustomer: undefined, updateCustomerSuccess: undefined, updateCustomerError: action.error };
        case UPDATING_SOURCE:
            return { ...state, updatingSource: true, putSourceSuccess: undefined, putSourceError: undefined };
        case UPDATE_SOURCE_SUCCESS:
            return { ...state, updatingSource: undefined, putSourceSuccess: action.payload, putSourceError: undefined };
        case UPDATE_SOURCE_FAIL:
            return { ...state, updatingSource: undefined, putSourceSuccess: undefined, putSourceError: action.error };
        case NEW_MAGIC_LINK_FAIL:
            return { ...state, magicLinkErr: action.payload, magicLink: undefined, gettingMagicLink: false };
        case NEW_MAGIC_LINK_SUCCESS:
            return { ...state, magicLinkErr: undefined, magicLink: action.payload, gettingMagicLink: false };
        case GETTING_MAGIC_LINK:
            return { ...state, magicLinkErr: undefined, magicLink: undefined, gettingMagicLink: true, noUser: false };
        case USER_DOESNT_EXIST:
            return { ...state, noUser: true, gettingMagicLink: false }
        case POST_PROSPECT_SUCCESS:
            return { ...state, postingProspect: false, postProspectSuccess: action.payload, postProspectError: undefined }
        case POST_PROSPECT_FAIL:
            return { ...state, postingProspect: false, postProspectSuccess: undefined, postProspectError: action.error }
        case POSTING_PROSPECT:
            return { ...state, postingProspect: true, postProspectSuccess: undefined, postProspectError: undefined }
        case ENROLL_CUSTOMER_SUCCESS:
            return { ...state, enrollingCustomer: false, enrollCustomerSuccess: action.payload, enrollCustomerError: undefined }
        case ENROLL_CUSTOMER_ERROR:
            return { ...state, enrollingCustomer: false, enrollCustomerSuccess: undefined, enrollCustomerError: action.error }
        case ENROLLING_CUSTOMER:
            return { ...state, enrollingCustomer: true, enrollCustomerSuccess: undefined, enrollCustomerError: undefined }
        case CLEAR_CUSTOMER_STATE:
            return {
                ...state,
                updatingCustomer: undefined,
                updateCustomerFail: undefined,
                updateCustomerSuccess: undefined,
                updatingSource: undefined,
                updateSourceSuccess: undefined,
                updateSourceError: undefined,
                magicLink: undefined,
                magicLinkErr: undefined,
                gettingMagicLink: false,
                noUser: false,
                enrollingCustomer: false,
                enrollCustomerSuccess: undefined,
                enrollCustomerError: undefined
            }
        case CLEAR_CUSTOMER:
            return {
                ...state,
                updatingCustomer: undefined,
                updateCustomerFail: undefined,
                updateCustomerSuccess: undefined,
                updatingSource: undefined,
                updateSourceSuccess: undefined,
                updateSourceError: undefined,
                user: undefined,
                getUserError: undefined,
                magicLink: undefined,
                magicLinkErr: undefined,
                gettingMagicLink: false,
                noUser: false,
                enrollingCustomer: false,
                enrollCustomerSuccess: undefined,
                enrollCustomerError: undefined
            }
        default:
            return state;
    }
};