import React from 'react';
import { Alert } from 'react-bootstrap';
import { featureFlags } from '../../../config';

export const OutageBanner = () => {
    if (featureFlags.block_signups) {
        return (
            <Alert variant="warning" className="text-center" id="outage-banner">
                We're currently experiencing technical difficulties, functionality on the website may not be available or may be slow. Please reach out to us at care@supplydrop.com if you have questions about your order.
            </Alert>
        )
    } else {
        return <div />
    }
}