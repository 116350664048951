import React, { Component } from 'react';
import '../../../Style/manifest-bundle.css';
import { BundleProduct, ManifestBundleHeader } from '../';
import { sortEveryday } from '../../../actions';

export class ManifestBundle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    renderHeader() {
        const { hideBundle } = this.state;
        const { title, bundleShortName, subtotal, viewBundleProducts, hasAllBundleTypes } = this.props;
        return (
            <ManifestBundleHeader
                title={title}Î
                bundleShortName={bundleShortName}
                subtotal={subtotal}
                viewBundleProducts={viewBundleProducts}
                hideBundle={hideBundle}
                onHeaderClick={subtotal ? () => this.setState({ hideBundle: !hideBundle }) : viewBundleProducts}
                roundedBottom={hasAllBundleTypes && hideBundle}
                noMargin={hasAllBundleTypes}
            />
        )
    }

    renderAddProductsBtn() {
        const { hideBundle } = this.state;
        const { view, title, bundleShortName, productGroups, viewBundleProducts, hasAllBundleTypes } = this.props;
        if (!hasAllBundleTypes && view !== "complete") {
            const hasProducts = Object.entries(productGroups).length;
            return (
                <div className={`bundle-footer-btn-container${hideBundle || !hasProducts ? " negative-margin" : ""}`} onClick={viewBundleProducts}>
                    <button className={`bundle-footer-btn ${bundleShortName}-footer-btn`}>Add {hasProducts ? "more " : ""}{title}</button>
                </div>
            )
        }
    }

    renderProducts() {
        const { hideBundle } = this.state;
        const { view, showOtherBrands, rounderSet, showDescription, onQuantityChange, bundleShortName, selectProductGroup, isDashboard } = this.props;
        let { productGroups } = this.props;
        if (!hideBundle && view !== "complete") {
            const rows = [];
            if (bundleShortName === "everyday") {
                productGroups = sortEveryday(productGroups);
            }
            const { length } = productGroups;
            for (let i = 0; i < length; i++) {
                const last = i === length - 1;
                const productGroup = productGroups[i];
                const {
                    groupName,
                    productType
                } = productGroup
                const onlyOffering = !Object.values(rounderSet[productType].additional).length;
                rows.push(
                    <BundleProduct
                        key={groupName}
                        productGroup={productGroup}
                        view={view}
                        bundleShortName={bundleShortName}
                        showDescription={showDescription}
                        onlyOffering={onlyOffering}
                        onQuantityChange={onQuantityChange}
                        showRemove={true}
                        last={last}
                        showOtherBrands={() => showOtherBrands(productType, bundleShortName)}
                        selectProductGroup={selectProductGroup}
                        isDashboard={isDashboard}
                    />
                )
                if (!last)
                    rows.push(<hr key={"hr of "+ groupName}/>)
            }
            return rows;
        }
    }

    renderOrderCompleteProducts() {
        const { hideBundle } = this.state;
        const { view, productGroups, showDescription, bundleShortName, selectProductGroup, isDashboard } = this.props;
        if (!hideBundle && view === "complete") {
            const rows = [];
            const length = Object.entries(productGroups).length;
            for (let i = 0; i < length; i++) {
                const last = i === length - 1;
                const productGroup = productGroups[i];
                const { groupName } = productGroup

                rows.push(
                    <BundleProduct
                        key={groupName}
                        productGroup={productGroup}
                        view={view}
                        bundleShortName={bundleShortName}
                        showDescription={showDescription}
                        showRemove={true}
                        last={last}
                        selectProductGroup={selectProductGroup}
                        isDashboard={isDashboard}
                    />
                )
                if (!last)
                    rows.push(<hr />)
            }
            return rows;
        }
    }

    render() {
        const { bundleShortName, isMobile, isDashboard } = this.props;
        return (
            <div className={`bundle${isDashboard && isMobile ? " margin-auto" : ""}`} id={bundleShortName ? `${bundleShortName}-bundle-container` : ""}>
                {this.renderHeader()}
                {this.renderProducts()}
                {this.renderAddProductsBtn()}
                {this.renderOrderCompleteProducts()}
            </div>
        )
    }
}