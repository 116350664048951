export const user = {
    allowedDeliverDates: ["2020-05-26", "2020-05-27", "2020-05-28", "2020-05-29", "2020-06-01", "2020-06-02", "2020-06-03"],
    customer: {
        firstName: "FirstName",
        lastName: "LastName",
        email: "mockdata@supplydrop.com",
        billing: {
            address: "301 Chicon St, Unit B",
            city: "Austin",
            name: "FirstName LastName",
            state: "TX",
            zipcode: "78702"
        },
        shipping: {
            address: "301 Chicon St, Unit B",
            city: "Austin",
            name: "FirstName LastName",
            state: "TX",
            zipcode: "78702",
            deliveryInstructions: "Delivery Instructions"
        },
        status: "Active",
        phone: "+15125550100",
        nickname: "Mock User",
        interview: {
            bathrooms: 1,
            cookingFrequency: 1,
            dishwashingFrequency: 1,
            dishwashingProducts: [],
            family: [{ gender: "Male", age: 26, staysHome: false }],
            housekeepingFrequency: 1,
            laundryFrequency: 1,
            laundryPreferences: [],
            pets: { Puppy: 0, Kitten: 0, Dog: 0, Cat: 0, Other: 0 },
            scentPreferences: [],
            tierPreferences: ["SocialGood"],
            trash: [{ type: "Kitchen", bags: 1, frequency: 1 }, { type: "Outdoor", bags: 1, frequency: 1 }],
            trashPickups: 1
        },
        paymentSource: {
            id: "src_mockData",
            brand: "Visa",
            expirationMonth: 4,
            expirationYear: 2044,
            last4: "4242"
        }
    },
    manifest: {
        contactAt: "2020-04-27",
        deliverAt: "2020-05-01",
        modified: true,
        salesTaxCents: 266,
        shipAt: "2020-04-30",
        status: "Predicted",
        viewed: true,
        products: {
            DishSoap: {
                articleCount: 1,
                groupName: "dish-soap-palmolive-ultra-strength-original-scent",
                moreThanAMonth: false,
                productChangeReason: "None",
                quantityChangeReason: "None"
            },
            ToiletPaper: {
                articleCount: 8,
                groupName: "toilet-paper-caboo-super-soft-bamboo-double-rolls",
                moreThanAMonth: false,
                productChangeReason: "TooExpensive",
                quantityChangeReason: "None"
            },
            PaperTowels: {
                articleCount: 4,
                groupName: "paper-towels-caboo-sustainable-bamboo",
                moreThanAMonth: false,
                productChangeReason: "None",
                quantityChangeReason: "None"
            },
            SurfaceCleaner: {
                articleCount: 0,
                groupName: "surface-cleaner-clorox-clean-up-and-bleach-original",
                moreThanAMonth: false,
                productChangeReason: "TooExpensive",
                quantityChangeReason: "None"
            }
        }
    },
    roundedProducts: {
        productSets: {
            DishSoap: {
                additional: [{
                    groupName: "dish-soap-seventh-generation-free-&-clear",
                    proposedArticles: 1
                }],
                preferred: {
                    groupName: "dish-soap-puracy-natural-green-tea-and-lime",
                    proposedArticles: 1
                }
            },
            ToiletPaper: {
                additional: [
                    {
                        groupName: "toilet-paper-charmin-ultra-strong-mega",
                        proposedArticles: 6
                    },
                    {
                        groupName: "toilet-paper-angel-soft-mega-rolls",
                        proposedArticles: 6
                    }
                ],
                preferred: {
                    groupName: "toilet-paper-caboo-super-soft-bamboo-double-rolls",
                    proposedArticles: 8
                }
            },
            PaperTowels: {
                additional: [
                    {
                        groupName: "paper-towels-bounty-select-a-size-double",
                        proposedArticles: 6
                    },
                    {
                        groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                        proposedArticles: 3
                    }
                ],
                preferred: {
                    groupName: "paper-towels-caboo-sustainable-bamboo",
                    proposedArticles: 4
                }
            },
            SurfaceCleaner: {
                additional: [
                    {
                        groupName: "surface-cleaner-method-all-purpose-french-lavender",
                        proposedArticles: 1
                    },
                    {
                        groupName: "disinfecting-wipes-clorox-crisp-lemon",
                        proposedArticles: 70
                    }
                ],
                preferred: {
                    groupName: "surface-cleaner-puracy-natural-multi-surface-green-tea-and-lime",
                    proposedArticles: 1
                }
            }
        }
    }
}

export const orders = [
    {
        items: [
            {
                amount: 546,
                currency: "usd",
                description: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
                groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                parent: "sku_GRJnwcjPHdWdg2",
                quantity: 3,
                type: "sku"
            },
            {
                amount: 672,
                currency: "usd",
                description: "Toilet Paper, Cottonelle, 6 Rolls, Ultra CleanCare Mega Rolls",
                groupName: "toilet-paper-cottonelle-ultra-clean-care-mega",
                parent: "sku_GRJXEhMTKL0eCy",
                quantity: 6,
                type: "sku"
            },
            {
                amount: 222,
                currency: "usd",
                description: "Sales tax",
                type: "tax"
            }
        ],
        order: {
            status: "PaymentSucceeded",
            type: "Initial",
            createdAt: "2020-04-01T13:43:07.958Z",
            expectedDeliveryDate: "2020-04-03",
            expectedShipDate: "2020-04-02",
            orderId: "1",
            products: [
                {
                    articleType: "Roll",
                    articlesPerPackage: 6,
                    badges: ["NationallyRecognizedBrandLead", "SupplyDropPick", "SensitiveSkin"],
                    brand: "Cottonelle",
                    description: "Cottonelle CleanCare toilet paper’s Cleaning Ripples provide texture for an ultra clean feel while providing exceptional softness and comfort.",
                    displayName: "Ultra CleanCare Mega",
                    group: "toilet-paper-cottonelle-ultra-clean-care-mega",
                    groupName: "toilet-paper-cottonelle-ultra-clean-care-mega",
                    imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/6QezIhqbPm9FqYHaDh6v0m/b3a27419f41c0192a98483d908c21d74/cottonelle-cleancare-1248.png",
                    packages: 1,
                    pricePerArticle: 112,
                    productId: "8f6a8067-e7c9-f105-b1e6-c8a98920b4a8",
                    purchaseDescription: "Toilet Paper, Cottonelle, 6 Rolls, Ultra CleanCare Mega Rolls",
                    stripePriceCents: 112,
                    type: "ToiletPaper"
                },
                {
                    articleType: "Roll",
                    articlesPerPackage: 1,
                    badges: ["NationallyRecognizedBrandLead", "SensitiveSkin", "SupplyDropPick"],
                    brand: "Viva",
                    description: "Soft and durable, Signature Cloth Choose a Sheet Viva paper towels have a texture that sets them apart from other paper towels with the strength to tackle any size job.",
                    displayName: "Signature Cloth Choose a Sheet Double",
                    group: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                    groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                    imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/3rmlCZxwXuMj6NxTKmORhp/f2a6960b0cbf834305a3544d5952f2e3/viva-signature-cloth-1-2.png",
                    packages: 3,
                    pricePerArticle: 182,
                    productId: "cbf23dcf-008d-4f0a-60ca-dd65244337f6",
                    purchaseDescription: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
                    stripePriceCents: 182,
                    type: "PaperTowels"
                }
            ]
        }
    },
    {
        items: [
            {
                amount: 546,
                currency: "usd",
                description: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
                groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                parent: "sku_GRJnwcjPHdWdg2",
                quantity: 3,
                type: "sku"
            },
            {
                amount: 222,
                currency: "usd",
                description: "Sales tax",
                type: "tax"
            }
        ],
        order: {
            status: "PaymentSucceeded",
            type: "Recurring",
            createdAt: "2020-04-01T13:43:07.958Z",
            expectedDeliveryDate: "2020-05-03",
            expectedShipDate: "2020-05-02",
            orderId: "2",
            products: [
                {
                    articleType: "Roll",
                    articlesPerPackage: 1,
                    badges: ["NationallyRecognizedBrandLead", "SensitiveSkin", "SupplyDropPick"],
                    brand: "Viva",
                    description: "Soft and durable, Signature Cloth Choose a Sheet Viva paper towels have a texture that sets them apart from other paper towels with the strength to tackle any size job.",
                    displayName: "Signature Cloth Choose a Sheet Double",
                    group: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                    groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                    imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/3rmlCZxwXuMj6NxTKmORhp/f2a6960b0cbf834305a3544d5952f2e3/viva-signature-cloth-1-2.png",
                    packages: 3,
                    pricePerArticle: 182,
                    productId: "cbf23dcf-008d-4f0a-60ca-dd65244337f6",
                    purchaseDescription: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
                    stripePriceCents: 182,
                    type: "PaperTowels"
                }
            ]
        }
    }
]

export const initOrder = {
    "stripeOrderId": "or_1GkaxwEIlpm44XfDtAcwOjPP",
    "stripeCustomerId": "cus_HJDOHvc21Fzmq0",
    "emittedSyncMessage": false,
    "orderId": "c9d852f3-fe2c-4af7-884a-f4682dcf048c",
    "stripeItems": [
        {
            "description": "Trash Bags, Glad 40 bags, 13 gallon, ForceFlex Tall Kitchen, Drawstring",
            "quantity": 40,
            "type": "sku",
            "amount": 1000,
            "currency": "usd",
            "parent": "sku_G4jwdnZ4rMM8ym",
            "groupName": "trash-bags-kitchen-glad-forceflex-drawstring"
        },
        {
            "description": "Facial Tissue, Kleenex, 60 Sheets, Cube, Anti-Viral",
            "quantity": 1,
            "type": "sku",
            "amount": 291,
            "currency": "usd",
            "parent": "sku_HDW2K0R4XwKGsr",
            "groupName": "facial-tissue-kleenex-anti-viral"
        },
        {
            "description": "Hand Sanitizer, LXR Biotech, 1.93 oz.",
            "quantity": 1,
            "type": "sku",
            "amount": 575,
            "currency": "usd",
            "parent": "sku_H8lOQPoRJ3coNu",
            "groupName": "hand-sanitizer-lxr-biotech-advanced-spray"
        },
        {
            "description": "Paper Towels, 2 Rolls, 75 Sheets, Bamboo",
            "quantity": 4,
            "type": "sku",
            "amount": 1180,
            "currency": "usd",
            "parent": "sku_Fv38kSTcWDj1vf",
            "groupName": "paper-towels-caboo-sustainable-bamboo"
        },
        {
            "description": "Toilet Paper, Cottonelle, 6 Rolls, Ultra CleanCare Mega Rolls",
            "quantity": 6,
            "type": "sku",
            "amount": 672,
            "currency": "usd",
            "parent": "sku_GRJXEhMTKL0eCy",
            "groupName": "toilet-paper-cottonelle-ultra-clean-care-mega"
        },
        {
            "description": "Sales tax",
            "type": "tax",
            "amount": 307,
            "currency": "usd"
        },
        {
            "description": "Free shipping",
            "type": "shipping",
            "amount": 0,
            "currency": "usd",
            "parent": "ship_free-shipping"
        }
    ],
    "expectedDeliveryDate": "2020-05-21"
}

export const productGroup = {
    groupName: "Mock",
    imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/7kTEZjQG40LI53bFT5aLEm/91804bf90ec2fc59ec086b247bed953b/clorox-disinfecting-bleach.png",
    brandName: "Brand",
    displayName: "Specific SKU You've Heard Of",
    productType: "MockProduct",
    possibleArticleCounts: [1, 2, 3, 4, 5, 6],
    articles: 3,
    articleType: "Roll",
    pricePerArticle: 1.00
}

export const bundle = {
    title: "Everyday Essentials",
    shortName: "everyday",
    productGroups: [
        productGroup
    ]
}

export const productGroups = [
    {
        groupName: "Mock",
        imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/7kTEZjQG40LI53bFT5aLEm/91804bf90ec2fc59ec086b247bed953b/clorox-disinfecting-bleach.png",
        brandName: "Brand",
        displayName: "Specific SKU You've Heard Of",
        productType: "MockProduct",
        possibleArticleCounts: [1, 2, 3, 4, 5, 6],
        articles: 3,
        articleType: "Roll",
        pricePerArticle: 1.00
    },
    {
        groupName: "Mock",
        imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/7kTEZjQG40LI53bFT5aLEm/91804bf90ec2fc59ec086b247bed953b/clorox-disinfecting-bleach.png",
        brandName: "Brand",
        displayName: "Specific SKU You've Heard Of",
        productType: "MockProduct",
        possibleArticleCounts: [1, 2, 3, 4, 5, 6],
        articles: 3,
        articleType: "Roll",
        pricePerArticle: 1.00
    },
    {
        groupName: "Mock",
        imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/7kTEZjQG40LI53bFT5aLEm/91804bf90ec2fc59ec086b247bed953b/clorox-disinfecting-bleach.png",
        brandName: "Brand",
        displayName: "Specific SKU You've Heard Of",
        productType: "MockProduct",
        possibleArticleCounts: [1, 2, 3, 4, 5, 6],
        articles: 3,
        articleType: "Roll",
        pricePerArticle: 1.00
    },
    {
        groupName: "Mock",
        imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/7kTEZjQG40LI53bFT5aLEm/91804bf90ec2fc59ec086b247bed953b/clorox-disinfecting-bleach.png",
        brandName: "Brand",
        displayName: "Specific SKU You've Heard Of",
        productType: "MockProduct",
        possibleArticleCounts: [1, 2, 3, 4, 5, 6],
        articles: 3,
        articleType: "Roll",
        pricePerArticle: 1.00
    },
    {
        groupName: "Mock",
        imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/7kTEZjQG40LI53bFT5aLEm/91804bf90ec2fc59ec086b247bed953b/clorox-disinfecting-bleach.png",
        brandName: "Brand",
        displayName: "Specific SKU You've Heard Of",
        productType: "MockProduct",
        possibleArticleCounts: [1, 2, 3, 4, 5, 6],
        articles: 3,
        articleType: "Roll",
        pricePerArticle: 1.00
    },
]

export const order = {
    items: [
        {
            amount: 546,
            currency: "usd",
            description: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
            groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
            parent: "sku_GRJnwcjPHdWdg2",
            quantity: 3,
            type: "sku"
        },
        {
            amount: 672,
            currency: "usd",
            description: "Toilet Paper, Cottonelle, 6 Rolls, Ultra CleanCare Mega Rolls",
            groupName: "toilet-paper-cottonelle-ultra-clean-care-mega",
            parent: "sku_GRJXEhMTKL0eCy",
            quantity: 6,
            type: "sku"
        },
        {
            amount: 222,
            currency: "usd",
            description: "Sales tax",
            type: "tax"
        }
    ],
    order: {
        status: "PaymentSucceeded",
        type: "Initial",
        createdAt: "2020-04-01T13:43:07.958Z",
        expectedDeliveryDate: "2020-04-03",
        expectedShipDate: "2020-04-02",
        orderId: "1",
        products: [
            {
                articleType: "Roll",
                articlesPerPackage: 6,
                badges: ["NationallyRecognizedBrandLead", "SupplyDropPick", "SensitiveSkin"],
                brand: "Cottonelle",
                description: "Cottonelle CleanCare toilet paper’s Cleaning Ripples provide texture for an ultra clean feel while providing exceptional softness and comfort.",
                displayName: "Ultra CleanCare Mega",
                group: "toilet-paper-cottonelle-ultra-clean-care-mega",
                groupName: "toilet-paper-cottonelle-ultra-clean-care-mega",
                imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/6QezIhqbPm9FqYHaDh6v0m/b3a27419f41c0192a98483d908c21d74/cottonelle-cleancare-1248.png",
                packages: 1,
                pricePerArticle: 112,
                productId: "8f6a8067-e7c9-f105-b1e6-c8a98920b4a8",
                purchaseDescription: "Toilet Paper, Cottonelle, 6 Rolls, Ultra CleanCare Mega Rolls",
                stripePriceCents: 112,
                type: "ToiletPaper"
            },
            {
                articleType: "Roll",
                articlesPerPackage: 1,
                badges: ["NationallyRecognizedBrandLead", "SensitiveSkin", "SupplyDropPick"],
                brand: "Viva",
                description: "Soft and durable, Signature Cloth Choose a Sheet Viva paper towels have a texture that sets them apart from other paper towels with the strength to tackle any size job.",
                displayName: "Signature Cloth Choose a Sheet Double",
                group: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/3rmlCZxwXuMj6NxTKmORhp/f2a6960b0cbf834305a3544d5952f2e3/viva-signature-cloth-1-2.png",
                packages: 3,
                pricePerArticle: 182,
                productId: "cbf23dcf-008d-4f0a-60ca-dd65244337f6",
                purchaseDescription: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
                stripePriceCents: 182,
                type: "PaperTowels"
            }
        ]
    }
}

export const newCoupon = {
    "code": "ojo",
    "description": null,
    "discount": {
        "type": "AmnountDiscount",
        "amountOffCents": 2000
    },
    "duration": {
        "type": "Once"
    },
    employer: {
        displayName: "OJO Labs"
    }
}

export * from './rounderResponse';