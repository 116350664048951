import styled from "styled-components";
import { colors } from "./Colors";

export const FixedBarData = styled.p`
    margin-bottom: 0;
    font-weight: normal;
`

export const FixedBarCol = styled.div`
    cursor: pointer;
    &:hover > p {
        text-decoration: underline;
    }
`

export const DiscountsCol = styled.div`
    margin-right: 6px;
    text-align: left;
`


export const TotalsCol = styled.div`
    margin-left: 6px;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const TotalText = styled.p`
    color: ${colors.titleText};
`

export const DiscountedSubtotalText = styled.p`
    text-decoration: line-through !important;
`
export const DiscountText = styled.p`
    color: ${colors.textGreen};
`