import React from 'react';
import '../../../Style/dashboard.css';
import {
    FAQBody as Body,
    DashboardFAQTitle as Title,
    DashboardFAQsContainer as Container
} from '../../../Style';

import { formatStringForId } from '../../../actions';

export const DashboardFAQ = ({ title, bodyHtml }) => {
    if (title && bodyHtml) {
        let id = "";
        if (title.toLowerCase() === "how can i contact supply drop?")
            id = "faqs-contact-us";
        else
            id = "faqs-" + formatStringForId(title)
        return (
            <Container id={id}>
                <Title>{title}</Title>
                <Body className="faq-body">
                    <div dangerouslySetInnerHTML={{ __html: bodyHtml }}></div>
                </Body>
            </Container>
        )
    }
}