export const colors = {
    alerts: {
        main: '#FF9800'
    },
    // Miscellaneous
    borderGray: '#EDEDED',
    borderLight: '#DFE0EB',
    borderDark: '#C9CBDA',
    cloudWhite: '#F9F9F9',
    dashboardGray: '#F7F8FC',
    disabledBackground: '#949494',
    inputBackground: '#EDEFF2',
    pureWhite: '#FFFFFF',
    // Text
    bodyText: '#465967',
    textGreen: '#00A664',
    textGreenHover: '#31af79',
    productHover: '#E4F1FF',
    titleText: '#183041',
    contrastText: "#FCFCFC",
    // Buttons
    btnBlue: {
        light: '#E4F1FF',
        idle: '#0074D2',
        hover: '#1F90FE',
        active: '#00579E',
        text: '#F9F9F9'
    },
    btnGreen: {
        light: '#E4FFEE',
        idle: '#6CD293',
        hover: '#9DE5B6',
        active: '#4EBA7A',
        text: '#1A5631'
    },
    btnOrange: {
        light: '#FFF2E4',
        idle: '#F5AD5D',
        hover: '#FAD292',
        active: '#EB8941',
        text: '#693007'
    },
    btnRed: {
        light: '#FFF1F1',
        idle: '#E34043',
        hover: '#F36668',
        active: '#C33235',
        text: '#620E10'
    },
    btnYellow: {
        light: '#FFFACC',
        idle: '#F5DF68',
        hover: '#FAEF8F',
        active: '#EBC856',
        text: '#605206'
    },
    btnWhite: {
        main: '#FFFFFF',
        text: '#465967'
    },
    // 
    // new colors from design system:
    // 
    primary: {
        main: "#0074D2",
        dark: "#0049A0",
        light: "#5DA2FF",
        contrastText: "#FCFCFC",
        active_contained: "rgba(0,73,160, .7)",
        hover: "rgba(0, 116, 210, 0.04)",
        selected: "rgba(0, 116, 210, 0.08",
        active_outlined: "rgba(0, 116, 210, 0.24)",
        outlined_stroke: "rgba(0, 116, 210, 0.5)",
    },
    secondary: {
        main: "#6CD293",
        dark: "#00A664",
        light: "#9FFFC4",
        contrastText: "#323232",
        active_contained: "rgba(0,166,100,0.7)",
        hover: "rgba(108, 210, 147, 0.04",
        selected: "rgba(108, 210, 147, 0.08)",
        active_outlined: "rgba(108, 210, 147, 0.24)",
        outlined_stroke: "rgba(0, 166, 100, 0.5)",
    },
    actions: {
        border: "#DFE0EB",
        hover: "rgba(0, 0, 0, 0.04)",
        selected: "rgba(0, 0, 0, 0.08)",
        disabled_background: "rgba(0, 0, 0, 0.12)",
        outlined_stroke: "rgba(0, 0, 0, 0.23)",
        disabled_text: "rgba(0, 0, 0, 0.26)"
    },
    other: {
        primary: "#183041",
        secondary: "#465967",
        disabled: "rgba(24, 48, 65, 0.5)",
        divider: "#DFE0EB",
        backdrop: "rgba(24, 48, 65, 0.65)",
        snackbar_background: "#323232",
        active_rotating_color: "#FFB400"
    },
    info: {
        main: "#2196F3",
        dark: "#0B79D0",
        light: "#64B6F7",
        dark_text: "#0d3d61",
        light_bg: "#E9F4FE",
        contrast_text: "#FFFFFF"
    },
    error: {
        main: "#F44336",
        dark: "#E31B0C",
        light: "#F88078",
        dark_text: "#621C16",
        light_bg: "#feeceb",
        contrast_text: "#FFFFFF"
    },
    warning: {
        main: "#FF9800",
        dark: "#C77700",
        light: "#FFB547",
        dark_text: "#663d00",
        light_bg: "#fff5e5",
        contrast_text: "rgba(0,0,0,87)"
    },
    success: {
        main: "#4CAF50",
        dark: "#3B873E",
        light: "#7BC67E",
        dark_text: "#1e4620",
        light_bg: "#edf7ed",
        contrast_text: "#FFFFFF"
    }
}