import React, { Component } from 'react';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getShipmentDate, validateZip } from '../../../actions';
import * as Sentry from '@sentry/browser';
import { getDeliveryDate } from '../../../helpers';
import TextField from '@material-ui/core/TextField';
import { CountdownAlertBody, CountdownCopy, CountdownInputWrapper as InputWrapper } from '../../../Style/CountdownAlert'
import FontAwesome from "react-fontawesome";
import { Logger } from '../../../helpers/Logger'
const logger = new Logger();

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isZipValid: true
        };

        this._isMounted = false;
        this.updateCountdown = this.updateCountdown.bind(this);
        this.setDeliveryDate = this.setDeliveryDate.bind(this);
        this.setZip = this.setZip.bind(this);
        this.validateAndGetShipmentDate = this.validateAndGetShipmentDate.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
        clearInterval(this.updateCountdown);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { shipment, zipcode } = this.props;

        if (zipcode && !shipment && zipcode !== this.state.zipcode) {
            this.setState({ zipcode });
            if(validateZip(zipcode))
                this.props.getShipmentDate(zipcode);
        }

        if (shipment && !this.state.shipment) {
            this.setState({ shipment });
            this.setDeliveryDate(shipment);
        }
    }

    componentDidUpdate() {
        this._isMounted = true;
        const { shipment, getShipmentError, zipcode } = this.props;

        if (shipment && shipment !== this.state.shipment) {
            this.setState({ shipment });
            this.setDeliveryDate(shipment);
        }
        if (zipcode && zipcode !== this.state.zipcode) {
            this.setState({ zipcode });
            if(validateZip(zipcode))
                this.props.getShipmentDate(zipcode);
        }
        if (getShipmentError && !this.state.getShipmentError) {
            this.setState({ getShipmentError })
            Sentry.captureException(getShipmentError);
        }
        if(this.state.shipment && this.state.zipcode && !zipcode) {
            this.props.setZipcode(this.state.zipcode)
        }
    }

    handleKeydown(e) {
        if(e.key === "Enter")
            this.validateAndGetShipmentDate(e.target.value)
    }

    setDeliveryDate(shipment) {
        try {
            const { deliveryDate, cutoff } = getDeliveryDate(shipment);

            this.setState({ cutoff, deliveryDate, now: moment.utc() });
            if (this.interval)
                clearInterval(this.updateCountdown)

            this.interval = setInterval(this.updateCountdown.bind(this), 1000);

        } catch (err) {
            console.error(err)
            Sentry.captureException();
        }
    }

    updateCountdown() {
        if (this._isMounted) {

            let { cutoff, now, deliveryDate, shipment } = this.state;

            if (cutoff && now && deliveryDate) {
                if (moment.utc(cutoff).isSameOrBefore(moment.utc()))
                    this.setDeliveryDate(shipment);
                else
                    this.setState({ now: moment.utc() })
            }
        }
    }

    validateAndGetShipmentDate(zipcode) {
        try {
            const isZipValid = validateZip(zipcode);
            if(isZipValid) {
                this.props.getShipmentDate(zipcode)
            } else {
                this.setState({ isZipValid })
            }
        } catch (err) {
            logger.error(err);
            this.setState({ isZipValid: false })
        }
    }
    
    setZip(zipcode) {
        this.setState({ zipcode, isZipValid: true })
    }

    renderContent() {
        const { gettingShipment } = this.props;
        const { cutoff, deliveryDate, now, zipcode, isZipValid } = this.state;
        if (gettingShipment) {
            return (
                <FontAwesome
                    key={"placing-order-spinner"}
                    name="spinner"
                    className="marRight5"
                    spin
                />
            )
        } else if (cutoff && deliveryDate) {
            return <span>{moment.utc(cutoff.diff(now)).format("H:mm:ss")} remaining for delivery by {moment(deliveryDate).format('MMM Do')}</span>
        } else {
            return (
                <CountdownAlertBody withInput={true}>
                    <CountdownCopy>We ship next business day! Enter your ZIP Code to get Delivery Date:</CountdownCopy>
                    <InputWrapper>
                        <TextField
                            id="delivery-zipcode-input"
                            onBlur={() => this.setState({ isZipValid: validateZip(zipcode) })}
                            error={!isZipValid}
                            helperText={!isZipValid && "Invalid ZIP Code"}
                            size="small"
                            label="ZIP Code"
                            variant="outlined"
                            value={zipcode}
                            onChange={(e) => this.setZip(e.target.value)}
                        />
                        <Button color="primary" size="small" className="countdown-alert-submit-btn" onClick={() => this.validateAndGetShipmentDate(zipcode)}>Submit</Button>
                    </InputWrapper>
                </CountdownAlertBody>
            )
        }
    }

    render() {
        const { spaceTop } = this.props;
        return (
            <Alert variant="warning" id="countdown-alert" className={spaceTop ? 'countdown-space-top' : ''}>
                {this.renderContent()}
            </Alert>
        )
    }
}

const mapStateToProps = (state) => {
    const { gettingShipment, shipment, getShipmentError } = state.order;
    return { gettingShipment, shipment, getShipmentError };
}

export default connect(mapStateToProps, {
    getShipmentDate
})(Countdown);