import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import {
    getRounder,
    formatProductType,
    clearRounder,
    getProductGroups,
    marginForCountdown,
    setCancelText,
    setManifest,
    refreshCappedValues,
    checkIfProductInBundle,
    removeProduct,
    setSubtotal,
    changeBundleProductQuantity,
    removeBodyNoScroll,
    removeAddProductModalNoScroll,
    appendBodyNoScroll,
    appendAddProductModalNoScroll,
    handleNewInterviewData,
    getBundleProducts,
    createOrderFromManifestBundles
} from '../../actions';
import Feedback from '../Common/Connected/Feedback';
import Countdown from '../Common/Connected/Countdown';
import {
    ProgressBar,
    ProductDetail,
    Nav,
    Saving,
    MetaTags,
    Loading,
    ManifestBundle,
    ProductsModal,
    FixedContinueBtn,
    InterviewQuestionModal,
    CancelModal,
    OrderSummary,
    KitchenFlow,
    SummaryTestimonials,
    ValueProps
} from '../Common';
import { bundles } from '../../data';
import {
    ValuePropsHR as HR,
    FadeInRight,
    CheckoutBody as Body
} from '../../Style';
import * as images from '../../assets';
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

class OrderPrediction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantityChanges: {},
            productChanges: {},
            productGroupsMap: {},
            selectedProductTypes: {},
            frequency: 1
        }

        this.setManifest = this.setManifest.bind(this);
        this.onConfirmOrderClick = this.onConfirmOrderClick.bind(this);
        this.retryRounder = this.retryRounder.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.closeDetail = this.closeDetail.bind(this);
        this.handleQuantityChangeReason = this.handleQuantityChangeReason.bind(this);
        this.handleProductChangeReason = this.handleProductChangeReason.bind(this);
        this.showDescription = this.showDescription.bind(this);
        this.removeKeydown = this.removeKeydown.bind(this);
        this.addKeydown = this.addKeydown.bind(this);
        this.updateInterview = this.updateInterview.bind(this);
        this.showOtherBrands = this.showOtherBrands.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.selectProductGroup = this.selectProductGroup.bind(this);
        this.viewBundleProducts = this.viewBundleProducts.bind(this);
        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.closeInterviewModal = this.closeInterviewModal.bind(this);
        this.submitProductChangeReason = this.submitProductChangeReason.bind(this);
        this.setUnsaved = this.setUnsaved.bind(this);
        this.setViewBundle = this.setViewBundle.bind(this);
        this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.refreshCappedValues = this.refreshCappedValues.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
        this.setTrashTypes = this.setTrashTypes.bind(this);
        this.back = this.back.bind(this);
    }

    componentWillUnmount() {
        this.removeKeydown();
        window.removeEventListener('resize', this.handleResize, false);
        removeBodyNoScroll();
        window.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    addKeydown() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    removeKeydown() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const { showInfo } = this.state;
        if (e.keyCode && e.keyCode === 13) {
            if (showInfo) {
                this.setState({ showInfo: false });
            } else {
                this.onConfirmOrderClick();
            }
        }

        const { viewBundle, selectedProductType, showProduct, addModalProductTypes } = this.state;
        if (e.keyCode && e.keyCode === 27) {
            if (selectedProductType && !showProduct) {
                this.closeModal("change")
            }
            if (viewBundle && addModalProductTypes && !showProduct && !selectedProductType) {
                this.closeModal("add")
            }
        }
    }

    handleClickOutside(e) {
        const onClickId = e.target.id
        if (onClickId === "change-products-modal-container") {
            this.closeModal("change")
        }
        if (onClickId === "add-products-modal-container") {
            this.closeModal("add")
        }
    }

    handleResize(e) {
        const { isMobile } = this.state;
        if (window.innerWidth < 992 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 991 && isMobile) {
            this.setState({ isMobile: false })
        }
    }

    componentDidMount() {
        if (prod) {
            window.fbq('trackCustom', 'InterviewCompleted');
            window.rdt('track', 'Lead')
        }
        window.scrollTo(0, 0);
        this.addKeydown();

        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);

        this.handleResize();
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { interview, zipcode, productGroupsMap, trashTypes } = signUpData;
            if (interview) {
                this.props.getRounder(interview);
                this.setStateForKeyValue(interview);
                if (!productGroupsMap) {
                    this.props.getProductGroups();
                } else {
                    this.setState({ productGroupsMap })
                }

                this.setTrashTypes(trashTypes);
            } else {
                this.back();
            }

            if (zipcode)
                marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        const { signUpData } = this.state;
        this.props.history.push('/preference', { signUpData })
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    setTrashTypes(trashTypes) {
        const { selectedProductTypes } = this.state;
        const bundle = bundles.everyday;
        bundle.productTypes.forEach(productType => {
            if (productType.includes("TrashBags")) {
                const trashType = productType.replace("TrashBags", "");
                if (trashTypes[trashType].frequency) {
                    selectedProductTypes[productType] = true;
                }
            } else {
                selectedProductTypes[productType] = true;
            }
        })
        this.setState({ selectedProductTypes })
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentDidUpdate() {
        const { rounderError, rounder, productGroupsError, productGroups } = this.props;
        const { manifestBundles, productGroupsMap, selectedProductTypes, refreshTotals, newRounder } = this.state;

        if (productGroups && !this.state.productGroups && !this.state.productGroupsMap) {
            productGroups.forEach(pg => {
                productGroupsMap[pg.groupName] = pg;
            })
            this.setState({ productGroups, productGroupsMap });
        }

        if (rounderError && !this.state.rounderError) {
            this.setState({ rounderError });
        }

        if (productGroupsError && !this.state.productGroupsError) {
            this.setState({ productGroupsError });
            Sentry.captureException(productGroupsError);
        }

        if (!manifestBundles && rounder && Object.keys(productGroupsMap).length && !this.state.rounder && Object.keys(selectedProductTypes).length) {
            this.setState({ rounder });
            this.setManifest(rounder, productGroupsMap, selectedProductTypes);
        }

        if (newRounder && rounder && Object.keys(productGroupsMap).length) {
            this.setState({ rounder, newRounder: false });
            this.refreshCappedValues(rounder.productSets, productGroupsMap);
        }

        if (!rounder && this.state.rounder) {
            this.setState({ rounder, options: undefined })
        }
        if (refreshTotals) {
            // maybe a better way to do this
            this.setState({ refreshTotals: false })
        }
    }

    async setManifest(rounder, productGroupsMap, selectedProductTypes) {
        const res = await setManifest(rounder, productGroupsMap, selectedProductTypes);
        if (!res.error) {
            this.setState({ rounderSet: res.rounderSet, manifestBundles: res.manifestBundles, productGroupsMap: res.productGroupsMap })
        } else {
            console.error(res.error);
            Sentry.captureException(res.error);
        }
    }

    viewBundleProducts(shortName) {
        try {
            const { interview } = this.state;
            if (shortName === "kitchen" && interview.housekeeping === undefined) {
                this.setState({ need: "needsKitchenFlow" })
            } else if (shortName === "laundry" && (!interview.laundry || !interview.laundry.frequency)) {
                this.setState({ need: "needsLaundry" });
            }
            appendBodyNoScroll();
            this.setViewBundle(shortName);
        } catch (err) {
            console.error(err);
            // Sentry.captureException(err);
        }
    }

    async setViewBundle(shortName) {
        let { viewBundle, productGroupsMap, manifestBundles, rounderSet } = this.state;

        if (!viewBundle)
            viewBundle = { shortName }

        const productTypes = await getBundleProducts(shortName, productGroupsMap, manifestBundles, rounderSet);
        viewBundle.title = bundles[shortName].title;

        this.setState({ viewBundle, addModalProductTypes: productTypes })
    }

    async refreshCappedValues(productSets, productGroupsMap) {
        const { newProductsMap, error } = await refreshCappedValues(productSets, productGroupsMap);
        if (newProductsMap)
            this.setState({ productGroupsMap: newProductsMap })
        else {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    async onConfirmOrderClick() {
        try {
            const { manifestBundles, rounderSet, productGroupsMap, rounder, interview, signUpData } = this.state;
            const order = await createOrderFromManifestBundles(manifestBundles, rounderSet);

            const updatedSignUpData = Object.assign(signUpData, { manifestBundles, rounderSet, productGroupsMap, rounder, interview, order })

            this.props.history.push("/checkout", { signUpData: updatedSignUpData });
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    retryRounder() {
        const { interview } = this.state;
        this.props.getRounder(interview);
        this.setState({ loading: true, rounderError: false })
    }

    closeDetail() {
        window.addEventListener('keydown', this.handleKeydown, false);
        this.setState({ showProduct: undefined });
    }

    renderError() {
        const { rounderError } = this.state;
        if (rounderError) {
            return (
                <Saving error={rounderError} body="Try again?" tryAgain={this.retryRounder} />
            )
        }
    }

    async onQuantityChange(groupName, value) {
        try {
            const { productGroupsMap, manifestBundles } = this.state;
            const productGroup = productGroupsMap[groupName];
            const isProductInBundle = await checkIfProductInBundle(manifestBundles, productGroup)
            let res;
            if (isProductInBundle) {
                if (value === "remove") {
                    res = await removeProduct(manifestBundles, productGroup)
                } else {
                    res = await changeBundleProductQuantity(manifestBundles, productGroup, value)
                }
                if (res.manifestBundles) {
                    this.setState({ newChanges: true, manifestBundles: res.manifestBundles, refreshTotals: true });
                }
            } else {
                // in case the productGroup hasn't been added to the manifest yet, but user is making quantity changes
                productGroupsMap[groupName].articles = value;
                this.setState({ productGroupsMap, newChanges: true })
            }
        } catch (err) {
            console.error(err);
            // Sentry.captureException(err);
        }
    }

    handleQuantityChangeReason(groupName, reason) {
        const { quantityChanges } = this.state;
        for (let i = 0; i < quantityChanges.length; i++) {
            if (quantityChanges[i].product.groupName === groupName) {
                quantityChanges[i].reason = reason;
                break;
            }
        }
        this.setState({ quantityChanges })
    }

    handleProductChangeReason(reason) {
        const changedProductReason = reason;
        this.setState({ changedProductReason });
    }

    submitProductChangeReason() {
        const { changedProductReason, changedProduct, productChanges } = this.state;
        productChanges[changedProduct] = changedProductReason;
        this.setState({ changedProduct: undefined, changedProductReason: undefined, productChanges })
    }

    closeInterviewModal() {
        this.setState({ need: false, unfinishedQuestion: undefined, frequency: 1 })
        this.closeModal("add");
    }

    setUnsaved(unfinishedQuestion) {
        const { need } = this.state;
        if (!unfinishedQuestion) {
            const question = need.replace("needs", "");
            this.setState({ unfinishedQuestion: question })
        } else {
            this.setState({ unfinishedQuestion })
        }
    }

    renderAdditionalQuestions() {
        const { need, showInfo, interview, unfinishedQuestion } = this.state;
        if (!showInfo && !unfinishedQuestion && need) {
            if (need === "needsKitchenFlow") {
                return (
                    <KitchenFlow
                        history={this.props.history}
                        updateInterview={this.updateInterview}
                        interview={interview}
                        close={this.setUnsaved}
                        clearUnsavedChanges={() => this.setState({ unsavedChanges: false })}
                    />
                )
            } else {
                return <InterviewQuestionModal need={need} next={this.updateInterview} back={this.setUnsaved} close={this.setUnsaved} history={this.props.history} />
            }
        }
    }

    renderUnsavedQuestionModal() {
        const { unfinishedQuestion, need } = this.state;
        if (unfinishedQuestion && need) {
            const copy = setCancelText(unfinishedQuestion, need);
            return (
                <CancelModal
                    next={this.closeInterviewModal}
                    cancel={() => this.setState({ unfinishedQuestion: undefined })}
                    copy={copy}
                />
            )
        }
    }

    showDescription(groupName) {
        const { productGroupsMap } = this.state;
        const showProduct = productGroupsMap[groupName];
        this.setState({ showProduct })
    }

    renderBackModal() {
        const { navigatingBack } = this.state;
        if (navigatingBack) {
            return (
                <div className="modal-container transparent">
                    <div className="interview-question-modal">
                        <img onClick={() => { this.setState({ navigatingBack: false }) }} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body$ no-padding">
                            <div className="signin-modal-tab-container">
                                <div className="cancel-modal-body">
                                    <p className="modal-title">
                                        Are you sure you want to go back?
                                    </p>
                                    <hr />
                                    <p className="modal-p">
                                        You will lose any changes made to your order.
                                    </p>
                                </div>
                            </div>
                            <div className="cancel-modal-button-row">
                                <button className="dashboard-btn dashboard-submit white" onClick={() => { this.setState({ navigatingBack: false }) }}>No, stay here</button>
                                <button className="dashboard-btn dashboard-cancel-btn" onClick={this.back}>Yes, go back</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderProductDetail() {
        const { showProduct } = this.state;
        if (showProduct) {
            window.removeEventListener('keydown', this.handleKeydown, false);
            return <ProductDetail productGroup={showProduct} close={this.closeDetail} />
        }
    }

    async updateInterview(data) {
        const { need, interview } = this.state;
        const res = await handleNewInterviewData(need, data, interview)
        if (res.interview) {
            this.props.getRounder(res.interview);

            if (need !== "needsKitchen" || need !== "needsLaundry") {
                removeAddProductModalNoScroll();
            }
            this.setState({ need: undefined, interview: res.interview, newRounder: true, frequency: 1 })
        } else {
            this.setState({ need: undefined, frequency: 1 })
        }
    }

    showOtherBrands(productType, shortName) {
        const { manifestBundles, rounderSet, productGroupsMap, viewBundle } = this.state;
        try {
            const selectedProductGroup = manifestBundles[shortName].productGroups[productType];
            const productTypeSet = rounderSet[productType].additional.map(pg => {
                return pg.groupName
            }).filter(groupName => productGroupsMap[groupName] && productGroupsMap[groupName].status === "Active");

            productTypeSet.push(rounderSet[productType].preferred.groupName);
            const selectedProductGroups = productTypeSet.map(groupName => {
                return productGroupsMap[groupName];
            }).filter(productGroup => productGroup.available === true)
            if (viewBundle) {
                appendAddProductModalNoScroll();
            } else {
                appendBodyNoScroll();
            }

            let selectedBundle = { shortName, title: bundles[shortName].title }

            this.setState({ selectedProductGroup, selectedProductGroups, selectedProductType: productType, selectedBundle });
        } catch (err) {
            console.error(err);
            // Sentry.captureException(err)
        }
    }

    closeModal(cta) {
        const { viewBundle, selectedProductType } = this.state;
        const element = document.getElementById(`${cta}-products-modal`);
        element.classList.add("slideOutRight");
        if (selectedProductType) {
            if (viewBundle) {
                removeAddProductModalNoScroll();
            } else {
                removeBodyNoScroll();
            }
            setTimeout(() => {
                let selectedProductGroup, selectedProductGroups, selectedProductType, selectedBundle = undefined;
                this.setState({ selectedProductGroup, selectedProductGroups, selectedProductType, selectedBundle })
            }, 500)
        } else {
            removeBodyNoScroll();
            setTimeout(() => {
                this.setState({ viewBundle: false })
            }, 500)
        }
    }

    selectProductGroup(groupName, cta, shortName) {
        try {
            const { manifestBundles, productGroupsMap } = this.state;
            const selectedProduct = productGroupsMap[groupName];
            const { articles, proposedArticles, cappedArticleCounts, productType } = selectedProduct;

            let refreshView;

            if (!articles)
                selectedProduct.articles = proposedArticles ? proposedArticles : cappedArticleCounts.length ? cappedArticleCounts[0] : 0;

            // may want to bring this back later:
            // 
            // if(manifestBundles[shortName].productGroups[productType]) {
            //     const changedProduct = productType
            //     this.setState({changedProduct});
            // }

            manifestBundles[shortName].productGroups[productType] = selectedProduct;

            if (cta === "change") {
                this.closeModal(cta);
                const addModal = document.getElementById("add-products-modal");
                if (addModal)
                    refreshView = true
            }

            if (Object.values(manifestBundles[shortName].productGroups).length === bundles[shortName].productTypes.length) {
                this.closeModal("add");
                manifestBundles[shortName].hasAll = true;
                removeBodyNoScroll();
                setTimeout(() => {
                    this.setState({ viewBundle: false })
                }, 500)
            } else {
                refreshView = true;
            }
            const res = setSubtotal(manifestBundles, shortName);
            if (res.manifestBundles) {
                this.setState({ manifestBundles: res.manifestBundles, refreshTotals: true });
                if (refreshView) {
                    // this ensures once a product is added, it does not continue to show in modal
                    this.viewBundleProducts(shortName);
                }
            } else {
                throw res.error;
            }
        } catch (err) {
            console.error(err)
            Sentry.captureException(err);
        }
    }

    handleDropdownSelect(productType) {
        try {
            const need = productType.replace("TrashBags", "");
            this.setState({ need: `needs${need}` })
            const element = document.getElementById(`product-select-${productType}`);
            element.blur();
        } catch (err) {
            console.error(err)
        }
    }

    renderCountdown() {
        const { zipcode } = this.state;
        if (zipcode)
            return <Countdown spaceTop={true} zipcode={zipcode} />
    }

    renderChangeProductsModal() {
        const { selectedProductGroup, selectedProductGroups, selectedProductType, selectedBundle, showProduct } = this.state;
        if (selectedProductType && !showProduct) {
            const formattedType = formatProductType(selectedProductType)

            return (
                <ProductsModal
                    cta="change"
                    productGroups={selectedProductGroups}
                    selectedProductGroup={selectedProductGroup}
                    formattedProductType={formattedType}
                    onQuantityChange={this.onQuantityChange}
                    bundleTitle={selectedBundle.title}
                    bundleShortName={selectedBundle.shortName}
                    selectProductGroup={this.selectProductGroup}
                    showDescription={this.showDescription}
                    close={this.closeModal}
                />
            )
        }
    }

    renderAddProductsModal() {
        const { viewBundle, addModalProductTypes, interview, rounderSet, showProduct } = this.state;
        if (viewBundle && addModalProductTypes && !showProduct) {
            return (
                <ProductsModal
                    cta="add"
                    productTypes={addModalProductTypes}
                    showDescription={this.showDescription}
                    onQuantityChange={this.onQuantityChange}
                    rounderSet={rounderSet}
                    showOtherBrands={this.showOtherBrands}
                    bundleTitle={viewBundle.title}
                    bundleShortName={viewBundle.shortName}
                    selectProductGroup={this.selectProductGroup}
                    close={this.closeModal}
                    interview={interview}
                    handleDropdownSelect={this.handleDropdownSelect}
                />
            )
        }
    }

    renderBundles() {
        const {
            rounderSet,
            manifestBundles,
            isMobile,
            coupon,
            newCoupon,
            isEmployeePerk,
            employer,
            sdCredit,
            refCust
        } = this.state;

        if (manifestBundles && rounderSet) {
            try {
                const rows = Object.entries(manifestBundles).map(entry => {
                    const shortName = entry[0]
                    const { title } = bundles[shortName];
                    const { productGroups, subtotal, hasAll } = entry[1];
                    return (
                        <ManifestBundle
                            key={title}
                            title={title}
                            bundleShortName={shortName}
                            productGroups={Object.values(productGroups)}
                            rounderSet={rounderSet}
                            onQuantityChange={this.onQuantityChange}
                            showOtherBrands={this.showOtherBrands}
                            hasAllBundleTypes={hasAll}
                            showDescription={this.showDescription}
                            subtotal={subtotal}
                            viewBundleProducts={() => this.viewBundleProducts(shortName)}
                            selectProductGroup={this.selectProductGroup}
                        />
                    )
                })
                if (isMobile) {
                    return rows;
                } else {
                    return (
                        <Body>
                            <div className="desktop-bundle-container">
                                {rows}
                            </div>
                            <OrderSummary
                                manifestBundles={manifestBundles}
                                coupon={coupon}
                                newCoupon={newCoupon}
                                sdCredit={sdCredit}
                                employer={employer}
                                isEmployeePerk={isEmployeePerk}
                                showValueProps={true}
                                showReferral={refCust ? true : false}
                            />
                        </Body>
                    )
                }
            }
            catch (err) {
                console.error(err)
            }
        }
    }

    renderMobileValueProps() {
        const { isMobile } = this.state;
        if (isMobile) {
            return (
                <Fragment>
                    <HR />
                    <ValueProps />
                    <SummaryTestimonials isMobile={isMobile} />
                </Fragment>
            )
        }
    }

    render() {
        const {
            manifestBundles,
            zipcode,
            refreshTotals,
            coupon,
            rounderSet,
            isMobile,
            newCoupon,
            sdCredit,
            isEmployeePerk,
            employer,
            refCust
        } = this.state;
        if (manifestBundles && rounderSet) {
            let isEmpty = true;
            for (const property in manifestBundles) {
                if (manifestBundles[property].subtotal !== 0) {
                    isEmpty = false;
                }
            }
            return (
                <div>
                    <MetaTags title="Supply Drop - Prediction" />
                    <ProgressBar progress={100} />
                    {this.renderCountdown()}
                    <Nav spaceTopLg={zipcode} back={() => this.setState({ navigatingBack: true })} />
                    {this.renderProductDetail()}
                    {this.renderBackModal()}
                    {this.renderChangeProductsModal()}
                    {this.renderAdditionalQuestions()}
                    {this.renderUnsavedQuestionModal()}
                    {this.renderAddProductsModal()}
                    <div className="has-fixed-continue">
                        <FadeInRight>
                            <div className="order-prediction-header-container">
                                <div className="order-prediction-header">Here's your recommended first delivery.</div>
                                <div className="order-prediction-subheader">If you would like to make adjustments, you can easily change your product selection or quantity.  In order to ensure every customer has what they need every month, there is a maximum quantity allowed for certain products.</div>
                            </div>
                            {this.renderBundles()}
                        </FadeInRight>
                        {this.renderMobileValueProps()}
                    </div>
                    <FixedContinueBtn
                        manifestBundles={manifestBundles}
                        next={this.onConfirmOrderClick}
                        disabled={isEmpty}
                        refreshTotals={refreshTotals}
                        coupon={coupon}
                        employer={employer}
                        isMobile={isMobile}
                        newCoupon={newCoupon}
                        sdCredit={sdCredit}
                        isEmployeePerk={isEmployeePerk}
                        showReferral={refCust ? true : false}
                    />
                </div>
            )
        } else {
            return (
                <Fragment>
                    <MetaTags title="Supply Drop - Prediction" />
                    <ProgressBar />
                    {this.renderCountdown()}
                    <Nav spaceTopLg={zipcode} />
                    {this.renderError()}
                    <div className="interview-outer">
                        <Loading />
                        <div className="interview-inner fadeInUp animated-fast rounded prediction-loading">
                            <div className="interview-copy">
                                <b>We’re calculating your first delivery...</b>
                            </div>
                        </div>
                        <Feedback question={'prediction'} isPostInterview={true} />
                    </div>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { productGroups, productGroupsError } = state.product;
    const { error, rounder, rounderError, gettingRounder } = state.interview;
    return { error, rounder, rounderError, gettingRounder, productGroups, productGroupsError };
}

export default connect(mapStateToProps, {
    getRounder,
    clearRounder,
    getProductGroups
})(OrderPrediction);
