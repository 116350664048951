import { Auth } from 'aws-amplify';
import { Logger } from './Logger';
const logger = new Logger();

const errorMessage = "Oops, something went wrong! Please sign in again and retry.";

export function signOut(history, hasErrorMessage) {
    const body = hasErrorMessage ? { pathname: '/login', state: { errorMessage } } : '/login';
    console.log(localStorage);
    Auth.signOut().then(() => {
        if(history)
            history.push(body);
        else
            window.location.reload(false);
    }).catch(err => {
        logger.error(new Error('Clearing localStorage'))
        localStorage.clear();
        logger.error(err, new Error('Auth.signOut failed after globalError thrown'))
        if(history)
            history.push(body);
        else
            window.location.reload(false);
    })
}