import React from 'react';
import * as images from '../../../assets';

export const SDPartnerLogos = ({ style, partner }) => {
    const partnerLogo = partner === "localeur" ? 
        <img src={images.localeur_logo} alt="Localeur logo" /> :
        <img style={{ paddingTop: 10 }} src={images.fetch_logo} alt="Fetch logo" />

    return (
        <div className="landing-logo-container" style={style}>
            <img src={images.logo} alt="Supply Drop logo" />
            <img id="landing-plus-sign" alt="plus" src={images.plus_sign} />
            {partnerLogo}
        </div>
    )
}