import React from 'react';
import * as images from '../../../assets';

export const PhoneBanner = ({next}) => {
    return (
        <div id="phone-banner-container" onClick={next}>
            <div id="phone-banner-main">
                <img src={images.phone_banner} alt="Verify your mobile number" id="phone-banner-img" />
                <div id="phone-banner-copy">Please verify your phone number</div>
            </div>
        </div>
    )
}