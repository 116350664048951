import React, { Component } from "react";
import * as images from "../../assets";
import "../../Style/signin.css";
import { Nav, MoreInfo, OutageBanner } from "../Common";
import { parseSearchParams } from '../../actions';

class SignIn extends Component {
	constructor(props) {
		super(props);

		this.state = {
			channel: "email",
		};

		this.redirect = this.redirect.bind(this)
	}

	componentDidMount() {
		const searchParams = parseSearchParams(this.props.location.search);
		if(searchParams.searchData) {
			const { s } = searchParams.searchData
			const channel = s === "e" ? "email" : "sms"
			this.setState({ channel })
		} else {
			this.props.history.push('/login')
		}
	}

	redirect() {
		this.props.history.push("/login");
	}

	renderInfo() {
		const { showInfo } = this.state;
		if (showInfo) {
			return (
				<MoreInfo
					close={() => this.setState({ showInfo: false })}
					copy="Passwords are so 2000. You don't have to mess with them anymore."
				/>
			);
		}
	}

	render() {
		const { channel } = this.state;
		return (
			<div>
				<OutageBanner />
				<Nav pathname="/" />
				{this.renderInfo()}
				<div className="interview-outer">
					<div className="interview-nav-container">
						<div className="interview-top-row signin">
							<div></div>
							<img
								src={images.info}
								onClick={() => this.setState({ showInfo: true })}
								className="interview-icon interview-info-icon"
								alt="Info"
							/>
						</div>
					</div>
					<div className="interview-inner text-center">
						<div className="interview-header-img">
							<img src={images.login} alt="Security" />
						</div>
						<div className="signin-main-header">New magic link</div>
						<div className="signin-subtext">
							Your previous link expired.
							<br />
								For security, we've sent a new link to your{" "}
								{channel === "sms" ? "phone" : "email"} that will allow you to login.
							<br />
							<br />
							<div>
								<span className="span-link" onClick={this.redirect}>Click here </span>
            					if you need to request a new link, or if you don't receive
								{channel === "sms" ? " a message." : " an email"}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SignIn;
