import React from 'react';

export const CancelModal = ({
    next,
    cancel,
    copy
}) => {
    return (
        <div className="modal-container transparent interview-question-modal-container">
            <div className="interview-question-modal">
                <div className="signin-modal-tab-container">
                    <div className="cancel-modal-body">
                        <p className="modal-title">
                            Are you sure you want to cancel?
                        </p>
                        <hr />
                        <p className="modal-p">{copy}
                            {/* {questions} before we have your recommendation for kitchen & cleaning supplies! */}
                        </p>
                    </div>
                </div>
                <div className="cancel-modal-button-row">
                    <button className="dashboard-btn dashboard-submit white" onClick={cancel}>Don't cancel and go back</button>
                    <button className="dashboard-btn dashboard-cancel-btn" onClick={next}>Yes, cancel</button>
                </div>
            </div>
        </div>
    )
}