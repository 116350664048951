import * as Sentry from '@sentry/browser';
import {
    SIGNED_IN_AUTH_ERROR,
    NEW_USER_AUTH_ERROR,
    CLEAR_GLOBAL_ERRORS
} from '../actions/types';
import { Logger } from '../helpers/Logger';
const logger = new Logger();

const INITIAL_STATE = {
    signedInAuthErr: undefined,
    nonSignedInAuthErr: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNED_IN_AUTH_ERROR:
            console.log(localStorage)
            logger.error(new Error('Clearing localStorage'))
            localStorage.clear();
            Sentry.captureEvent('cleared cognito local data')
            Sentry.captureException(new Error('Instance of global error'))
            return { ...state, signedInAuthErr: action.error };
        case NEW_USER_AUTH_ERROR:
            console.log(localStorage)
            logger.error(new Error('Clearing localStorage'))
            localStorage.clear();
            Sentry.captureEvent('cleared cognito local data')
            Sentry.captureException(new Error('Instance of global error'))
            console.error(action.error)
            return { ...state, nonSignedInAuthErr: action.error };
        case CLEAR_GLOBAL_ERRORS:
            Sentry.captureEvent('cleared global error')
            Sentry.captureException(new Error('Instance of global error handled'))
            return { ...state, nonSignedInAuthErr: undefined, signedInAuthErr: undefined };
        default:
            return state;
    }
};