import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import * as images from '../../../assets';
import ReactTooltip from 'react-tooltip';
import ContentfulImage from '../../../contentful';
import {SingleChoiceModal} from '..';
import ReactGA from 'react-ga';
import {getUnits} from '../../../actions';

export class ProductDetail extends Component {
    constructor(props) {
        super(props);

        this.handleKeydown = this.handleKeydown.bind(this);
    }
    
    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    componentDidMount() {
        ReactGA.pageview('/product-detail')
    }

    handleKeydown(e) {
        if(e.keyCode && (e.keyCode === 13 || e.keyCode === 27)) {
            this.props.close();
        }
    }

    render() {
        const {productGroup, close} = this.props;
        const {
            imageUrl,
            name,
            brand,
            brandName,
            displayName,
            price,
            pricePerArticle,
            description, 
            badges,
            groupName,
            articleType,
            displayUnits
        } = productGroup;
    
        const singleUnit = getUnits(articleType, 1, true);
        const badgeImages = [];
    
        if(badges && badges.length) {
            badges.forEach(badge => {
                if(images[badge]) {
                    let formattedBadge = "";
                        switch(badge) {
                            case "SupplyDropPick":
                                formattedBadge = "Premium Product" 
                                break;
                            case "EcoFriendly":
                                formattedBadge = "Eco-Friendly"
                                break;
                            case "NationallyRecognizedBrandLead":
                                formattedBadge = "National Brand";
                                break;
                            case "SocialGood":
                                formattedBadge = "Socially Conscious";
                                break;
                            default:
                                formattedBadge = badge.replace(/([A-Z])/g, ' $1').trim()
                                break;
                        }
                        badgeImages.push(
                            <div>
                                <img data-tip data-for={`${groupName}-${badge}`} className="prediction-badge" src={images[badge]} alt={badge} />
                                <ReactTooltip id={`${groupName}-${badge}`}>
                                    {formattedBadge}
                                </ReactTooltip>
                            </div>
                        )
                }
            })
        }
        
        return (
        <SingleChoiceModal
            title="Product Details"
            body=
                {<Row className="flex-row flex-center product-detail-container">
                    <Row>
                        <Col xs={4} className="product-detail-thumbnail">
                              <ContentfulImage src={imageUrl}
                                defaultWidth="100" defaultHeight="100"
                                alt={brand ? brand : brandName}
                              />
                        </Col>
                        <Col xs={8} className="flex-col-center flex-center product-detail-name">
                            <Row className="prediction-detail-name no-margin">
                                <div><b>{brand ? brand : brandName}</b> {name ? name : displayName}<br/>
                                <span>${price ? price.toFixed(2) : (pricePerArticle/100).toFixed(2)} per {!!displayUnits ? displayUnits : singleUnit}</span></div>
                            </Row>
                        </Col>
                        <Col xs={12}><div className="interview-copy product-detail-copy">{description}</div></Col>
                        <Col xs={12}><div className="product-detail-badges-header">THIS PRODUCT IS</div></Col>
                        <Col xs={12} className="text-left"><div className="badge-row detail">{badgeImages}</div></Col>
                    </Row>
                </Row>}
            buttonCopy="Go Back"
            close={close}
            next={close}
        />
        )
    }
}
