export const fetchAddresses = {
    sdn: {
        shippingAddress: {
            line1: "9324 Neils Thompson Dr. Ste 100",
            line2: "SDN",
            city: "Austin",
            state: "TX",
            zipcode: "78758",
            deliveryInstructions: "Fetch: "
        },
        billingAddress: {
            line1: "3401 W Parmer Ln",
            line2: "",
            city: "Austin",
            state: "TX",
            zipcode: "78727"
        }
    },
    wpk: {
        shippingAddress: {
            line1: "9324 Neils Thompson Dr. Ste 100",
            line2: "WPK",
            city: "Austin",
            state: "TX",
            zipcode: "78758",
            deliveryInstructions: "Fetch: "
        },
        billingAddress: {
            line1: "11711 Domain Dr",
            line2: "",
            city: "Austin",
            state: "TX",
            zipcode: "78758"
        }
    }
}