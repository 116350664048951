import React from 'react';
import * as images from '../../../assets';
import '../../../Style/header.css'
import { Link } from 'react-router-dom'
import { InterviewNavContainer } from '../../../Style/Nav';

export const Nav = ({
    disabled,
    spaceTopLg,
    spaceTop,
    back,
    showInfo,
    pathname
}) => {
    const infoIcon = () => {
        if (showInfo) {
            return <img src={images.info} onClick={showInfo} className="interview-icon interview-info-icon" alt="Info" />
        } else {
            return <div className="header-filler"></div>
        }
    }

    const backButton = () => {
        if (back) {
            return <img src={images.back_arrow} onClick={back} className="interview-icon interview-back-arrow" alt="Back" />
        } else {
            return <div className="header-filler"></div>
        }
    }

    const logo = () => {
        const { search } = window.location;
        if (disabled || !pathname) {
            return (
                <img className="nav-logo" src={images.logo} alt="Supply Drop Home" />
            )
        } else {
            return (
                <Link to={{ pathname, search }}>
                    <img className="nav-logo" src={images.logo} alt="Supply Drop Home" />
                </Link>
            )
        }
    }

    return (
        <InterviewNavContainer spaceTopLg={spaceTopLg} spaceTop={spaceTop}>
            <div className="interview-top-row header">
                {backButton()}
                {logo()}
                {infoIcon()}
            </div>
        </InterviewNavContainer>
    )
}