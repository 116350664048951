import React, { Fragment, useState } from 'react';
import { MegaMenu, AboutUs } from '../';
import {
	DesktopMenuContainer as Container,
	DesktopMenuLink as StyledLink,
	NavCTA as Button,
	NavCTAContainer as BtnContainer,
	DesktopMenuProductsLink as ProductsLink
} from '../../../Style';
import { signOut } from '../../../helpers/SignOut';

export const DesktopMenu = ({ buttonClick, buttonText, isSignedIn, history, spaceTop }) => {
	const [productOpen, setProductsOpen] = useState()
	const [productHovered, setProductsHovered] = useState()
	const [aboutUsOpen, setAboutUsOpen] = useState()
	const [aboutUsHovered, setAboutUsHovered] = useState()

	const productHoverLeave = () => {
		setTimeout(() => { setProductsHovered(false) }, 700)
	}

	const renderProductMenu = () => {
		if (productHovered || productOpen) {
			return <MegaMenu spaceTop={spaceTop} setOpen={setProductsOpen} history={history} />
		}
	}

	const aboutUsHoverLeave = () => {
		setTimeout(() => { setAboutUsHovered(false) }, 700)
	}

	const renderAboutUsMenu = () => {
		if (aboutUsHovered || aboutUsOpen) {
			return <AboutUs spaceTop={spaceTop} setOpen={setAboutUsOpen} history={history} />
		}
	}

	return (
		<Fragment>
			<Container>
				<StyledLink as={ProductsLink} onMouseOver={() => { setProductsHovered(true); setAboutUsHovered(false) }} onMouseLeave={productHoverLeave}>Products <span style={{ fontSize: 14 }} className="fa fa-chevron-down" /></StyledLink>
				<StyledLink onMouseOver={() => { setAboutUsHovered(true); setProductsHovered(false) }} onMouseLeave={aboutUsHoverLeave}>About <span style={{ fontSize: 14 }} className="fa fa-chevron-down" /></StyledLink>
				<StyledLink href={"/#faqs-contact-us" + window.location.search} title="Contact">Contact </StyledLink>
				<StyledLink href="https://blog.supplydrop.com" title="Blog">Blog</StyledLink>
				<StyledLink as={ProductsLink} onClick={isSignedIn ? () => signOut() : () => history.push('/login')} title={isSignedIn ? "Logout" : "Login"}>{isSignedIn ? "Logout" : "Login"}</StyledLink>
				<BtnContainer>
					<Button id={isSignedIn ? "desktop-nav-cta-signed-in" : "desktop-nav-cta"} onClick={buttonClick}>{buttonText ? buttonText : isSignedIn ? "My Dashboard" : "Get Started"}<span test-id="get-started"></span></Button>
				</BtnContainer>
			</Container>
			{renderProductMenu()}
			{renderAboutUsMenu()}
		</Fragment>
	)
}

