import React from 'react';
import {
    BundleDescriptionSectionContainer as Container,
    BundleDescriptionSectionWrapper as Wrapper,
    BundleDescriptionSectionImgWrapper as ImgWrapper,
    BundleDescriptionSectionImg as Img,
    BundleDescriptionSectionTitle as Title,
    FlexCenter,
} from '../../../Style'; 

export const BundleDescriptionSection = ({ description, title, icon }) => {
    return (
        <Container>
            <Wrapper>
                <FlexCenter>
                    <ImgWrapper>
                        <Img src={icon} alt={title} />
                    </ImgWrapper>
                    <Title>{title}</Title>
                </FlexCenter>
                {description}
            </Wrapper>
        </Container>
    )
}