import React, { Component, Fragment } from 'react';
import * as images from '../../../assets';
import '../../../Style/header.css'
import {
    LandingNav as Nav,
    TopRow as Body,
    NavLogo
} from '../../../Style';
import { MobileMenu, DesktopMenu, DocModal, SDPartnerLogos } from '../';
import { OutageBanner } from '../Alerts/OutageBanner';
import { featureFlags } from '../../../config';

export class LandingNav extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.showDoc = this.showDoc.bind(this);
        this.adjustSpaceTop = this.adjustSpaceTop.bind(this);
    }

    componentDidMount() {
        if(featureFlags.block_signups)
            window.addEventListener('scroll', this.adjustSpaceTop, false);

    }

    componentWillUnmount() {
        if(featureFlags.block_signups)
            window.removeEventListener('scroll', this.adjustSpaceTop, false);
    }

    componentDidUpdate() {
        this.adjustSpaceTop()
    }

    adjustSpaceTop() {
        const element = document.getElementById('outage-banner');
        if(element) {
            const spaceTop = Math.max(element.offsetHeight - window.pageYOffset, 0);
            if(spaceTop !== this.state.spaceTop)
                this.setState({ spaceTop })
        }
    }

    renderDialog() {
        const { doc, showDialog } = this.state;
        if (showDialog && !!doc) {
            return <DocModal doc={doc} hide={() => this.setState({ showDialog: false })} />
        }
    }

    showDoc(doc) {
        this.props.toggleMenu();
        this.setState({ showDialog: true, doc })
    }

    renderMobileMenu() {
        const { history, buttonText, isTablet, isShowingMenu, toggleMenu, next, isSignedIn } = this.props;
        if (isTablet)
            return (
                <MobileMenu
                    history={history}
                    buttonText={buttonText}
                    buttonClick={next}
                    toggleMenu={toggleMenu}
                    isShowingMenu={isShowingMenu}
                    isSignedIn={isSignedIn}
                    showDoc={this.showDoc}
                />
            )
    }

    renderDesktopMenu() {
        const { history, buttonText, isTablet, isShowingMenu, isSignedIn, next, logout } = this.props;
        const { spaceTop } = this.state;
        if (!isTablet)
            return (
                <DesktopMenu
                    history={history}
                    buttonClick={next}
                    buttonText={buttonText}
                    toggleMenu={this.props.toggleMenu}
                    isShowingMenu={isShowingMenu}
                    isSignedIn={isSignedIn}
                    logout={logout}
                    spaceTop={spaceTop}
                />
            )
    }

    renderLogos() {
        const { isMobile, utm_campaign, history } = this.props;
        const { search } = window.location;
        if (isMobile && utm_campaign) {
            if (utm_campaign.includes('fetch')) {
                return <SDPartnerLogos partner="fetch" />
            } else if (utm_campaign.includes('localeur')) {
                return <SDPartnerLogos style={{ marginTop: -20 }} partner="localeur" />
            } else {
                return <NavLogo src={images.logo} onClick={() => history.push({ pathname: '/', search })} alt="Supply Drop Home" />
            }
        } else {
            return <NavLogo src={images.logo} onClick={() => history.push({ pathname: '/', search })} alt="Supply Drop Home" />
        }
    }

    render() {
        return (
            <Fragment>
                <OutageBanner />
                <Nav spaceTop={featureFlags.block_signups ? this.state.spaceTop : null}>
                    <Body landingNav={true}>
                        {this.renderLogos()}
                        {this.renderDialog()}
                        {this.renderMobileMenu()}
                        {this.renderDesktopMenu()}
                    </Body>
                </Nav>
            </Fragment>
        );
    }
}
