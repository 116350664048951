import React, {Component, Fragment} from 'react';
import { 
    CustomerReferralContainer as Container, 
    CustomerReferralTextContainer as TextContainer,
    CustomerReferralButton as Button,
    CustomerReferralImgContainer as ImgContainer,
    CustomerReferralSubHeadline as SubHeadline,
    CustomerReferralTerms as Terms,
    CustomerReferralSocialContainer as SocialContainer
} from '../../../Style';
import '../../../Style/dashboard.css';
import { 
    Heading5
} from '../../../Style/DesignSystem/Typography'
import { theme } from '../../../Style/DesignSystem/Theme'
import * as images from '../../../assets';
import { Grid, Snackbar, IconButton, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    CustomerReferralInfo,
    DocModal,
    FacebookShare,
    NavigationShare,
    ShareTwitter
} from '../../Common';
import ReactGA from 'react-ga';

class CustomerReferral extends Component{
    constructor(props){
        super(props)
        this.state = {
            copyMessage: 'Copy',
            showDialog: false,
            doc: undefined,
            setSnackbarOpen: false,
            textToShare: "Join Supply Drop and we will both get a $20 credit! Sign up with my link. "
        }

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
        this.renderSnackbar = this.renderSnackbar.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.renderNonMobile = this.renderNonMobile.bind(this);
        this.renderMobile = this.renderMobile.bind(this);
    }

    componentWillMount() {
        document.addEventListener('copy', this.addToClipboard, false)
    }

    componentWillUnmount() {
        document.removeEventListener('copy', this.addToClipboard, false)
    }

    copyToClipboard(e) {
        const { referralLink } = this.props
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copyMessage: 'Copied!', setSnackbarOpen: true });
        setTimeout(() => {
            this.setState({ copyMessage: 'Copy', setSnackbarOpen: false });
        }, 3000);
        ReactGA.event({
            category: 'DashboardUIEvents',
            action: 'Copied Referral Link',
            label: `${referralLink}`,
        })
    };

    renderInfo() {
		const { showInfo } = this.state;
        if(showInfo) {
            return (
                <CustomerReferralInfo
                    close={() => this.setState({showInfo: false})}
                    next={() => this.setState({showInfo: false})}
                />
            )
        }
    }

    renderDialog() { 
        const {doc, showDialog} = this.state;
        if(showDialog && !!doc) {
            return <DocModal doc={doc} hide={this.hideDialog}/>
        }
    }

    showDialog(doc) {
        this.setState({ showDialog: true, doc })
    }

    hideDialog() {
        this.setState({ showDialog: false, doc: undefined })
    }

    handleSnackbarClose(event, reason) {
        this.setState({ setSnackbarOpen: false })
    }

    renderSnackbar() {
        const { setSnackbarOpen } = this.state;
        return (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={setSnackbarOpen}
                    autoHideDuration={3000}
                >
                    <SnackbarContent
                        style={{
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText
                        }}
                        message={<span id="client-snackbar">Copied to clipboard!</span>}
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                      <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    />
                </Snackbar>
        )
    }

    renderForm() {
        const { referralLink } = this.props;
        const referralCopy = `Join Supply Drop and we will both get a $20 credit! Sign up with my link.\n${referralLink}`
        return(
            <form id="textToCopy" noValidate autoComplete="off" style={{cursor: "text"}}>
                <textarea
                    ref={(textarea) => this.textArea = textarea}
                    value={referralCopy}
                    readOnly
                    className="referralTextarea"
                />
            </form>
        )
    }

    renderMobile() {
        const { copyMessage, textToShare } = this.state;
        const { referralLink } = this.props;
        return(
            <div>
            <Grid container spacing={0}>
                <Grid item xs={4} >
                    <ImgContainer>
                        <img src={images.referral_present} alt="close" style={{maxWidth: "100%"}}/>
                    </ImgContainer>
                </Grid>
                <Grid item xs={8} >
                    <div style={{marginLeft: "10px"}}>
                        <Heading5 style={{textAlign: "left"}}>Give $20, Get $20</Heading5>
                        {this.renderInfo()}
                        <SubHeadline>Invite your friends in Texas and you will <b>both earn $20</b> when they sign up.
                            <div className="link" onClick={() => this.setState({showInfo: true})}>Learn More.</div>
                        </SubHeadline>
                        <div style={{height: "5px"}}></div>
                    </div>
                </Grid>
            </Grid>
            <div>
                <div>
                    {this.renderForm()}
                    {this.renderSnackbar()}
                    <Grid container spacing={0}>
                        <Grid item xs={8}>
                            <SocialContainer>
                                <FacebookShare urlToShare={referralLink} textToShare={textToShare}/>
                                <span style={{paddingLeft: 20}}></span>
                                <ShareTwitter urlToShare={referralLink} textToShare={textToShare}/>
                                <span style={{paddingLeft: 20}}></span>
                                { navigator.share && <NavigationShare
                                    urlToShare={referralLink}
                                    textToShare={textToShare}
                                    title="Supply Drop Referral Program"
                                /> }
                            </SocialContainer>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={this.copyToClipboard}>{copyMessage}</Button>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    {this.renderDialog()}
                    <Terms>By using your referral link you agree to our </Terms>
                        <div className="link" style={{fontSize: 12, textAlign: "center"}}
                            onClick={() => this.showDialog('Referral_Terms_and_Conditions')}>Terms and Conditions.
                        </div>
                </div>
            </div>
            </div>
        )
    }

    renderNonMobile() {
        const { copyMessage, textToShare } = this.state
        const { referralLink } = this.props;
        return(
            <Grid container spacing={0}>
                <Grid item lg={2}>
                    <ImgContainer>
                        <img src={images.referral_present} alt="close" style={{maxWidth: "100%"}}/>
                    </ImgContainer>
                </Grid>
                <Grid item lg={10}>
                    <TextContainer>
                        <Heading5 style={{textAlign: "left"}}>Give $20, Get $20</Heading5>
                        {this.renderInfo()}
                        <div>
                            <SubHeadline>Invite your Austin friends and you will <b>both earn $20</b> when they sign up.</SubHeadline>
                            <div className="link" onClick={() => this.setState({showInfo: true})}>Learn More.</div>
                            <div style={{height: "20px"}}></div>
                        </div>
                        <Grid container spacing={0} >
                            <Grid item md={9}>
                                {this.renderForm()}
                                {this.renderSnackbar()}
                                <SocialContainer>
                                    <FacebookShare urlToShare={referralLink} textToShare={textToShare}/>
                                    <span style={{paddingLeft: 20}}></span>
                                    <ShareTwitter
                                        urlToShare={referralLink}
                                        textToShare={textToShare}
                                    />
                                    <span style={{paddingLeft: 20}}></span>
                                    { navigator.share && <NavigationShare
                                        urlToShare={referralLink}
                                        textToShare={textToShare}
                                        title="Supply Drop Referral Program"
                                    /> }
                                </SocialContainer>
                            </Grid>
                            <Grid item md={3}>
                                <Button onClick={this.copyToClipboard}>{copyMessage}</Button>
                            </Grid>
                        </Grid>
                        <div>
                            {this.renderDialog()}
                            <Terms>By using your referral link you agree to our
                                <span className="link" onClick={() => this.showDialog('Referral_Terms_and_Conditions')}> Terms and Conditions.</span>
                            </Terms>
                        </div>
                    </TextContainer>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { isUnverified, limitExceeded, isMobile } = this.props
        return (
            <div className={`${isUnverified && !limitExceeded ? "with-banner" : ""}`}>
            <Container>
                {isMobile ? this.renderMobile() : this.renderNonMobile()}
            </Container>
            </div>
        )
    }
}

export default CustomerReferral;
