import React, {Component} from 'react';
import {postFeedback} from '../../../actions';
import { connect } from 'react-redux';
import {SingleChoiceModal} from '../../Common';
import { TextareaPrimary } from '../../../Style';
import ReactGA from 'react-ga';

class Feedback extends Component{
    constructor(props) {
        super(props);

        this.state = {
            feedback: '',
            liked: true
        };

        this.sendFeedback = this.sendFeedback.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.close = this.close.bind(this);
    }

    handleChange(change) {
        this.setState({feedback: change});
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    componentDidUpdate() {
        const {feedback, error, dashboard, showFeedback} = this.props;
        if(this.state.loading && feedback) {            
            setTimeout(() => {
                if(dashboard) this.closeDashboardFeedback()
                this.setState({loading: false, active: false});
                ReactGA.pageview(window.location.pathname + window.location.search);
            }, 3000);
        }

        if(this.state.loading && error) {
            setTimeout(() => {
                if(dashboard) this.closeDashboardFeedback()
                this.setState({loading: false, active: false});
                ReactGA.pageview(window.location.pathname + window.location.search);
            }, 3000);
            console.error(error);
        }
        if(dashboard && showFeedback !== this.state.active) {
            this.setState({active: showFeedback})
            if(showFeedback) ReactGA.pageview('/feedback');
            else ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    close() {
        const {dashboard} = this.props;
        if(dashboard)
            this.closeDashboardFeedback();
        else
            this.setState({active: false});
    }

    handleKeydown(e) {
        if(e.keyCode && e.keyCode === 27) {
            this.close()
        }
    }

    sendFeedback() {
        const {feedback, liked} = this.state;
        const {question} = this.props;
        if(feedback) {
            this.props.postFeedback(`${question} ${new Date()}`, liked, feedback);
            this.setState({loading: true, feedback: ""})
        }
    }

    closeDashboardFeedback() {
        this.props.close();
    }

    renderSupportCopy() {
        if(window.location.pathname.includes('dashboard')) {
            return (
                <p>
                    If you need to reach customer support to modify, pause or cancel your account, please <a href="sms://30389">text us at 30389</a>.
                </p>
            )
        }
    }

    render() {
        const {active, loading} = this.state;
        const {dashboard, question} = this.props;

        if(!active && !dashboard) {
            return (
                <div className={`feedback-container${question === "order-complete" ? " complete" : ""}`}>
                    <div className="feedback-btn-container">
                        <div onClick={() => this.setState({active: true})} className="interview-feedback-btn"><span className="interview-feedback-icon">[+]</span>FEEDBACK</div>
                    </div>
                </div>
            )
        } else if (!active && dashboard) {
            return <div></div>
        } else {
            if(loading) {
                return (
                    <div className="modal-container light">
                        <div className="interview-inner feedback rounded">
                            <div className="interview-feedback-container">
                                <h2 className="interview-question-header">Thank you for your feedback!</h2>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                <SingleChoiceModal
                    title="Feedback"
                    body={
                    <div>
                        <p>
                            Part of being in Beta is learning a lot from our customers.
                            Please provide any feedback on the questions and sign-up process.
                            And yes, we want to hear it all!
                        </p>
                        {this.renderSupportCopy()}
                        <TextareaPrimary
                            type="text"
                            placeholder="I love it so far..."
                            value={this.state.feedback}
                            onChange={(e) => this.handleChange(e.target.value)}
                        />
                    </div>
                    }
                    buttonCopy="Send Feedback"
                    cancelCopy="Cancel"
                    close={this.close}
                    next={this.sendFeedback}
                />
                )
            }
        }
    }
}

const mapStateToProps = (state) => {
	const { feedback, error } = state.interview;
	return { feedback, error };
}

export default connect(mapStateToProps, {
	postFeedback
})(Feedback);
