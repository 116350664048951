import React from 'react';
import { Helmet } from 'react-helmet';

export const MetaTags = ({
    title,
    description,
    robots,
    og,
    twitter
}) => {
    const renderTitle = () => {
        if (title) return <title>{title}</title>
    }

    const renderDescription = () => {
        if (description) return <meta name="description" content={description} />
    }

    const renderRobots = () => {
        if (robots)
            return <meta name="robots" content={robots} />
        else
            return <meta name="robots" content="noindex" />
    }

    const renderTwitterTags = () => {
        if(twitter) {
            const tags = Object.entries(twitter).map(entry => {
                const name = entry[0];
                const content = entry[1];

                return <meta property={`twitter:${name}`} content={content} />
            })         

            return tags;
        }
    }

    const renderOG = () => {
        if (og) {
            const tags = Object.entries(og).map(entry => {
                const name = entry[0];
                const content = entry[1];

                if(
                    name === 'secure_url' ||
                    name === 'width' ||
                    name === 'height'
                ) {
                    return <meta property={`og:image:${name}`} content={content} />
                } else {
                    return <meta property={`og:${name}`} content={content} />
                }
            })         

            return tags;
        }
    }

    return (
        <Helmet>
            {renderTitle()}
            {renderDescription()}
            {renderOG()}
            {renderTwitterTags()}
            {renderRobots()}
        </Helmet>
    )
}