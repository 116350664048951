import React, { Component } from 'react';
import { InterviewQuestion } from '../../Common';
import * as images from '../../../assets';
import { marginForCountdown } from '../../../actions';

class Cooking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cookingFrequency: 1,
            infoCopy: (
                <div>
                    The amount you cook has a direct impact on the amount of kitchen supplies you use.
                </div>
            ),
            image: {
                src: images.cookingFrequency,
                alt: "Cooking"
            }
        };

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.interview && !this.state.interview) {
            this.setState({ interview: this.props.interview })
        }

        this.setState({ isModal: this.props.isModal });

        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { interview } = signUpData;
            if (interview) {
                this.setStateForKeyValue(interview);
                const { bathrooms } = interview;
                if (bathrooms)
                    this.setState({ bathrooms })

                this.setState({ interview })
            } else {
                this.back();
            }

            if (signUpData.zipcode)
                marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        const { signUpData } = this.state;
        this.props.history.push('/start/4', { signUpData })
    }

    next() {
        const {
            cookingFrequency,
            isModal,
            interview,
            signUpData
        } = this.state;

        interview.cookingFrequency = cookingFrequency;
        if (isModal) this.props.next({ cookingFrequency })
        else {
            signUpData.interview = interview
            this.props.history.push('/kitchen/2', { signUpData });
        }
    }

    decrement() {
        let { cookingFrequency } = this.state;
        if (cookingFrequency > 0) cookingFrequency -= 1;
        this.setState({ cookingFrequency })
    }

    increment() {
        let { cookingFrequency } = this.state;
        cookingFrequency += 1;
        this.setState({ cookingFrequency })
    }

    render() {
        const { cookingFrequency, isModal, image, infoCopy } = this.state;
        const { history, close } = this.props;

        const counter = {
            increment: this.increment,
            decrement: this.decrement,
            min: 0,
            max: 7,
            count: cookingFrequency
        }

        return (
            <InterviewQuestion
                key="Cooking"
                id="Cooking"
                back={isModal ? this.props.back : this.back}
                next={this.next}
                close={close}
                history={history}
                image={image}
                question="How many nights a week do you cook at home?"
                title="Supply Drop - Personalization - Kitchen & Cleaning"
                isModal={isModal}
                counter={counter}
                infoCopy={infoCopy}
            />
        );
    }
}

export default Cooking;