export const
    arrow_left_mobile = require('./arrow_left_mobile.svg'),
    arrow_right_mobile = require('./arrow_right_mobile.svg'),
    arrow_left_desktop = require('./arrow_left_desktop.svg'),
    arrow_right_desktop = require('./arrow_right_desktop.svg'),
    arrow_top = require('./arrow_top.svg'),
    better_way_right = require('./better_way_right.png'),
    better_way_left = require('./better_way_left.png'),
    home_hexagons = require('./home_hexagons.png'),
    honeycomb = require('./honeycomb.svg'),
    loop_house = require('./loop_house.svg'),
    loop_checklist = require('./loop_checklist.svg'),
    loop_family = require('./loop_family.jpg'),
    loop_touch = require('./loop_touch.svg'),
    science_atom = require('./science_atom.png'),
    science_hero_desktop = require('./science_hero_desktop.png'),
    science_hero_mobile = require('./science_hero_mobile.png'),
    science_pattern = require('./science_pattern.png'),
    science_pie = require('./science_pie.png'),
    science_stacked_bar = require('./science_stacked_bar.png'),
    science_structure = require('./science_structure.svg'),
    science_tailored_phone = require('./science_tailored_phone.png'),
    science_text_us = require('./science_text_us.png')