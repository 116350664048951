import React, { Component } from 'react';
import { facebook_logo } from '../../../assets';
import ReactGA from 'react-ga';


export class FacebookShare extends Component {
    constructor(props){
        super(props);
        this.state = {}
        this.shareOnFacebookPopUp = this.shareOnFacebookPopUp.bind(this)
    }

    shareOnFacebookPopUp() {
      const { textToShare, urlToShare } = this.props;

      window.FB.ui({
            display: 'popup',
            method: 'share',
            quote: textToShare,
            href: urlToShare,
      }, function(response){});

      ReactGA.event({
          category: 'DashboardUIEvents',
          action: 'Clicked Share via Facebook',
          label: `${urlToShare}`,
      })
    }

    facebookLinkToRender(){
        const { shareProfile, urlToShare } = this.props;
        if (shareProfile) {
            return (
                <a target="_blank"
                    href={urlToShare}
                    rel="noopener noreferrer"
                >
                    <img src={facebook_logo} alt="facebook_logo"/>
                </a>
            )
        } else {
            return (<img src={facebook_logo} alt="facebook_logo" onClick={() => this.shareOnFacebookPopUp()}/> )
        }
    }

    render() {
        return(
            <span style={{cursor: "pointer"}}>
                {this.facebookLinkToRender()}
            </span>
        )
    }
}