import React from 'react';
import {SingleChoiceModal} from './SingleChoiceModal'

export const CustomerReferralInfo = (props) => {
        const { close } = props;
        const moreInfoCopy =  "Give your friends and family $20 to use on Supply Drop. " +
            "When they place their first order, you'll earn a $20 credit as well - it's a win-win!"


        return(
                <SingleChoiceModal
                    title="Supply Drop Referral Program"
                    body={moreInfoCopy}
                    buttonCopy="Got it, thanks!"
                    close={close}
                    next={close}
                />
        )
}



