import React, {Component, Fragment} from 'react';
import {Nav, MetaTags} from '../Common';
import * as images from '../../assets';

class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <MetaTags title="Supply Drop - Page Not Found" />
                <Nav pathname="/" />
                <div className="interview-outer">
                    <div className="interview-inner rounded">
                    <div className="btn-container">
                        <img src={images.not_found} alt="404" className="img-404-tp" />
                    </div>
                        <h1 className="landing-main-header">Oops! That page can't be found.</h1>
                        <p className="interview-copy">The page you are looking for may have been removed,
                            had its name changed or is temporarily unavailable.</p>
                            <div className="btn-container">
                                <button className="btn-blue" id="btn-404" onClick={() => this.props.history.push('/')}>Go to homepage</button>
                            </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default NotFound;
