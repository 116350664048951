import React, { Component, Fragment } from 'react';
import { formatProductType, getUnits, sortEveryday } from '../../../actions';
import { Row, Col } from 'react-bootstrap';
import { bundles } from '../../../data';
import { ManifestBundleHeader } from '../';
import { SummaryTestimonials, ValueProps } from '../';
import * as images from '../../../assets';
import {
    round, getGrandTotal, getDiscountDollars,
    getCreditDollars, getDiscountString, getRemainingReferral
} from '../../../helpers/pricing';
import { CustomerReferralInfo } from "../../Common"
import { shippingChargeCents, freeShippingMinCents } from '../../../data/ShippingCharges';

export class OrderSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {}

        this.setSubtotal = this.setSubtotal.bind(this);
        this.toggleBundle = this.toggleBundle.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    componentDidMount() {
        this.handleResize();
    }

    componentDidUpdate() {
        const { tax } = this.props;
        if (tax && !this.state.tax) {
            this.setState({ tax });
        }
    }

    handleResize(e) {
        const { isMobile } = this.state;
        if (window.innerWidth < 992 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 991 && isMobile) {
            this.setState({ isMobile: false })
        }
    }

    displayBundles() {
        const { manifestBundles } = this.props;
        if (manifestBundles) {
            let totals = 0
            const rows = [];
            Object.entries(manifestBundles).forEach(entry => {
                const bundleShortName = entry[0];
                const { productGroups, subtotal } = entry[1];
                if (subtotal) {
                    rows.push(
                        <Fragment key={entry}>
                            <ManifestBundleHeader
                                title={bundles[bundleShortName].title}
                                bundleShortName={bundleShortName}
                                subtotal={subtotal}
                                onHeaderClick={() => this.toggleBundle(bundleShortName)}
                                hideBundle={this.state[`hide_${bundleShortName}`]}
                                isDetailsView={true}
                            />
                            {this.renderProductGroupRows(productGroups, bundleShortName)}
                        </Fragment>
                    )
                    totals += subtotal;
                }
            })
            this.setSubtotal(totals)
            return rows;
        } else {
            return <Fragment />
        }
    }

    setSubtotal(subtotal) {
        if (subtotal !== this.state.subtotal) {
            this.setState({ subtotal });
        }
    }

    toggleBundle(bundleShortName) {
        this.setState({ [`hide_${bundleShortName}`]: !this.state[[`hide_${bundleShortName}`]] })
    }

    renderProductGroupRows(productGroups, bundleShortName) {
        if (!this.state[`hide_${bundleShortName}`]) {
            const productGroupsArr = sortEveryday(Object.values(productGroups));
            const rows = productGroupsArr.map(productGroup => {
                const { brandName, pricePerArticle, articleType, articles, productType, displayUnits, groupName } = productGroup;
                const formattedType = formatProductType(productType);
                const singleUnit = getUnits(articleType, 1, true);
                const priceDollars = pricePerArticle / 100;
                return (
                    <Row key={groupName} className="price-details-row">
                        <Col xs={8}>
                            <div className="price-details-product-name"><b>{formattedType}</b> {brandName}</div>
                            <div className="price-details-pricing-breakdown">({articles} x ${(priceDollars).toFixed(2)} per {!!displayUnits ? displayUnits : singleUnit})</div>
                        </Col>
                        <Col xs={4} className="price-details-line-price">
                            ${(priceDollars * articles).toFixed(2)}
                        </Col>
                    </Row>
                )
            })
            return (
                <div className="price-details-products-container">
                    {rows}
                </div>
            )
        }
    }

    renderCoupon() {
        const { coupon, newCoupon, isEmployeePerk, employer, sdCredit, showReferral } = this.props;
        const { subtotal } = this.state;

        if (subtotal) {
            let discountDollars = getDiscountDollars(coupon, newCoupon, subtotal / 100);
            let discountCredit = getCreditDollars(sdCredit, subtotal / 100, discountDollars ? discountDollars : 0)
            let title = "Discount "
            let remainingReferralBalance = getRemainingReferral(sdCredit, discountCredit)
            let creditTitle = remainingReferralBalance ? `Referral Credit (Remaining balance: $${round(remainingReferralBalance).toFixed(2)}):`
                : `Referral Credit:`
            const discountString = getDiscountString(newCoupon);

            if (isEmployeePerk && employer) {
                title = `${employer.displayName} pays `
            }
            if (discountString)
                title += discountString;

            if (discountDollars && showReferral) {
                return (
                    <div>
                        <Fragment>
                            <Row className="price-details-row coupon">
                                <Col xs={8} className="price-details-subtotal-label coupon">{title}</Col>
                                <Col xs={4} className="price-details-subtotal-value text-right coupon">-${round(discountDollars).toFixed(2)}</Col>
                            </Row>
                            <hr className="price-details-hr" />
                        </Fragment>
                        <Fragment>
                            <Row className="price-details-row coupon">
                                <Col xs={8} onClick={() => this.setState({ showInfo: true })} className="price-details-subtotal-label coupon credit">{creditTitle}</Col>
                                <Col xs={4} className="price-details-subtotal-value text-right coupon">-${round(discountCredit).toFixed(2)}</Col>
                            </Row>
                            <hr className="price-details-hr" />
                        </Fragment>
                    </div>
                )
            } else if (discountDollars && !showReferral) {
                return (
                    <div>
                        <Fragment>
                            <Row className="price-details-row coupon">
                                <Col xs={8} className="price-details-subtotal-label coupon">{title}</Col>
                                <Col xs={4} className="price-details-subtotal-value text-right coupon">-${round(discountDollars).toFixed(2)}</Col>
                            </Row>
                            <hr className="price-details-hr" />
                        </Fragment>
                    </div>
                )
            } else if (!discountDollars && showReferral) {
                return (
                    <div>
                        <Fragment>
                            <Row className="price-details-row coupon">
                                <Col xs={8} onClick={() => this.setState({ showInfo: true })} className="price-details-subtotal-label coupon credit">{creditTitle}</Col>
                                <Col xs={4} className="price-details-subtotal-value text-right coupon">-${round(discountCredit).toFixed(2)}</Col>
                            </Row>
                            <hr className="price-details-hr" />
                        </Fragment>
                    </div>
                )
            }
        }
    }

    renderTax() {
        const { tax } = this.props;
        if (tax) {
            return (
                <Fragment>
                    <Row className="price-details-row">
                        <Col xs={8} className="price-details-subtotal-label">Tax:</Col>
                        <Col xs={4} className="price-details-subtotal-value text-right">${(tax / 100).toFixed(2)}</Col>
                    </Row>
                    <hr className="price-details-hr" />
                </Fragment>
            )
        }
    }

    renderSubtotal() {
        const { subtotal } = this.state;
        if (subtotal) {
            return (
                <Fragment>
                    <Row className="price-details-row">
                        <Col xs={8} className="price-details-subtotal-label">Subtotal:</Col>
                        <Col xs={4} className="price-details-subtotal-value text-right">${(subtotal / 100).toFixed(2)}</Col>
                    </Row>
                    <hr className="price-details-hr" />
                </Fragment>
            )
        }
    }

    renderTotals() {
        const { subtotal } = this.state;
        return (subtotal ?
            <Fragment>
                {this.renderSubtotal()}
                {this.renderShippingCharges()}
                {this.renderCoupon()}
                {this.renderTax()}
                {this.renderGrandTotal()}
            </Fragment>
            :
            <Fragment>
                {this.renderGrandTotal()}
            </Fragment>

        )
    }

    renderShippingCharges() {
        const { subtotal } = this.state
        const { chargeShipping } = this.props;
        let chargeString = "Free";
        if (chargeShipping && shippingChargeCents && subtotal < freeShippingMinCents) {
            chargeString = `$${(shippingChargeCents / 100).toFixed(2)}`
        }
        return (
            <Fragment>
                <Row className="price-details-row">
                    <Col xs={8} className="price-details-subtotal-label">Shipping:</Col>
                    <Col xs={4} className="price-details-subtotal-value text-right">{chargeString}</Col>
                </Row>
                <hr className="price-details-hr" />
            </Fragment>
        )
    }

    renderGrandTotal() {
        const {
            chargeShipping,
            coupon,
            newCoupon,
            sdCredit,
            tax,
        } = this.props;

        const { subtotal } = this.state;

        if (subtotal !== undefined) {
            const { label, total, error } = getGrandTotal(subtotal, tax, coupon, newCoupon, sdCredit, chargeShipping);
            if (!error) {
                return (
                    <Row className="price-details-row">
                        <Col xs={8} className="price-details-total-label">{label}:</Col>
                        <Col xs={4} className="price-details-total-value text-right">${total.toFixed(2)}</Col>
                    </Row>
                )
            }
        }
    }

    renderSubRows() {
        const { subRows } = this.props;
        if (subRows) {
            return subRows
        }
    }

    renderValueProps() {
        const { showValueProps, isModal } = this.props;
        if (showValueProps && !isModal) {
            return (
                <Fragment>
                    <ValueProps isCheckout={true} />
                    <SummaryTestimonials />
                </Fragment>
            )
        }
    }

    renderInfo() {
        const { showInfo } = this.state;
        if (showInfo) {
            return (
                <CustomerReferralInfo
                    close={() => this.setState({ showInfo: false })}
                    next={() => this.setState({ showInfo: false })}
                />
            )
        }
    }

    render() {
        const { close, isModal, orderComplete } = this.props;
        return (
            <div className={isModal ? "modal-container white" : orderComplete ? "oc-cart-summary" : "desktop-cart-summary"}>
                {this.renderInfo()}
                <div className="price-details-container">
                    <div className={`price-details-header${isModal ? "" : " not-modal"}`}>
                        <div className="price-details-header-text">Order Summary</div>
                        <img className={isModal ? "price-details-close" : "hide"} onClick={close} src={images.btn_close_x} alt="close" />
                    </div>
                    {this.displayBundles()}
                    {this.renderTotals()}
                </div>
                {this.renderSubRows()}
                {this.renderValueProps()}
            </div>

        )
    }
}