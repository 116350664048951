import React, { Component, Fragment } from 'react';
import '../../../Style/fixedContinueBtn.css'
import { ContinueButton } from './'
import { getGrandTotal, getDiscountString } from '../../../helpers/pricing';
import {
    OrderSummary,
    DocModal
} from '../'
import {
    DiscountsCol,
    TotalsCol,
    TotalText,
    DiscountedSubtotalText,
    DiscountText,
    FixedBarData,
    FixedBarCol
} from '../../../Style/FixedBar';
import { FlexRowCenter } from '../../../Style';

export class FixedContinueBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOrderSummary: false,
            subtotal: 0,
            showDialog: false,
            doc: undefined
        }
        this.toggleOrderSummary = this.toggleOrderSummary.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.setItemCount = this.setItemCount.bind(this);
    }

    componentDidMount() {
        const { manifestBundles } = this.props;
        if (manifestBundles) {
            this.setTotals();
            this.setItemCount(manifestBundles);
        }
    }

    componentDidUpdate() {
        const { manifestBundles, tax } = this.props;

        if (this.props.refreshTotals) {
            this.setTotals();
            this.setItemCount(manifestBundles);
        }

        if (manifestBundles && !this.state.manifestBundles) {
            this.setState({ manifestBundles })
            this.setTotals();
            this.setItemCount(manifestBundles);
        }

        if (tax !== undefined && tax !== this.state.tax) {
            this.setState({ tax })
            this.setTotals(tax);
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    setTotals(tax) {
        const { manifestBundles, coupon, newCoupon, sdCredit } = this.props;
        if (manifestBundles) {
            let subtotalCents = 0;
            Object.values(manifestBundles).forEach(bundle => {
                subtotalCents += bundle.subtotal;
            })

            const { total, error, label, discountDollars, discountCredit, subtotalDollars } = getGrandTotal(subtotalCents, tax, coupon, newCoupon, sdCredit)
            if (!error) {
                this.setState({ total, label, discountCredit, discountDollars, subtotalDollars, newCoupon, sdCredit });
            }
        }
    }

    setItemCount(manifestBundles) {
        if (manifestBundles) {
            let itemCount = 0;
            Object.values(manifestBundles).forEach(bundle => {
                const { productGroups } = bundle;
                itemCount += Object.keys(productGroups).length
            })
            this.setState({ itemCount })
        }
    }

    toggleOrderSummary() {
        const { showOrderSummary } = this.state;
        if (showOrderSummary)
            this.removeBodyNoScroll();
        else
            this.appendBodyNoScroll();

        this.setState({ showOrderSummary: !showOrderSummary })
    }

    handleKeydown(e) {
        const { showOrderSummary } = this.state;
        if (e.keyCode && (e.keyCode === 13 || e.keyCode === 27)) {
            if (showOrderSummary) {
                this.setState({ showOrderSummary: false })
            }
        }
    }

    appendBodyNoScroll() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('no-scroll');
    }

    removeBodyNoScroll() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
    }

    renderOrderSummary() {
        const { employer } = this.props;
        const { showOrderSummary } = this.state;
        const {
            manifestBundles,
            tax,
            coupon,
            isEmployeePerk,
            newCoupon,
            sdCredit,
            showReferral
        } = this.props;
        if (showOrderSummary) {
            return (
                <OrderSummary
                    manifestBundles={manifestBundles}
                    close={this.toggleOrderSummary}
                    isModal={true}
                    tax={tax}
                    coupon={coupon}
                    employer={employer}
                    isEmployeePerk={isEmployeePerk}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    showReferral={showReferral}
                />
            )
        }
    }

    renderCta() {
        const { cta, text, next, manifestBundles, disabled, loading, isDashboard } = this.props;
        if (cta) {
            return cta();
        } else if (!isDashboard) {
            return <ContinueButton next={next} loading={loading} disabled={!Object.entries(manifestBundles).length || disabled} fixed={true} text={text} />
        }
    }

    renderSkipAll() {
        const { skipAll } = this.props;
        if (skipAll) {
            return (
                <div className="fixed-continue-col">
                    {skipAll}
                </div>
            )
        }
    }

    renderTermsAndPrivacy() {
        const { showTerms, showCustomerRef } = this.props;
        if (showTerms) {
            if (showCustomerRef) {
                return (
                    <div className="fixed-terms-and-privacy">By clicking "Place Order" you agree to our <span onClick={() => this.showDialog('Privacy_Policy')}>Privacy Policy</span>, <span onClick={() => this.showDialog('Terms_of_Use')}>Terms of Use</span> and referral program <span onClick={() => this.showDialog('Referral_Terms_and_Conditions')}>Terms and Conditions</span>.</div>
                )
            } else {
                return (
                    <div className="fixed-terms-and-privacy">By clicking "Place Order" you agree to our <span onClick={() => this.showDialog('Privacy_Policy')}>Privacy Policy</span> and <span onClick={() => this.showDialog('Terms_of_Use')}>Terms of Use</span>.</div>
                )
            }
        }
    }

    renderDialog() {
        const { doc, showDialog } = this.state;
        if (showDialog && !!doc) {
            return <DocModal doc={doc} hide={this.hideDialog} />
        }
    }

    showDialog(doc) {
        this.setState({ showDialog: true, doc })
    }

    hideDialog() {
        this.setState({ showDialog: false, doc: undefined })
    }

    renderDiscounts() {
        const {
            newCoupon,
            discountCredit,
            subtotalDollars
        } = this.state;
        if (newCoupon || discountCredit) {
            if (subtotalDollars) {
                const discountString = newCoupon ? getDiscountString(newCoupon, true) : "";
                return (
                    <FixedBarCol as={DiscountsCol}>
                        {discountString ? <FixedBarData as={DiscountText}>{discountString}{discountCredit ? "+" : ""} </FixedBarData> : null}
                        {discountCredit ? <FixedBarData as={DiscountText}>${(discountCredit).toFixed(2)} off</FixedBarData> : null}
                        <FixedBarData as={DiscountedSubtotalText}>${subtotalDollars.toFixed(2)}</FixedBarData>
                    </FixedBarCol>
                )
            }
        }
    }

    renderTotalsAndDiscount() {
        const { itemCount, total } = this.state;
        if (itemCount !== undefined) {
            return (
                <FlexRowCenter onClick={this.toggleOrderSummary}>
                    {this.renderDiscounts()}
                    <FixedBarCol as={TotalsCol}>
                        <FixedBarData as={TotalText}>{itemCount} Items</FixedBarData>
                        <FixedBarData as={TotalText}>${total.toFixed(2)}</FixedBarData>
                    </FixedBarCol>
                </FlexRowCenter>
            )
        }
    }

    render() {
        const { manifestBundles, showTerms, showCustomerRef } = this.props;
        const { total } = this.state;
        if (!manifestBundles || total === undefined) return <div />
        else return (
            <Fragment>
                {this.renderDialog()}
                {this.renderOrderSummary()}
                <div className={`fixed-continue-btn-container${showTerms ? showCustomerRef ? " with-ref-terms" : " with-terms" : ""}`}>
                    <div className="fixed-continue-btn">
                        <div className="fixed-continue-col text-center">
                            {this.renderTotalsAndDiscount()}
                            {this.renderTermsAndPrivacy()}
                        </div>
                        <div className="fixed-continue-col text-center">
                            {this.renderCta()}
                        </div>
                        {this.renderSkipAll()}
                    </div>
                </div>
            </Fragment>
        )
    }
}
