import React from 'react';
import {
    CadenceCalendarCircle as Circle,
    CadenceCalendarContainer as CalendarContainer,
    CadenceMonths
} from '../../../Style/'
import { CadenceCalendarArrow } from '../../../assets/nav_icons/CadenceCalendarArrow'


export const CadenceCalendar = ({
    months,
    hasArrow,
    onclick
}) => {
    const cadenceCircle = () => {
        return (
            <Circle hasArrow={hasArrow}>
                <CadenceMonths hasArrow={hasArrow}>{months}</CadenceMonths>
                {arrow()}
            </Circle>
        )
    }

    const arrow = () => {
        if (hasArrow) {
            return <CadenceCalendarArrow />
        }
    }

    const containerStyles = {
        height: hasArrow ? 45 : 68,
        width: hasArrow ? 45 : 68
    }

    return (
        <CalendarContainer containerStyles={containerStyles} onClick={onclick}>
            <svg style={containerStyles} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                    <path d="M48.8991 3.86328V7.72535H42.1405V3.86328H18.9681V7.72535H12.2095V3.86328H4.48535V14.484H56.6233V3.86328H48.8991Z" fill="#E34043" />
                    <path d="M12.2095 14.4824H4.48535V55.9997H56.6233V14.4824H48.8991H42.1405H18.9681H12.2095Z" fill="#FFF2E4" />
                    <path d="M40.209 21.2441H38.2779H31.5193H29.5883H22.8297H20.8986H12.209V29.9338V31.8648V38.6235V40.5545V49.2441H20.8986H22.8297H29.5883H31.5193H38.2779H40.209H48.8986V40.5545V38.6235V31.8648V29.9338V21.2441H40.209ZM31.5193 23.1752H38.2779V29.9338H31.5193V23.1752ZM38.2779 38.6235H31.5193V31.8648H38.2779V38.6235ZM22.8297 31.8648H29.5883V38.6235H22.8297V31.8648ZM22.8297 23.1752H29.5883V29.9338H22.8297V23.1752ZM14.14 23.1752H20.8986V29.9338H14.14V23.1752ZM14.14 31.8648H20.8986V38.6235H14.14V31.8648ZM20.8986 47.3131H14.14V40.5545H20.8986V47.3131ZM29.5883 47.3131H22.8297V40.5545H29.5883V47.3131ZM38.2779 47.3131H31.5193V40.5545H38.2779V47.3131ZM46.9676 47.3131H40.209V40.5545H46.9676V47.3131ZM46.9676 38.6235H40.209V31.8648H46.9676V38.6235ZM40.209 29.9338V23.1752H46.9676V29.9338H40.209Z" fill="#FAD292" />
                    <path d="M18.9676 0H12.209V7.72414H18.9676V0Z" fill="#36495E" />
                    <path d="M48.8992 0H42.1406V7.72414H48.8992V0Z" fill="#36495E" />
                    <path d="M29.5887 31.8633H22.8301V38.6219H29.5887V31.8633Z" fill="#693007" />
                    {months}
                </g>
                <defs>
                    <filter id="filter0_d" x="0" y="0" width="68" height="68" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>
            </svg>
            {cadenceCircle()}
        </CalendarContainer>
    )
}