import styled, { keyframes } from 'styled-components';

const fadeInDown = keyframes`
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -50px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
`

const fadeInRight = keyframes`
    0% {
        opacity: 0;
        /*-webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);*/
        -webkit-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
`

const slideInRight = keyframes`
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
`

export const FadeInDown = styled.div`
    animation: 1s ${fadeInDown};
`

export const FadeInRight = styled.div`
    animation: 1s ${fadeInRight};
`

export const SlideInRight = styled.div`
    animation: .5s ${slideInRight};
`