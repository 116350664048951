import React from 'react';
import * as images from '../../../assets';

export const TopRow = ({showInfo, close}) => {
    return (
        <div className="interview-modal-top-row">
            <img src={images.info} onClick={showInfo} className="interview-icon interview-info-icon" alt="Info" />
            <img onClick={close} className="text-right order-x-icon" src={images.btn_close_x} alt="Close" />
        </div>
    )
}