const InterviewQuestions = {
    Household: {
        index: 1
    },
    StaysHome: {
        index: 2
    },
    Bathrooms: {
        index: 3
    },
    TrashPreferences: {
        index: 4
    },
    Cooking: {
        index: 5
    },
    DishwashingFrequency: {
        index: 6
    },
    Cleaning: {
        index: 7
    },
    LaundryFrequency: {
        index: 8
    },
    TierPreferences: {
        index: 9
    },
    Prediction: {
        index: 10
    }
}

export default InterviewQuestions;
