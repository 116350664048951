import React, { Component, Fragment } from 'react';
import { Row } from 'react-bootstrap';
import * as images from '../../../assets';
import Feedback from '../../Common/Connected/Feedback';
import MenuItem from './MenuItem';
import { Hamburger } from '../../Common';
import { Link } from '@material-ui/core';
import { signOut } from '../../../helpers/SignOut';

const menuItems = [
    {
        id: "menu-home-icon",
        pathname: "/dashboard",
        title: "Next Delivery"
    },
    {
        id: "menu-parachute-icon",
        pathname: "/dashboard/orders",
        title: "Past Deliveries"
    },
    {
        id: "menu-edit-icon",
        pathname: "/dashboard/account",
        title: "My Account"
    },
    {
        id: "menu-faq-icon",
        pathname: "/dashboard/faqs",
        title: "FAQs"
    },
    {
        id: "menu-contact-us",
        pathname: "/dashboard/faqs",
        hash: "#faqs-contact-us",
        title: "Contact"
    }
]

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.toggleList = this.toggleList.bind(this);
    }

    toggleList() {
        const { showList } = this.state;
        this.setState({ showList: !showList })
    }

    renderFeedback() {
        const { showFeedback } = this.state;
        return <Feedback showFeedback={showFeedback} dashboard={true} close={() => this.setState({ showFeedback: false, showList: false })} />
    }

    renderMenu() {
        const { showList } = this.state;
        const { dashboardData } = this.props;
        const menu = menuItems.map(item => {
            const { id, pathname, title, hash } = item;
            return (
                <MenuItem
                    key={id}
                    id={id}
                    pathname={pathname}
                    hash={hash}
                    title={title}
                    dashboardData={dashboardData}
                />
            )
        })

        menu.push(
            <Row key="feedback" className="menu-nav-row" onClick={() => this.setState({ showFeedback: true })}>
                <div className="menu-icon" id="menu-feedback-icon">[+]</div>
                <div className="row menu-nav-link">Give Feedback</div>
            </Row>
        )

        menu.push(
            <Fragment key='logout'>
                <hr />
                <Row onClick={() => signOut(this.props.history)} className="menu-nav-row">
                    <div className="menu-icon" id="menu-logout-icon"></div>
                    <div className="row menu-nav-link">Logout</div>
                </Row>
            </Fragment>
        )

        return (
            <Fragment>
                <div id="menu-container-desktop">
                    {this.renderFeedback()}
                    {this.renderMenuHeader()}
                    <nav className="menu-nav-container container">
                        {menu}
                    </nav>
                </div>
                <div id="menu-container-mobile">
                    {this.renderFeedback()}
                    {this.renderMenuHeader(true)}
                    <div className={`menu-mobile-list ${showList ? "" : "hide"}`}>
                        {menu}
                    </div>
                </div>
            </Fragment>
        )
    }

    renderMenuHeader(isMobile) {
        const { search } = window.location;
        if (isMobile) {
            return (
                <Link id="menu-mobile-header" to={{ pathname: "/", search }}>
                    <img src={images.logo} className="pointer" id="menu-logo-mobile" alt="Supply Drop Home" />
                    <div id="menu-hamburger-container"><Hamburger toggleMenu={this.toggleList} /></div>
                </Link>
            )
        } else {
            return (
                <Link className="row" to={{ pathname: "/", search }}>
                    <img src={images.logo} className="pointer" id="menu-logo-desktop" alt="Supply Drop Home" />
                </Link>
            )
        }
    }

    render() {
        return (
            <div id="menu-container">
                {this.renderMenu()}
            </div>
        )
    }
}

export default Menu;