import React from 'react';
import {
    arrow_left_mobile,
    arrow_left_desktop,
    arrow_right_mobile,
    arrow_right_desktop,
    arrow_top,
    loop_checklist,
    loop_family,
    loop_house,
    loop_touch
} from '../../../assets/science';
import {
    TopArrow,
    LeftArrow,
    RightArrow,
    LoopContainer as Container,
    LoopStepContainer as StepContainer,
    LoopIcon as Icon,
    LoopFamily as Family,
    LoopText as Text
} from '../../../Style/ProcessLoop';
import { FlexRowCenter as Row } from '../../../Style/Flex';

export const ProcessLoop = ({ isMobile }) => {
    const arrow_left = isMobile ? arrow_left_mobile : arrow_left_desktop;
    const arrow_right = isMobile ? arrow_right_mobile : arrow_right_desktop;

    const renderStep = ({
        copy,
        img
    }) => {
        const { src, alt } = img;
        return (
            <StepContainer>
                <Icon src={src} alt={alt} />
                <Text>{copy}</Text>
            </StepContainer>
        )
    }

    function renderTopArrow() {
        if(!isMobile) {
            return <TopArrow alt="Arrow" src={arrow_top} />
        }
    }

    function renderHouseStep() {
        return renderStep({
            img: {
                src: loop_house,
                alt: "An intelligent household"
            },
            copy: "We learn about you and what your household needs.",
        })
    }
    
    function renderChecklistStep() {
        return renderStep({
            img: {
                src: loop_checklist,
                alt: "Checklist"
            },
            copy: "We build a personalized plan that ensures you are always stocked with your home essentials.",
        })
    }
    
    function renderTouchStep() {
        return (
            <Row>
                {renderStep({
                    img: {
                        src: loop_touch,
                        alt: "Magic touch"
                    },
                    copy: "We keep in touch to make sure we always deliver what you need, when you need it.",
                })}
            </Row>
        )
    }

    function renderFirstRow() {
        if(isMobile) {
            return  (
                <Row>
                    {renderHouseStep()}
                </Row>
            )
        } else {
            return (
                <Row>
                    {renderHouseStep()}
                    {renderTopArrow()}
                    {renderChecklistStep()}
                </Row>
            )
        }
    }

    function renderSecondRow () {
        if(isMobile) {
            return renderChecklistStep()
        } else {
            return <Family alt="Family" src={loop_family} />
        }
    }

    return (
        <Container>
            {renderFirstRow()}
            {renderSecondRow()}
            {renderTouchStep()}
            <LeftArrow alt="Arrow" src={arrow_left} />
            <RightArrow alt="Arrow" src={arrow_right} />
        </Container>
    )
}