import React, { Component, Fragment } from 'react';
import {
    LandingMain as Main,
    HomeHeroPattern,
    LandingContainer,
    PageDivider,
    RemoteSubtext,
    H2Amaranth as H2,
    colors,
    InvestorContainer, 
    AboutUsHeadingContainer
} from '../../Style';
import {
    Footer,
    LandingNav,
    Hero,
    LandingTwoUpSection,
    ShareTwitter,
    InvestorLogo,
    LinkedinShare
} from '../Common';
import * as images from '../../assets';
import { Heading5, BodyL } from '../../Style/DesignSystem/Typography';
import { Grid } from '@material-ui/core';
import { defaultZipcodes } from '../../data/DefaultZipcodes';
import { featureFlags } from '../../config';
import { parseSearchParams, postProspect, getLanding } from '../../actions';
import { Logger } from '../../helpers/Logger';
import { Auth } from 'aws-amplify';
import Analytics from "../../analytics";
import { connect } from 'react-redux';
const logger = new Logger();

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handleResize = this.handleResize.bind(this);
        this.renderBuseyProfile = this.renderBuseyProfile.bind(this);
        this.renderAprilProfile = this.renderAprilProfile.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onGetStartedClick = this.onGetStartedClick.bind(this);
        this.onDashboardClick = this.onDashboardClick.bind(this);
        this.setFromSearchParams = this.setFromSearchParams.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    componentDidMount() {
        const { search } = window.location;
        this.handleResize(null, true)
        if (this.props.location && this.props.location.state)
            this.setState({ signUpData: this.props.location.state.signUpData });

        Auth.currentAuthenticatedUser().then(data => {
            this.setState({ isSignedIn: true });
            Analytics.setUser(data.username);
            if(search) this.setFromSearchParams(search);
            this.props.getLanding(search)
        }).catch(err => {
            this.setIdentityBreadcrumb();
            if(search) this.setFromSearchParams(search);
            this.props.getLanding(search)
        })
    }

    componentDidUpdate() {
        const { landingData, landingError } = this.props;

        if(landingData && !this.state.landingData)
            this.setState({ landingData, ...landingData })

        if(landingError && !this.state.landingError) {
            this.setState({ landingError })
            logger.error(landingError)
        }
    }

    setIdentityBreadcrumb() {
        Auth.currentCredentials().then(creds => {
            if (creds && creds._identityId) {
                Analytics.setUser(creds._identityId);
            }
        }).catch(err => {
            logger.errorr(err);
        })
    }

    handleResize(e) {
        const { isMobile, isTablet, isJumbo } = this.state;
        if (window.innerWidth < 768 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 767 && isMobile) {
            this.setState({ isMobile: false })
        }
        if (window.innerWidth < 992 && !isTablet) {
            this.setState({ isTablet: true })
        }
        if (window.innerWidth >= 991 && isTablet) {
            this.setState({ isTablet: false })
        }
        if (window.innerWidth >= 1300 && !isJumbo) {
            this.setState({ isJumbo: true})
        }
        if (window.innerWidth < 1300 && isJumbo) {
            this.setState({ isJumbo: false})
        }
    }

    renderFixedNav() {
        const { aptCampaign, zipcode, isTablet, isShowingMenu, isMobile, utm_campaign, isSignedIn } = this.state;
        const buttonText = isSignedIn ? "My Dashboard" : "Get Started";
        return (
            <LandingNav
                history={this.props.history}
                isTablet={isTablet}
                isMobile={isMobile}
                isSignedIn={isSignedIn}
                aptCampaign={aptCampaign}
                spaceTop={zipcode}
                buttonText={buttonText}
                next={this.onGetStartedClick}
                toggleMenu={this.toggleMenu}
                isShowingMenu={isShowingMenu}
                utm_campaign={utm_campaign}
            />
        )
    }

    onGetStartedClick() {
        const {
            coupon,
            dropoffZipcodes,
            employer,
            employerId,
            employerSlug,
            isEmployeePerk,
            isSignedIn,
            landingData,
            newCoupon,
            productGroups,
            productGroupsMap,
            rc,
            refCust,
            referrer,
            sdCredit,
            signupsOpen,
            utm_campaign,
            utm_content,
            utm_source,
            zipcode,
            zipcodes
        } = this.state;

        if (isSignedIn) {
            this.onDashboardClick()
        } else {
            const signUpData = {
                coupon,
                employer,
                employerId,
                employerSlug,
                isEmployeePerk,
                newCoupon,
                productGroups,
                productGroupsMap,
                rc,
                refCust,
                referrer,
                signupsOpen,
                sdCredit,
                utm_campaign,
                utm_content,
                utm_source,
                zipcode,
                zipcodes: zipcodes ? zipcodes : dropoffZipcodes ? dropoffZipcodes : defaultZipcodes
            };

            const pushInterviewFlow = (featureFlags.isUngated && landingData && signupsOpen);
            const pathname = pushInterviewFlow ? '/start/1' : '/how-it-works';

            this.props.history.push({
                pathname,
                search: window.location.search,
                state: { signUpData }
            });
        }
    }

    onDashboardClick() {
        this.props.history.push('/dashboard');
    }

    setFromSearchParams(search) {
        const { searchData, error } = parseSearchParams(search);
        if (!error) {
            const { utm_campaign, utm_content, utm_source, employer, fbclid, rc, refCust } = searchData;

            const referrer = searchData.referrer ? searchData.referrer : document.referrer;

            this.setState({ utm_campaign, utm_content, utm_source, employerSlug: employer, fbclid, rc, refCust, referrer });

            this.props.postProspect({
                campaignId: utm_campaign,
                referrer,
                attributes: {
                    employer,
                    fbclid,
                    rc,
                    refCust,
                    utm_content,
                    utm_source,
                }
            })
        } else {
            logger.error(error)
        }
    }

    toggleMenu() {
        this.setState({ isShowingMenu: !this.state.isShowingMenu })
    }

    renderHeroSection() {
        const {
            isEmployeePerk,
            isMobile,
            isSignedIn,
            isTablet,
            newCoupon,
            sdCredit,
            signUpData,
            utm_campaign,
            utm_content
        } = this.state;
        const { history } = this.props;

        const positionedImages = [
            <HomeHeroPattern key="heroSectionImg" src={images.home_hero_pattern} alt="" isHome={true} />
        ]
        return (
            <Hero
                hideCouponAlert={true}
                coupon={newCoupon}
                sdCredit={sdCredit}
                header="Our mission is simple, to deliver the right amount of  home essential products you love,️ to your door every month."
                heroImg={{
                    alt: "Supply Drop Hero Image - Household Essentials Delivered to Your Door",
                    src: images.about_hero
                }}
                history={history}
                isEmployeePerk={isEmployeePerk}
                isMobile={isMobile}
                isSignedIn={isSignedIn}
                isTablet={isTablet}
                positionedImages={positionedImages}
                raisedSectionStyles={{ zIndex: 1 }}
                signUpData={signUpData}
                utm_campaign={utm_campaign}
                utm_content={utm_content}
                isRaised={true}
                headerStyles={{
                    width: isMobile || isTablet ? "100%" : 500,
                    textAlign: "left",
                    fontSize: isMobile || isTablet ? 36 : 46,
                    position: isMobile || isTablet ? "static" : "absolute",
                    top: 30,
                    left: 200,
                }}
                style={{
                    marginBottom: isMobile || isTablet ? 0 : 60
                }}
                imgContainerStyles={{
                    position: isMobile || isTablet ? "static" : "absolute",
                    top: 0,
                    right: "-190px"
                }}
            />
        )
    }

    renderWeAreSupplyDrop() {
        const { isMobile, isTablet } = this.state;
        return (
            <LandingTwoUpSection
                header="We are Supply Drop"
                headerStyle={{
                    color: colors.titleText
                }}
                style={{
                    paddingLeft: isMobile || isTablet ? 0 : 132,
                }}
                body={(
                    <BodyL>
                        Supply Drop was founded in 2019 in Austin, Texas. We are here to deliver you a magical experience – which means being exceptional at delivering all our customer’s household needs as seamlessly as possible.
                        <br /><br />
                        We strive to communicate with our customers to make this process as easy and natural as possible - we’re here to make your life easier!
                    </BodyL>
                )}
                mainImg={{
                    alt: "Our remote warehouse",
                    src: images.wh1,
                    label: (<BodyL as={RemoteSubtext} style={{color: colors.titleText}}>We are working remote! United in the warehouse via collage.</BodyL>)
                }}
                imgContainerStyles={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    justifyContent: "center",
                    marginLeft: isMobile ? -10 : 0,
                }}
            />
        )
    }

    renderFounders() {
        const { isMobile, isTablet } = this.state;

        return (
            <Fragment>
                <AboutUsHeadingContainer>
                    <LandingTwoUpSection
                        reverseHeader={isMobile || isTablet ? false : true}
                        header="Our Founders"
                        headerStyle={{
                            color: colors.titleText,
                            textAlign: "left",
                            marginBottom: 20
                        }}
                        body={this.renderBuseyProfile()}
                        mainImg={{
                            alt: "",
                            src: images.busey_profile
                        }}
                        isReverse={!isTablet}
                        bodyStyle={{
                            maxWidth: 685
                        }}
                        imgContainerStyles={{
                            width: "40%",
                            textAlign: "left !important",
                            maxWidth: isMobile || isTablet ? "95%" : "100%",
                            paddingLeft: isMobile || isTablet ? 0 : 12,
                        }}
                        bodyContainerStyle={{
                            marginLeft: 0,
                            marginTop: isMobile || isTablet ? 0 : 110
                        }}
                        style={{
                            paddingLeft: 0
                        }}
                    />
                    <LandingTwoUpSection
                        excludeHeader={true}
                        body={this.renderAprilProfile()}
                        mainImg={{
                            alt: "",
                            src: images.april_profile
                        }}
                        bodyStyle={{
                            maxWidth: 685,
                            paddingLeft: isMobile || isTablet  ? 0 : 10
                        }}
                        imgContainerStyles={{
                            width: "40%",
                            paddingLeft: isMobile || isTablet  ? 0 : 80,
                            textAlign: isMobile || isTablet ? "left !important" : "center",
                            maxWidth: isMobile || isTablet ? "95%" : "100%",
                        }}
                        style={{
                            paddingLeft: 0
                        }}
                        bodyContainerStyle={{
                            marginLeft: 0
                        }}
                    />
                </AboutUsHeadingContainer>
            </Fragment>
        )
    }

    renderBuseyProfile() {
        return (
            <Fragment>
                <Heading5 style={{textAlign: "left", maxWidth: 686}}>Andrew Busey</Heading5>
                <BodyL style={{maxWidth: 686}}>
                    Andrew is a serial entrepreneur having co-founded over eight Austin-based companies including Hypergiant, Conversable, Pluck and iChat. Having pioneered some of the most important web technologies, Andrew’s passion is creating products that deliver incredible experiences and value.
                    <br /><br />
                    <b>Favorite brands</b><br />
                    Cottonelle and Bounty<br />
                    <b>Office companions</b><br />
                    Two Siamese Cats<br />
                    <b>Hometown</b><br />
                    Austin (adopted, grew up in Virginia)
                </BodyL>
                {this.renderBuseySocial()}
            </Fragment>
        )
    }

    renderBuseySocial() {
        return (
            <div>
                <ShareTwitter shareProfile={true} urlToShare={"https://twitter.com/andrewbusey"}/> 
                <span style={{paddingLeft: 10}}></span>
                <LinkedinShare shareProfile={true} urlToShare={"https://www.linkedin.com/in/busey/"}/>
            </div>
        )
    }

    renderAprilProfile() {
        return (
            <Fragment>
                <Heading5 style={{textAlign: "left", maxWidth: 686}}>April Downing</Heading5>
                <BodyL style={{maxWidth: 686}}>
                    April is a seasoned financial and operational executive. She’s helped grow companies from start-ups to multi-national businesses including Austin-based WP Engine, Civitas Learning, Dachis Group and Motive. Her passion is helping scale businesses while creating an exceptional employee experience.
                    <br /><br />
                    <b>Favorite brands</b><br />
                    Austin-based brands Puracy, Handzies & Armbrust Face Masks<br />
                    <b>Conference call interrupters</b><br />
                    Lab and Rescue Dog with a VERY loud bark<br />
                    <b>Hometown</b><br />
                    Austin
                </BodyL>
                {this.renderAprilSocial()}
            </Fragment>
        )
    }

    renderAprilSocial() {
        return (
            <div>
                <ShareTwitter shareProfile={true} urlToShare={"https://twitter.com/aprildowning1"}/> 
                <span style={{paddingLeft: 10}}></span>
                <LinkedinShare shareProfile={true} urlToShare={"https://www.linkedin.com/in/aprildowning/"}/>
            </div>
        )
    }

    renderInvestors() {
        return (
            <Fragment>
                <div style={{ marginTop: 80 }}>
                    <H2 style={{color: colors.titleText}}>Our Investors</H2>
                    <BodyL style={{textAlign: "center"}}>Backed by Leading Investors</BodyL>
                    {this.renderInvestorLogos()}
                    <BodyL style={{textAlign: "center", marginBottom: 0, paddingTop: 40}}>PRESS</BodyL>
                    <div style={{textAlign: "center", paddingBottom: 60}}>
                        <a href="https://bizj.us/1q7152" target="_blank" rel="noopener noreferrer">
                            <img src={images.austin_business_journal} alt="austin_business_journal"></img>
                        </a>
                    </div>
                </div>
            </Fragment>
        )
    }

    renderInvestorLogos() {
        return (
            <InvestorContainer>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} sm={6} md={4} justify="center" style={{display: "flex"}}>
                        <InvestorLogo slug={"mark_vc"} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} justify="center" style={{display: "flex"}}>
                        <InvestorLogo slug={"firebrand"} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} justify="center" style={{display: "flex"}}>
                        <InvestorLogo slug={"next_coast_ventures"} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} justify="center" style={{display: "flex"}}>
                        <InvestorLogo slug={"live_oak"} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} justify="center" style={{display: "flex"}}>
                        <InvestorLogo slug={"notley"} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} justify="center" style={{display: "flex"}}>
                        <InvestorLogo slug={"capital_factory"} />
                    </Grid>
                </Grid>
            </InvestorContainer>           
        )
    }

    render() {
        const { isJumbo } = this.state;
        return (
            <Main>
                {this.renderFixedNav()}
                <LandingContainer>
                    {this.renderHeroSection()}
                    <PageDivider isColored={true} isReverse={true} style={{ marginTop: isJumbo ? 0 : -87, zIndex: 2, position: "relative" }} />
                    {this.renderWeAreSupplyDrop()}
                    {this.renderFounders()}
                    <PageDivider isReverse={true} />
                    {this.renderInvestors()}
                </LandingContainer>
                <Footer
                    history={this.props.history}
                    landing={true}
                />
            </Main>
        )
    }
}

const mapStateToProps = (state) => {
    const { landingData, gettingLandingData, landingError } = state.order;
    const { postProspectSuccess, postProspectError, postingProspect } = state.user;
    return {
        postProspectSuccess,
        postProspectError,
        postingProspect,
        landingData,
        gettingLandingData,
        landingError
    }
}

export default connect(mapStateToProps, {
    postProspect,
    getLanding
})(About);