import { Logger } from './Logger'
const logger = new Logger();

export const isEnabled = (featureFlag) => {
    try {
        if(featureFlag) {
            return featureFlag === 'true';
        } else {
            logger.error(new Error('Unknown feature flag'))
            return false;
        }
    } catch(err) {
        logger.error(err, new Error(`Could not verifiy if feature flag ${featureFlag} isEnabled`));
        return false;
    }
}