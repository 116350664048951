export const bundleMap = {
    ToiletPaper: "everyday",
    PaperTowels: "everyday",
    FacialTissue: "everyday",
    TrashBagsCompost: "everyday",
    TrashBagsKitchen: "everyday",
    TrashBagsOutdoor: "everyday",
    TrashBagsRecycling: "everyday",
    TrashBagsSmall: "everyday",
    HandSoap: "kitchen",
    SurfaceCleaner: "kitchen",
    DishSoap: "kitchen",
    DishwasherDetergent: "kitchen",
    Bleach: "laundry",
    DryerSheets: "laundry",
    LaundryDetergent: "laundry",
    HandWipes: "defense",
    HandSanitizer: "defense",
    FaceMasksSurgical: "defense"
}