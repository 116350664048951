import React from 'react';
import {
    TestimonialCard
} from './'
import {
    ScienceCarouselSection as CarouselSection,
    H2Amaranth as H2,
    TestimonialCarouselSubtitleContainer as SubtitleContainer
} from '../../../Style';
import Carousel from 'react-multi-carousel';

const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1500 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1500, min: 991 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 991, min: 0 },
        items: 1,
    },
}

export const TestimonialCardCarousel = ({
    style,
    testimonials
}) => {
    if(testimonials) {
        const { title, entries, subtitle, isWide } = testimonials;
        let i = 0;
        const testimonialCards = entries.map(testimonial => {
            i++
            const { quote, src, name } = testimonial;
            return (
                <TestimonialCard key={`testimonial-card-${i}`} quote={quote} src={src} name={name} isWide={isWide} />
            )
        })

        function renderSubtitle() {
            if(subtitle) {
                return (
                    <SubtitleContainer>
                        <p>{subtitle}</p>
                    </SubtitleContainer>
                )
            }
        }
    
        return (
            <CarouselSection style={style}>
                <H2>{title}</H2>
                {renderSubtitle()}
                <Carousel
                    containerClass={`testimonial-card-container${isWide ? " testimonial-card-container-centered" : ""}`}
                    dotListClass="testimonial-card-dot-list"
                    responsive={responsive}
                    partialVisible={true}
                    autoPlay={true}
                    autoPlaySpeed={6000}
                    infinite={true}
                    showDots={true}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    className="testimonial-card-carousel"
                >
                    {testimonialCards}
                </Carousel>
            </CarouselSection>
        )
    }
    else {
        return null;
    }
}