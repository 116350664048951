import config from '../config';
import { Auth } from 'aws-amplify';
import signApiGatewayRequest from '../actions/ags';
import * as Sentry from '@sentry/browser';

export default function getSignature(method, payload, path, queryParams) {
  return new Promise(async (resolve, reject) => {
    try {
      const credentials = await Auth.currentCredentials();

      let request = {
        host: config.apiHostName,
        method: method,
        data: payload,
        path,
        queryParams: queryParams ? queryParams : {},
        headers: {
          'content-type': 'application/json',
        }
      }

      Auth.currentSession().then(data => {
        request.headers['X-SupplyDrop-CustomerId'] = data.idToken.jwtToken
      }).catch(err => {
        // it's ok if this fails, the field above is not always needed.
      })

      request = signApiGatewayRequest(request, credentials);

      resolve({ request });
    }
    catch (err) {
      const explicitErr = new Error('failed to create signature' );
      explicitErr.name = 'AuthError'
      Sentry.captureException(explicitErr)
      Sentry.captureException(err)
      reject(explicitErr);
    }
  })
}
