export const 
    Caboo = require('./Caboo.png'),
    Cascade = require('./Cascade.png'),
    Cottonelle = require('./Cottonelle.png'),
    Fantastik = require('./Fantastik.png'),
    Glad = require('./Glad.png'),
    Kleenex = require('./Kleenex.png'),
    MrsMeyers = require('./MrsMeyers.png'),
    Puracy = require('./Puracy.png'),
    Soapbox = require('./Soapbox.png'),
    SeventhGeneration = require('./SeventhGeneration.png')