// return header copy for utm_content

// defaults: {
//     header: "Finally, a Smart Subscription for your home!",
//     subheader: "Personalized for your household with the products you use everyday."
// }

export const campaignHeaders = {
    "Promoting Website%3A https%3A%2F%2Fsupplydrop.com%2F": {
        header: "Your Concierge For Home Essentials - FREE Delivery",
        subheader: "Personalized smart subscription for the products you love. We know what you need and when so you don't have to think about it. Cancel anytime."
    },
    "Smart Subscription": {
        header: "Your Concierge For Home Essentials - FREE Delivery",
        subheader: "Personalized smart subscription for the products you love. We know what you need and when so you don't have to think about it. Cancel anytime."
    },
    "50% Off": {
        header: "Your Concierge For Home Essentials - FREE Delivery",
        subheader: "Personalized smart subscription for the products you love. We know what you need and when so you don't have to think about it. Cancel anytime."
    },
    "Cancel Anytime 1": {
        header: "You're already buying all this stuff; let our concierge take care of it for you!",
        subheader: "No more hassle and you still get the guaranteed best prices"
    },
    "Cancel Anytime 2": {
        header: "Try it now, risk free!",
        subheader: "You’re already buying it; we make it easier and we guarantee the best prices. It’s easy to get started. Cancel anytime."
    },
    "Cancel Anytime 3": {
        header: "The better way to get your home essentials!",
        subheader: "Free delivery of the products you love, when you need them. No hidden fees. Cancel anytime."
    },
    "Cancel Anytime 4": {
        header: "Minimum effort. Maximum convenience.",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "Dog's Best Delivery": {
        header: "Your concierge for home supplies!",
        subheader: "Free delivery of the products you love, when you need them. No hidden fees. Cancel anytime."
    },
    "Dog's Best Delivery 2": {
        header: "Minimum effort. Maximum convenience.",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "No Hassle": {
        header: "The better way to get your home essentials!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "No Lock In 1": {
        header: "The products you already buy but without the hassle!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "No Lock In 2": {
        header: "Minimum effort. Maximum convenience.",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "No Lock In 3": {
        header: "You're already buying all this stuff; let our concierge take care of it for you!",
        subheader: "No more hassle and you still get the guaranteed best prices"
    },
    "No Lock In 4": {
        header: "Try it now, risk free!",
        subheader: "You’re already buying it; we make it easier and we guarantee the best prices. It’s easy to get started. Cancel anytime."
    },
    "On Hand": {
        header: "Your favorite brands delivered when you need them!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "On the Go": {
        header: "The right amount of the right products at the right time!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "Quick Quiz 1": {
        header: "Your favorite brands delivered when you need them!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "Quick Quiz 2": {
        header: "Your Concierge For Home Essentials - FREE Delivery",
        subheader: "Personalized smart subscription for the products you love. We know what you need and when so you don't have to think about it. Cancel anytime."
    },
    "Quick Quiz 3": {
        header: "Minimum effort. Maximum convenience.",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "Quick Quiz 4": {
        header: "The products you already buy but without the hassle!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "Quick Quiz 5": {
        header: "Your concierge for home supplies!",
        subheader: "Free delivery of the products you love. No lock in. No hidden fees. Cancel anytime."
    },
    "Roommate": {
        header: "Your favorite brands delivered when you need them!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    },
    "Time Well Saved": {
        header: "Minimum effort. Maximum convenience.",
        subheader: "Free delivery of the products you love, when you need them. No hidden fees. Cancel anytime."
    },
    "Out of TP": {
        header: "No hassle guarantee to have the home essentials you need!",
        subheader: "Free delivery of the products you love, when you need them. No hidden fees. Cancel anytime."
    },
    "Toilet Paper": {
        header: "Your favorite brands delivered when you need them!",
        subheader: "Home essentials concierge service. Same price as the grocery store + free delivery."
    }
}