import React from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({
    id,
    pathname,
    hash,
    title,
    dashboardData
}) => {
    return (
        <Link className="row menu-nav-row" to={{pathname, hash, state: {dashboardData}}}>
            <div className="menu-icon" id={id}></div>
            <div className="row menu-nav-link">{title}</div>
        </Link>
    )
}

export default MenuItem;