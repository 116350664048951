import { campaigns } from "../data";
import { Logger } from '../helpers/Logger';
const logger = new Logger();

export const validateEmail = (email) => {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(email).toLowerCase());
}

export function validateWorkEmail(workEmail, employerSlug) {
    let workEmailValid = validateEmail(workEmail);
    if (workEmailValid) {
        // TODO: Get this data somewhere else˝
        const res = validateEmailDomain(workEmail, employerSlug)
        return { workEmailValid: res.workEmailValid, workEmailError: res.error }
    } else {
        return { workEmailValid, workEmailError: "Email address is not in proper format" }
    }
}

function validateEmailDomain(workEmail, employerSlug) {
    const domain = workEmail.split('@').pop();
    // Validate work email domain if employer has one set 
    if (campaigns[employerSlug] && (!campaigns[employerSlug].domain || campaigns[employerSlug].domain === domain)) {
        return { workEmailValid: true }
    } else if (campaigns[employerSlug] && campaigns[employerSlug].displayName) {
        const { displayName } = campaigns[employerSlug]
        return { error: `Email address needs to be ${isFirstLetterVowel(displayName) ? "an" : "a"} ${displayName} email address` }
    } else {
        logger.error('Trying to validate work email without valid employerSlug', null, null, null, { employerSlug })
        return { error: "Email address needs to be a company email address" }
    }
}

export const validateZip = (zip) => {
    const reg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return reg.test(zip);
}

export const validateStreetAddress = (address) => {
    if (address.split(" ").length > 2) {
        return true;
    } else {
        return false;
    }
}

export const validateCityAndNames = (value) => {
    const reg = RegExp('[A-Za-z]');
    return reg.test(value);
}

export const checkObjForNulls = (quickCheckObject) => {
    let invalidFields = Object.values(quickCheckObject).filter(field => field === null || field === undefined || field === "");
    return invalidFields.length > 0 ? false : true;
}

export const validatePassword = (pword) => {
    //between 8-20 characters with one captial letter, one lowercase and a number
    const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    return reg.test(pword);
}
export const validateCvv = (value) => {
    const reg = /^[0-9]{3,4}$/;
    return reg.test(value);
}

export const validateExpDate = (date) => {
    return date > new Date() ? true : false;
}

export const validateCheckoutForm = (values) => {
    let invalidFields = false;
    for (let i = 0; i < values.length; i++) {
        invalidFields = !values[i];
        if (invalidFields) {
            break;
        }
    }
    return invalidFields;
}

function isFirstLetterVowel(string) {
    if(string && typeof(string) === "string") {
        switch(string[0].toLowerCase()) {
            case 'a':
            case 'e':
            case 'i':
            case 'o':
            case 'u':
            case 'y':
                return true;
            default:
                return false;
        }
    } else {
        return false
    }
}
